// export const TOKEN_NAME: string = import.meta.env.VITE_TOKEN_NAME;

// export const USER_ID_NAME: string = import.meta.env.VITE_USER_ID_NAME;

// export const ETUBES_URL_PREFIX = import.meta.env.VITE_DOMAIN;

export const RAZORPAY_KEY = "rzp_live_VVxtgFlGk9YK8d";
export const ETUBES_URL_PREFIX =
  process.env.REACT_APP_ENV !== "dev"
    ? "https://api.zynoflix.com/v1"
    : "https://api.zynoflix.com/v1";
// export const ETUBES_URL_PREFIX =
//   process.env.REACT_APP_ENV !== "dev"
//     ? "http://13.200.126.1:4000/v1"
//     : "http://13.200.126.1:4000/v1";
export const APP_NAME = "zynoflix";
// http://13.200.126.1:4000/v1/test
// export const CURRENCY_UNIT = import.meta.env.VITE_CURRENCY_UNIT;

// export const API_VERSION = import.meta.env.VITE_API_VERSION;
export const USER_ID_NAME: string = "etubes_id";
export const STATIC_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NDI3MjM4OTdiMTAyYjc5MjZkYTE5ZSIsImlhdCI6MTczMzcyMTg2NX0.lFhA95CbTBN_CM1jcbGJiZz9irQV9Js3lacHDiEIMPc";
// "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NDI3MjM4OTdiMTAyYjc5MjZkYTE5ZSIsImlhdCI6MTcwNTA2NzQ1MX0.8ra6CX9qcaAOmKz50LBgS6kGbVH3vMcMyT4qQayEszs";
// export const UPLOAD_VIDEO_MAX_SIZE = import.meta.env.VITE_UPLOAD_VIDEO_MAX_SIZE;

// export const UPLOAD_THUMBNAIL_MAX_SIZE = import.meta.env.VITE_UPLOAD_THUMBNAIL_MAX_SIZE;

// export const ZYNOFLIX_DISCOUNT = +import.meta.env.VITE_CART_DISCOUNT;

// export const ZYNOFLIX_SHARE_URL = import.meta.env.VITE_SHARE_URL;

// export const ZYNOFLIX_SHARE_TITLE = import.meta.env.VITE_SHARE_TITLE;
// export const USERNAME_EXISTS_CHECK_STRING =
//   process.env.REACT_APP_USERNAME_EXISTS_CHECK_STRING
// export const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME
// export const UPLOAD_VIDEO_MAX_SIZE = process.env.REACT_APP_UPLOAD_VIDEO_MAX_SIZE
// export const ZYNOFLIX_DISCOUNT = process.env.REACT_APP_ZYNOFLIX_DISCOUNT

// export const ZYNOFLIX_GST = process.env.REACT_APP_ZYNOFLIX_GST
// export const ZYNOFLIX_PROCESSING_FEE =
//   process.env.REACT_APP_ZYNOFLIX_PROCESSING_FEE
export const USERNAME_EXISTS_CHECK_STRING = "user_name already exist";
export const TOKEN_NAME: string = "etubes_token";
export const UPLOAD_VIDEO_MAX_SIZE = "343434343434mb";
export const ZYNOFLIX_DISCOUNT = 0.0;
export const ZYNOFLIX_GST = 1.8;
export const ZYNOFLIX_PROCESSING_FEE = 0.21;
