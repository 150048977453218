import React, { lazy, Suspense, useEffect } from "react";
import "./App.css";

import { CookiesProvider, useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { fetchToken } from "./utils/util";
import { TOKEN_NAME } from "./utils/variables";
import ReactGA from "react-ga";

import ScrollToTop from "./utils/ScrollToTop";
import ForgotPassword from "./Pages/ForgotPassword";

import Founder from "./Pages/Founder";
import MyPostedEvent from "./Pages/MyPostedEvent";
import Header from "./components/Header/Header";
import MyPostedWantedVideo from "./components/MyPostedWantedVideo";
import FAQ from "./Pages/FAQ";
import Live from "./Live";
// import Cursor from "./components/Cursor"

import Map from "./Pages/Location/Map";

import AppyForm from "./Pages/ApplyForm";
import AuthorProfile from "./Pages/AuthorProfile";
import Cursor from "./components/Cursor";
import TransactionSuccess from "./Pages/TransactionSuccess";
import TransactionFail from "./Pages/TransactionFail";
import SignUpOtp from "./Pages/SignUpOtp/SignUpOtp";
import SellVidoe from "./Pages/SellVideo";
import Sponsorship from "./Pages/Sponsorship";
import { UploadDetails } from "./Pages/SubmitVideo";

const Footer = lazy(() => import("./components/Footer/Footer"));
// Lazy-loaded components
const LandingPage = lazy(() => import("./Pages/LandingPage"));
const VideoPreview = lazy(() => import("./Pages/VideoPreview"));
const Wishlist = lazy(() => import("./Pages/Profile/component/Wishlist"));
const Cart = lazy(() => import("./Pages/MyCard/Cart"));
const SearchResultsPage = lazy(
  () => import("./Pages/SearchResultsPage/SearchResultsPage")
);
const Orders = lazy(() => import("./Pages/Profile/component/Orders"));
const ProfilePage = lazy(() => import("./Pages/Profile"));
const Notifications = lazy(() => import("./components/Notiications"));
const SubmitVideo = lazy(() => import("./Pages/SubmitVideo"));
const TicketBooking = lazy(() => import("./Pages/TicketBooking"));
const TicketPreview = lazy(() => import("./Pages/TicketPreview"));
const TicketCreate = lazy(() => import("./Pages/TicketCreate"));
const TicketBookPayment = lazy(() => import("./Pages/TicketBook"));
const WantedVideoMain = lazy(() => import("./Pages/WantedVideo"));
const PostJob = lazy(() => import("./Pages/PostJob"));
const BoostPost = lazy(() => import("./Pages/BoostPost"));
const Login = lazy(() => import("./Pages/Login"));
const MoreInfo = lazy(() => import("./Pages/Signup/MoreInfo"));
const LiveStream = lazy(() => import("./Pages/LiveStream"));
const MyAppliedWantedVideo = lazy(() => import("./Pages/MyAppliedWantedVideo"));
const InstantCartSummary = lazy(
  () => import("./components/InstantCartSummary")
);
const CartSummary = lazy(() => import("./components/CartSummary"));
const AboutUs = lazy(() => import("./Pages/About-Us"));
const TermsCondition = lazy(() => import("./Pages/TermsCon"));
const PrivacyPolicy = lazy(() => import("./Pages/Privacy-Policy"));
const RefundPolicy = lazy(() => import("./components/RefundPolicy"));
const Copyrights = lazy(() => import("./components/Copyrights"));
const ContactUs = lazy(() => import("./components/contact/ContactUs"));
const PrivateRoute = ({ children, ...rest }: any) => {
  const TOKEN = fetchToken(TOKEN_NAME);
  const isAuthenticated = TOKEN;

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

ReactGA.initialize("G-0XWDLX2XXW");

ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <CookiesProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zynoflix</title>
        <link rel="canonical" href="https://www.zynoflix.com" />
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        {window.location.pathname.slice(1, 8) === "preview" ||
          (window.location.pathname.slice(1, 8) === "meeting" && <Live />)}
        <Cursor />
        <Suspense
          fallback={
            <div className="h-screen flex items-center justify-center bg-background_body w-screen">
              <img src="/Images/loading.gif" className="" alt="" />
            </div>
          }
        >
          <div
            className="mouse-cursor-gradient-tracking blob"
            id="customCursor"
          >
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/location" element={<Map />} />
              <Route path="/founder" element={<Founder />} />
              <Route path="/video-summary" element={<VideoPreview />} />
              <Route
                path="/wishlist"
                element={
                  <PrivateRoute fallbackPath={"/login"}>
                    <Wishlist />
                  </PrivateRoute>
                }
              />
              <Route
                path="/my-cart"
                element={
                  <PrivateRoute fallbackPath={"/login"}>
                    <Cart />
                  </PrivateRoute>
                }
              />
              <Route path="/search-results" element={<SearchResultsPage />} />
              <Route
                path="/orders"
                element={
                  <PrivateRoute fallbackPath={"/login"}>
                    <Orders />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <PrivateRoute>
                    <Notifications />
                  </PrivateRoute>
                }
              />
              <Route
                path="/upload-video"
                element={
                  <PrivateRoute>
                    <SubmitVideo />
                  </PrivateRoute>
                }
              />
              <Route path="/awards" element={<UploadDetails />} />
              <Route
                path="/ticket/booking"
                element={
                  <PrivateRoute>
                    <TicketBooking />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ticket/booking/preview"
                element={
                  <PrivateRoute>
                    <TicketPreview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ticket/booking/create-ticket"
                element={
                  <PrivateRoute>
                    <TicketCreate />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ticket/booking/payment"
                element={
                  <PrivateRoute>
                    <TicketBookPayment />
                  </PrivateRoute>
                }
              />
              <Route path="/wanted/video" element={<WantedVideoMain />} />
              <Route
                path="/wanted/video/create"
                element={
                  <PrivateRoute>
                    <PostJob />
                  </PrivateRoute>
                }
              />
              <Route
                path="/wanted/video/apply"
                element={
                  <PrivateRoute>
                    <AppyForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/wanted/my-post"
                element={
                  <PrivateRoute>
                    <MyPostedWantedVideo />
                  </PrivateRoute>
                }
              />
              <Route
                path="/wanted/my-applied"
                element={
                  <PrivateRoute>
                    <MyAppliedWantedVideo />
                  </PrivateRoute>
                }
              />
              <Route
                path="/boost/post/:id"
                element={
                  <PrivateRoute>
                    
                    <BoostPost />
                  </PrivateRoute>
                }
              />
              <Route
                path="/live-stream-event/my-posted"
                element={
                  <PrivateRoute>
                    <MyPostedEvent />
                  </PrivateRoute>
                }
              />
              <Route
                path="/live-streaming"
                element={
                  <PrivateRoute>
                    <LiveStream />
                  </PrivateRoute>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/author-profile" element={<AuthorProfile />} />
              <Route path="/signup" element={<MoreInfo />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/sell/video" element={<SellVidoe />} />
              <Route path="/sponsorship" element={<Sponsorship />} />
              <Route
                path="/transaction-success"
                element={<TransactionSuccess />}
              />
              <Route path="/transaction-fail" element={<TransactionFail />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsCondition />}
              />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/refundpolicy" element={<RefundPolicy />} />
              <Route path="/copyrights" element={<Copyrights />} />
              <Route
                path="/contact-us"
                element={<ContactUs description={""} />}
              />
              <Route path="/forgot-password" element={<SignUpOtp />} />
              <Route
                path="/change-password"
                element={
                  <ForgotPassword
                    title="forget password"
                    subTitle="register a new password"
                    mode="change"
                  />
                }
              />
              <Route
                path="/instantcart-summary"
                element={
                  <PrivateRoute>
                    <InstantCartSummary />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <ForgotPassword
                    title="reset password"
                    subTitle="register a new password"
                    mode="reset"
                  />
                }
              />
            </Routes>
          </div>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
