import backgroundImage from "./../../assets/signup-background.svg"
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { ToastContainer, toast } from "react-toastify"
import OtpInput from "../../components/otpCheckBox/OTPCheckBox"
import { fetchToken } from "../../utils/util"
import ButtonWithLoader from "../../components/loader/ButtonWithLoader"

import { ETUBES_URL_PREFIX, TOKEN_NAME } from "../../utils/variables"

type FormData = {
  username: string
  password: string
}

const TOKEN_NAME1 = TOKEN_NAME

const URL_REQUEST_OTP = `${ETUBES_URL_PREFIX}/forget_password`

const SignUpOtp = () => {
  const navigate = useNavigate()
  const [userName, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [otp, setOtp] = useState("")
  const [userNameErrorMessage, setUsernameErrorMessage] = useState("")
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
  const [enableOTP, setEnableOTP] = useState(false)
  const [minutes, setMinutes] = useState(1)
  const [seconds, setSeconds] = useState(30)

  const [blockResendOTP, setBlockResendOTP] = useState(true)
  const [requestStatus, setRequestStatus] = useState<any>()
  const [email, setEmail] = useState<any>()
  const [setId, setSetId] = useState<any>()
  const [oneTimePinSent, setOneTimePinSent] = useState<any>()
  const [oneTimePin, setOneTimePin] = useState<any>()
  const fieldValidator = () => {
    if (userName === "") {
      setUsernameErrorMessage("Please enter a valid username")
    }
    if (password === "") {
      setPasswordErrorMessage("Please enter your password")
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  const onChangeHanlder = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "email") {
      setUsername(e.target.value)
      setEmail(e.target.value)
      userNameErrorMessage !== "" && setUsernameErrorMessage("")
    }

    if (e.target.type === "password") {
      setPassword(e.target.value)
      passwordErrorMessage !== "" && setPasswordErrorMessage("")
    }
  }

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormData>()

  const onSubmit = handleSubmit((data) => {
    setValue("username", data.username)
    setValue("password", data.password)
  })
  console.log(oneTimePinSent, "oneTimePinSent")

  const onClickValidationHandler = async () => {
    if (Object.values(errors).length > 0) {
      const errorValues = Object.values(errors)
      errorValues.map(
        (error) => error?.message !== "" && toast(error.message || "")
      )
    } else {
      if (otp === oneTimePinSent) {
        navigate("/reset-password")
      } else {
        toast("Please enter the valid OTP")
      }
    }
  }

  const otpHandler = async () => {
    const TOKEN = fetchToken(TOKEN_NAME1)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ email }),
    }
    try {
      setRequestStatus({
        loading: false,
        success: false,
        error: false,
        buttonLoading: true,
      })
      const result = await fetch(URL_REQUEST_OTP, options).then((data) =>
        data.json()
      )
      setRequestStatus({
        loading: false,
        success: true,
        error: false,
        buttonLoading: false,
      })
      if (result.status_code === 200) {
        setEnableOTP(true)
        setOneTimePin(result.result[0].otp)
        setOneTimePinSent(result.result[0].otp)
        setId(result.result[0]._id)
        toast("OTP sent successfully!")
        setMinutes(1)
        setSeconds(30)
      } else {
        toast(result.message)
      }
    } catch (error) {
      setRequestStatus({
        loading: false,
        success: false,
        error: true,
        buttonLoading: false,
      })
    }
  }

  useEffect(() => {
    if (enableOTP) {
      setTimeout(() => {
        setBlockResendOTP(false)
      }, 600000)
    }
  }, [enableOTP])

  return (
    <div className="flex h-screen items-center justify-center w-screen">
      <div className="fixed inset-0">
        <video
          className="w-full object-cover rounded"
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
          poster={"./Images/loginbg.mp4"}
          src={"./Images/loginbg.mp4"}
        />
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="basis-full z-50 relative text-white   flex justify-center align-center">
        <div className="login-form-wrapper w-[40%] flex flex-col justify-center gap-6 md:py-16 md:px-8  p-8  ">
          <div className="form-meta">
            <h5 className="form-title font-semibold text-xl">User Sign Up</h5>
            <p className="form-description text-xm w-3/4 flex-wrap hidden">
              Enter OTP
            </p>
          </div>
          <form
            onSubmit={onSubmit}
            className="login-form space-y-6 flex flex-col"
          >
            <div className="input-wrapper">
              <div>
                <input
                  type="email"
                  id="userEmail"
                  className={` w-full grow h-[65px] px-6 bg-black rounded-xl outline-none font-medium text-base p-4 ${
                    enableOTP && "opacity-3 cursor-not-allowed"
                  }`}
                  placeholder="Enter email"
                  value={userName}
                  onChange={onChangeHanlder}
                  disabled={enableOTP}
                />
              </div>
            </div>
            {enableOTP && (
              <div className="input-wrapper">
                <div className="relative">
                  <OtpInput
                    value={otp}
                    valueLength={6}
                    onChange={setOtp}
                    enableOTP={enableOTP}
                  />
                </div>
              </div>
            )}

            <div className="form-utils flex justify-between flex-row">
              <div
                className={`flex basis-1/2 justify-start items-center w-1/2 ${
                  enableOTP ? "block" : "hidden"
                }`}
              >
                {seconds > 0 || minutes > 0 ? (
                  <p className="text-sm md:text-xl">
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>Didn't recieve code?</p>
                )}
              </div>
              <div
                className={`flex flex-row basis-1/2 px-6 py-3 bg-btn_bgcolor3 rounded-xl justify-end items-center ${
                  !enableOTP && "hidden"
                }`}
              >
                <label
                  htmlFor="remember-me"
                  className={`text-sm md:text-xl ${
                    seconds > 0 || minutes > 0
                      ? "opacity-50 cursor-not-allowed pointer-events-none"
                      : ""
                  }`}
                  aria-disabled={blockResendOTP}
                  onClick={otpHandler}
                  role="button"
                >
                  Resend OTP
                </label>
              </div>
            </div>
            <ButtonWithLoader
              text="Send OTP"
              onClickHandler={otpHandler}
              customClassName={` px-6 py-3 bg-btn_bgcolor3 rounded-xl ${
                !enableOTP ? "block" : "hidden"
              }`}
            />
            <ButtonWithLoader
              text="Continue"
              onClickHandler={onClickValidationHandler}
              customClassName={` px-6 py-3 bg-btn_bgcolor3 rounded-xl ${
                enableOTP ? "block" : "hidden"
              }`}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignUpOtp
