import React, { createContext, useState, useEffect, ReactNode } from "react"

import { checkTokenValidity, fetchToken, viewProfile } from "../../utils/util"
import { TOKEN_NAME, USER_ID_NAME } from "../../utils/variables"

// Define the type for the context value
interface MyContextProps {
  isAdmin: boolean
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>
  activeSession: any
  validSession: any
  // setProfile: any
  // profile: any
}

const MyContext = createContext<any>(undefined)

interface MyProviderProps {
  children: ReactNode
}

function MyProvider({ children }: MyProviderProps) {
  const [isAdmin, setIsAdmin] = useState(false)

  const [activeSession, setActiveSession] = useState()
  const validSession = fetchToken(TOKEN_NAME)
  // const [profile, setProfile] = useState<any>()
  // useEffect(() => {
  //   if (validSession) {
  //     const ID = localStorage.getItem(USER_ID_NAME)
  //     if (ID && ID !== "") {
  //       viewProfile(ID, setProfile)
  //     }
  //   }
  // }, [])

  const contextValue: MyContextProps = {
    isAdmin,
    setIsAdmin,
    validSession,
    activeSession,
    // profile,
    // setProfile,
  }

  return (
    <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>
  )
}

export { MyProvider, MyContext }
