import React, { useState } from "react";
import VideoComponent from "./video_duration";
import { Link, useNavigate } from "react-router-dom";
import Bookmark from "../../../components/Bookmark";
import { fetchToken } from "../../../utils/util";
import { TOKEN_NAME } from "../../../utils/variables";
import { PlayIcon } from "@heroicons/react/24/outline";

const VideoCard = ({ trendingVideos, target }: any) => {
  const navigation = useNavigate();
  const [videplayBtn, setVideplayBtn] = useState(false);

  return (
    <div className="lg:w-[289px] w-[170px] h-[350px] lg:h-[360px] py-2 bg-[rgba(248,240,255,0.05)] px-2 rounded-xl flex flex-col">
      <button className="absolute z-50 flex items-center justify-center rounded-full text-xs px-2 right-6 top-2 py-1">
        {trendingVideos && <Bookmark video_id={trendingVideos?._id} />}
      </button>
      <button className="absolute z-50 flex items-center justify-center rounded-full text-xs px-2 left-2 top-2 py-1">
        {/* Add your content here */}
      </button>
      <Link
        to={`/video-summary?id=${trendingVideos._id}`}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        onMouseEnter={() => setVideplayBtn(true)}
        onMouseLeave={() => setVideplayBtn(false)}
        className="rounded-xl duration-500 relative"
      >
        <div className="relative">
          {!videplayBtn ? (
            <img
              className="rounded w-full lg:w-[300px] h-[187px] lg:h-[187px] object-cover"
              src={trendingVideos?.thumbnail}
              alt="thumbnail"
            />
          ) : (
            <video
              className="w-full object-cover lg:w-[300px] h-[187px] lg:h-[187px] rounded"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              poster={trendingVideos?.thumbnail}
              src={trendingVideos.url}
            />
          )}

          {/* <button className="absolute z-50 flex items-center justify-center bottom-0 px-1 right-0 bg-[#000] gap-1 text-white rounded">
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 7.74994C10.3674 7.74994 10.2402 7.80262 10.1464 7.89639C10.0526 7.99015 9.99997 8.11733 9.99997 8.24994V10.2499C9.99997 10.3825 9.94729 10.5097 9.85352 10.6035C9.75976 10.6973 9.63258 10.7499 9.49997 10.7499H2.49997C2.36736 10.7499 2.24018 10.6973 2.14642 10.6035C2.05265 10.5097 1.99997 10.3825 1.99997 10.2499V8.24994C1.99997 8.11733 1.94729 7.99015 1.85352 7.89639C1.75975 7.80262 1.63258 7.74994 1.49997 7.74994C1.36736 7.74994 1.24018 7.80262 1.14642 7.89639C1.05265 7.99015 0.999969 8.11733 0.999969 8.24994V10.2499C0.999969 10.6478 1.158 11.0293 1.43931 11.3106C1.72061 11.5919 2.10214 11.7499 2.49997 11.7499H9.49997C9.8978 11.7499 10.2793 11.5919 10.5606 11.3106C10.8419 11.0293 11 10.6478 11 10.2499V8.24994C11 8.11733 10.9473 7.99015 10.8535 7.89639C10.7598 7.80262 10.6326 7.74994 10.5 7.74994ZM5.64497 8.60494C5.69252 8.65046 5.74859 8.68614 5.80997 8.70994C5.86982 8.73639 5.93453 8.75006 5.99997 8.75006C6.06541 8.75006 6.13012 8.73639 6.18997 8.70994C6.25135 8.68614 6.30742 8.65046 6.35497 8.60494L8.35497 6.60494C8.44912 6.51079 8.50202 6.38309 8.50202 6.24994C8.50202 6.11679 8.44912 5.98909 8.35497 5.89494C8.26082 5.80079 8.13312 5.74789 7.99997 5.74789C7.86682 5.74789 7.73912 5.80079 7.64497 5.89494L6.49997 7.04494V2.24994C6.49997 2.11733 6.44729 1.99015 6.35352 1.89639C6.25976 1.80262 6.13258 1.74994 5.99997 1.74994C5.86736 1.74994 5.74018 1.80262 5.64642 1.89639C5.55265 1.99015 5.49997 2.11733 5.49997 2.24994V7.04494L4.35497 5.89494C4.30835 5.84832 4.25301 5.81134 4.19209 5.78611C4.13118 5.76088 4.0659 5.74789 3.99997 5.74789C3.93404 5.74789 3.86876 5.76088 3.80785 5.78611C3.74693 5.81134 3.69159 5.84832 3.64497 5.89494C3.59835 5.94156 3.56137 5.9969 3.53614 6.05781C3.51091 6.11873 3.49792 6.18401 3.49792 6.24994C3.49792 6.31587 3.51091 6.38115 3.53614 6.44206C3.56137 6.50298 3.59835 6.55832 3.64497 6.60494L5.64497 8.60494Z"
                fill="#fff"
              />
            </svg>
            {trendingVideos.downloads}
          </button> */}
          <button className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 shadow-2xl">
            <PlayIcon className="w-6 h-6" />
          </button>
          <button className="absolute  top-0 left-0 shadow-2xl">
            <img className="w-12 h-12" src="/Images/ad.gif" alt="" />
          </button>
        </div>
      </Link>

      <div className="flex flex-col justify-between">
        {/* <div className="flex items-center justify-between w-full"> */}
        {/* <div className="w-full"> */}
        <h3 className="lg:text-base h-7 lg:h-12 text-xs cursor-pointer bg-transparent text-start mt-3 font-semibold duration-300 text-white hover:text-main line-clamp-2">
          {trendingVideos?.title}
        </h3>

        <div className="lg:flex  flex-col w-full lg:mt-6 mt-3">
          <div className="flex !justify-between lg:flex-row flex-col w-full lg:items-center items-start">
            <div className="flex items-center justify-between w-full min-w-max text-xs lg:text-sm">
              <h2 className="lg:px-2 px-1  rounded-md lg:rounded-md text-white font-normal bg-[rgba(255,255,255,.1)] lg:font-medium text-[10px] lg:text-xs lg:py-1">
                {trendingVideos.category}
              </h2>
            </div>
            <p className="text-xs min-w-max mt-1 lg:mt-0 font-medium text-gray-300 lg:text-sm flex">
              Total Duration :{" "}
              <span className="pl-2">
                {trendingVideos && trendingVideos?.duration}
              </span>
            </p>
          </div>
          <h2 className="text-[#fff] lg:hidden block text-xs font-medium">
            {trendingVideos?.total_views} Views
          </h2>
          {/* </div> */}
          {/* </div> */}
          <div className="flex justify-between w-full items-center mt-2">
            <div className="flex rounded-xl py-3  items-center w-full justify-between">
              <div className="flex items-center">
                <div className="lg:px-2 px-1  rounded lg:rounded-md text-white font-normal bg-[rgba(255,255,255,.1)] lg:font-bold text-sm lg:text-xs lg:py-1">
                  <h2> ₹ {trendingVideos.cost}</h2>
                </div>
              </div>
              <h2 className="text-[#fff] lg:block hidden text-xs font-medium">
                {trendingVideos?.total_views} Views
              </h2>
              <button
                onClick={() => {
                  const TOKEN = fetchToken(TOKEN_NAME);
                  if (!TOKEN && TOKEN === "") navigation("/login");
                  else
                    navigation(
                      `/instantcart-summary?video_id=${trendingVideos._id}`
                    );
                }}
                className="lg:px-3 px-2 default-button-color rounded lg:rounded text-white font-normal lg:font-semibold text-xs lg:text-xs py-0.5 lg:py-1 duration-200"
              >
                Buy Now
              </button>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
