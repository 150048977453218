import React from "react"
import { Group, Avatar, Text, Accordion } from "@mantine/core"
const charactersList = [
  {
    id: "bender",
    image: "https://img.icons8.com/clouds/256/000000/futurama-bender.png",
    label: "How to make money on Zynoflix.? ",
    description: "the complete guide about how to make money on Zynoflix",
    content: (
      <p className="">
        <span>
          Making money on Zynoflix involves several strategies and
          considerations. Here's a step-by-step guide to help you get started:
        </span>
        <br />
        <br />
        <span>
          Set up your Zynoflix channel: Set up your channel by creating a
          Zynoflix account or using an existing Google account. Choose a catchy
          name, create a unique channel logo and banner, and add a compelling
          channel description.
        </span>{" "}
        <br />
        <br />
        <span>
          Define your niche: Decide on the type of content you want to create
          and the target audience you want to attract. Choosing a specific niche
          helps you build a loyal subscriber base.
        </span>{" "}
        <br />
        <br />
        <span>
          Create high-quality content: Invest in making engaging, high-quality
          videos that add value to your viewers' lives. Ensure your videos have
          good lighting, clear audio, and professional editing. Consistency is
          key, so aim to upload content on a regular schedule.
        </span>{" "}
        <br />
        <br />
        <span>
          Optimize your videos for discovery: Use relevant keywords and tags in
          your video titles, descriptions, and tags to improve search engine
          optimization (SEO). This helps your videos rank higher in search
          results and gain more visibility.
        </span>
        <br />
        <br />
        <span>
          Build an audience: Promote your channel on social media platforms and
          collaborate with other Zynoflixers in your niche to expand your reach.
          Engage with your viewers by responding to comments and building a
          community around your content.
        </span>
        <br />
        <br />
        <span>
          Monetize your channel: To start earning money from your Zynoflix
          channel, you'll need to meet the eligibility requirements for the
          Zynoflix. This includes having at least ** subscribers ** and ** watch
          hours in the past months. Once you meet the requirements, you can
          apply to join the YPP.
        </span>
        <br />
        <br />
        <span>
          Set up Google AdSense: Once your channel is approved for the Zynoflix,
          link it to a Google AdSense account to enable monetization. AdSense
          allows Zynoflix to display ads on your videos, and you earn a portion
          of the revenue generated from those ads.
        </span>
        <br />
        <br />
        <span>
          Explore additional monetization options: Beyond ads, you can diversify
          your income streams. Some popular options include:
        </span>
        <br />
        <br />
        <span>
          Channel memberships: Offer exclusive perks to your subscribers in
          exchange for a monthly fee.
        </span>
        <br />
        <br />
        <span>
          Sponsored content: Collaborate with brands to create sponsored videos
          or integrate sponsored product placements in your content.
        </span>
      </p>
    ),
  },

  {
    id: "carol",
    image: "https://img.icons8.com/clouds/256/000000/futurama-mom.png",
    label: " 5 Tips to making money with ZynoFlix",
    description: "ZynoFlix tips",
    content: (
      <p>
        1. Create Engaging and High-Quality Content <br />
        2. Build a Loyal Subscriber Base <br />
        3. Optimize Your Videos for Search <br />
        4. Monetize Your Channel <br />
        5. Diversify Your Revenue Streams
      </p>
    ),
  },

  {
    id: "homer",
    image: "https://img.icons8.com/clouds/256/000000/homer-simpson.png",
    label: "How to make money on Zynoflix step by step",
    description: "Overweight, lazy, and often ignorant",
    content: (
      <p>
        <div className="">
          <span className="pl-12"> Step 1: </span>
          <br />
          <span className="pl-24 text-base font-bold text-center">
            {" "}
            Set up a Zynoflix Channel
          </span>
          <br />
          <span>
            Sign in to Zynoflix with your Google account. Click on your profile
            picture and select "Create a channel." Follow the prompts to create
            your channel name and customize your channel layout.
          </span>
          <br />
          <br />
          <span className="pl-12"> Step 2: </span>
          <br />
          <span className="pl-24 text-base font-bold text-center">
            {" "}
            Create Engaging Content
          </span>
          <br />
          <span>
            Identify your target audience and niche. Plan your video content
            around topics that are valuable and interesting to your viewers. Use
            good quality equipment for recording, such as a camera and
            microphone. Edit your videos to enhance their quality and make them
            more engaging.
          </span>
          <br />
          <br />
          <span className="pl-12"> Step 3: </span>
          <br />
          <span className="pl-24 text-base font-bold text-center">
            {" "}
            Grow Your Subscriber Base
          </span>
          <br />
          <span>
            Consistently upload new videos to your channel. Promote your videos
            on social media platforms, your website, or other relevant online
            communities. Engage with your viewers by responding to comments and
            encouraging them to subscribe. Collaborate with other Zynoflixrs to
            reach a wider audience.
          </span>
          <br />
          <br /> <span className="pl-12"> Step 4: </span>
          <br />
          <span className="pl-24 text-base font-bold text-center">
            {" "}
            Meet the Zynoflix Partner Program Requirements
          </span>
          <br />
          <span>
            To be eligible for monetization, you need at least 1,000 subscribers
            and 4,000 watch hours in the past 12 months. Focus on creating
            high-quality content and promoting your channel to attract more
            subscribers and watch time.
          </span>
          <br />
          <br />
          <span className="pl-12"> Step 5: </span>
          <br />
          <span className="pl-24 text-base font-bold text-center">
            {" "}
            Apply for the Zynoflix Partner Program
          </span>
          <br />
          <span>
            Once you meet the eligibility requirements, go to the Zynoflix
            Partner Program website. Follow the instructions to set up an
            AdSense account (if you don't have one already) and link it to your
            Zynoflix channel. Complete the monetization application by providing
            the required information. Zynoflix will review your channel to
            ensure it complies with its policies before approving your
            application.
          </span>
          <br />
          <br />
          <span className="pl-12"> Step 6: </span>
          <br />
          <span className="pl-24 text-base font-bold text-center">
            {" "}
            Explore Additional Monetization Options
          </span>
          <br />
          <span>
            Once you're approved for the Zynoflix Partner Program, you can
            monetize your videos through ads. Consider other revenue streams
            like channel memberships, merchandise shelf, Super Chat, sponsored
            content, brand partnerships, affiliate marketing, Patreon, or
            selling digital products. Research and experiment with different
            monetization strategies to find what works best for your channel.
          </span>
          <br />
          <br />
          <span className="pl-12"> Step 7: </span>
          <br />
          <span className="pl-24 text-base font-bold text-center">
            {" "}
            Continuously Improve and Optimize
          </span>
          <br />
          <span>
            Regularly analyze your channel's performance through Zynoflix
            analytics. Identify which videos perform well and understand your
            audience's preferences. Optimize your video titles, descriptions,
            tags, and thumbnails using relevant keywords to increase visibility
            in search results. Pay attention to viewer feedback and adapt your
            content accordingly.
          </span>
        </div>
      </p>
    ),
  },
  {
    id: "2",
    image: "https://img.icons8.com/clouds/256/000000/homer-simpson.png",
    label: "10 best tips for making money on Zynoflix.",
    description: "10 best tips for making money on Zynoflix.",
    content: (
      <p>
        1. Identify a specific topic or niche that you are passionate about and
        that has a target audience. This will help you attract dedicated viewers
        who are interested in your content. <br /> <br /> 2. Invest in good
        equipment, such as a high-definition camera and a quality microphone, to
        ensure your videos have a professional look and sound. Deliver engaging
        and valuable content that keeps viewers coming back for more. <br />{" "}
        <br /> 3. Research relevant keywords and use them strategically in your
        video titles, descriptions, tags, and captions. This will help your
        videos rank higher in search results and attract more viewers. <br />{" "}
        <br /> 4. Develop a consistent brand identity for your channel,
        including a memorable channel name, logo, and consistent visual style.
        This will help you stand out and build recognition among your viewers{" "}
        <br /> <br />
        5. Respond to comments, ask for feedback, and create a sense of
        community. Encourage viewers to like, share, and subscribe to your
        channel. Engaged and loyal subscribers are more likely to support your
        channel and help it grow. <br /> <br /> 6. Don't rely solely on ad
        revenue. Explore additional monetization options like channel
        memberships, merchandise sales, sponsored content, affiliate marketing,
        crowdfunding, or creating and selling digital products. <br /> <br /> 7.
        Don't rely solely on ad revenue. Explore additional monetization options
        like channel memberships, merchandise sales, sponsored content,
        affiliate marketing, crowdfunding, or creating and selling digital
        products. <br /> <br /> 8. Collaborations can introduce your channel to
        new audiences and help you grow your subscriber base. Partner with other
        creators in your niche for cross-promotion or participate in collab
        videos. <br /> <br /> 9. Use social media platforms, your website, and
        other online communities to promote your videos. Share teasers,
        behind-the-scenes content, and engaging snippets to attract viewers and
        drive traffic to your channel. <br /> <br /> 10. Building a successful
        Zynoflix channel takes time and dedication. Post videos regularly, stick
        to a schedule, and continually improve your content. Stay persistent
        even if you face initial challenges or slow growth.
      </p>
    ),
  },
  {
    id: "3",
    image: "https://img.icons8.com/clouds/256/000000/homer-simpson.png",
    label: " How much money you can make with zynoflix?",
    description: " How much money you can make with zynoflix?",
    content: (
      <p>
        The amount of money you can make on Zynoflix can vary greatly depending
        on various factors, such as the number of views, engagement, audience
        demographics, and monetization methods. Ultimately, the amount of money
        you can make on Zynoflix depends on your content quality, audience
        engagement, channel growth, and the monetization strategies you
        implement. Consistency, audience connection, and exploring multiple
        revenue streams can help increase your earning potential.
      </p>
    ),
  },
  {
    id: "4",
    image: "https://img.icons8.com/clouds/256/000000/homer-simpson.png",
    label: " 3 simple tips for making the most of your time on zynoflix?",
    description: " 3 simple tips for making the most of your time",
    content: (
      <p className="text-gray-300">
        <div className=" space-y-2">
          <span className="font-bold text-white"> 1. Plan and Organize:</span>
          <p>
            {" "}
            Create a content calendar: Plan your video ideas, release schedule,
            and topics in advance. This helps you stay organized and ensures a
            consistent flow of content. Batch production: Film multiple videos
            in one sitting to maximize efficiency. This allows you to utilize
            your filming setup, props, and energy efficiently. Use video editing
            tools: Familiarize yourself with video editing software to
            streamline the editing process. Learn keyboard shortcuts and use
            templates or presets to save time.
          </p>
        </div>
        <div className=" space-y-2">
          <span className="font-bold text-white"> 2. Optimize Workflow :</span>
          <p>
            Create templates: Design reusable video templates for your intro,
            end screens, captions, and other graphical elements. This saves time
            in the editing process and maintains consistency across your videos.
            Use automation tools: Explore tools that can help automate certain
            tasks, such as scheduling social media posts or automatically
            generating video thumbnails. Leverage Zynoflix features: Utilize
            features like end screens, cards, and Zynoflix Studio's analytics to
            optimize your videos for better audience engagement and retention.
          </p>
        </div>
        <div className=" space-y-2">
          <span className="font-bold text-white">
            {" "}
            3. Focus on High-Value Tasks:
          </span>
          <p>
            Prioritize tasks: Identify the tasks that have the most significant
            impact on your channel's growth and revenue. Prioritize these tasks
            over less critical ones to make the most of your time. Outsource or
            delegate: As your channel grows, consider outsourcing certain tasks
            like editing, thumbnail design, or social media management to free
            up your time for content creation and strategy. Continuous learning:
            Stay updated on Zynoflix trends, algorithm changes, and best
            practices. This helps you make informed decisions and adapt your
            content strategy accordingly.
          </p>
        </div>
      </p>
    ),
  },
  {
    id: "4",
    image: "https://img.icons8.com/clouds/256/000000/homer-simpson.png",
    label: " How to make money from zynoflix: the ultimate beginner's guide.",
    description: " How to make money",
    content: (
      <p className="text-gray-300">
        <p>
          {" "}
          Certainly! Here is an ultimate beginner's guide on how to make money
          from Zynoflix:
        </p>
        <div className=" space-y-2">
          <span className="font-bold text-white">
            {" "}
            1. Set Up Your Zynoflix Channel:
          </span>
          <p>
            {" "}
            - Sign in to Zynoflix using your Google account. <br /> <br /> -
            Click on your profile picture and select "Create a channel." <br />{" "}
            <br /> - Follow the prompts to create your channel name and
            customize your channel layout.
          </p>
        </div>
        <div className=" space-y-2">
          <span className="font-bold text-white">
            {" "}
            2. Create Engaging Content:
          </span>
          <p>
            - Identify your target audience and niche. <br /> <br />- Plan your
            video content around topics that are valuable, entertaining, and
            unique. <br /> <br /> - Invest in good quality equipment, such as a
            camera and microphone, to ensure your videos have a professional
            look and sound. <br /> <br /> - Edit your videos to enhance their
            quality and make them more engaging.
          </p>
        </div>
        <div className=" space-y-2">
          <span className="font-bold text-white">
            3. Grow Your Subscriber Base:
          </span>
          <p>
            - Consistently upload new videos to your channel. <br /> <br /> -
            Promote your videos on social media platforms, your website, or
            other relevant online communities. <br /> <br /> - Engage with your
            viewers by responding to comments and encouraging them to subscribe.
            <br /> <br />- Collaborate with other Zynoflixrs in your niche for
            cross-promotion and exposure to new audiences.
          </p>
        </div>
        <div className=" space-y-2">
          <span className="font-bold text-white">
            4. Meet the Zynoflix Partner Program Requirements:
          </span>
          <p>
            - To monetize your videos, you need to meet the eligibility
            requirements set by Zynoflix, which include having at least 1,000
            subscribers and 4,000 watch hours in the past 12 months. <br />{" "}
            <br />- Focus on creating quality content and promoting your channel
            to attract more subscribers and watch time.
          </p>
        </div>
        <div className=" space-y-2">
          <span className="font-bold text-white">
            5. Apply for the Zynoflix Partner Program:
          </span>
          <p>
            - Once you meet the eligibility requirements, go to the Zynoflix
            Partner Program website. <br /> <br /> - Follow the instructions to
            set up an AdSense account (if you don't have one already) and link
            it to your Zynoflix channel. <br /> <br /> - Complete the
            monetization application by providing the required information.{" "}
            <br /> <br /> - Zynoflix will review your channel to ensure it
            complies with their policies before approving your application.
          </p>
        </div>
        <div className=" space-y-2">
          <span className="font-bold text-white">
            6. Explore Monetization Methods:
          </span>
          <p>
            - Ad Revenue: Once you are accepted into the Zynoflix Partner
            Program, you can monetize your videos through ads that appear on
            your content. <br /> <br /> - Channel Memberships: Offer exclusive
            perks and content to your subscribers in exchange for a monthly fee.
            <br /> <br /> - Merchandise Shelf: Sell merchandise related to your
            brand directly on your channel. <br /> <br /> - Sponsored Content:
            Collaborate with brands to create sponsored videos or product
            placements. <br /> <br /> - Affiliate Marketing: Promote products or
            services in your videos and earn a commission for each sale made
            through your referral links.
          </p>
        </div>
        <div className=" space-y-2">
          <span className="font-bold text-white">
            7. Optimize Your Videos for Discovery:
          </span>
          <p>
            - Conduct keyword research to understand what people are searching
            for in your niche. <br /> <br />- Use those keywords in your video
            titles, descriptions, tags, and captions to increase the likelihood
            of your videos appearing in search results. <br /> <br /> - Create
            eye-catching thumbnails and compelling titles to attract more
            clicks.
          </p>
        </div>
        <div className=" space-y-2">
          <span className="font-bold text-white">
            8. Engage with Your Audience and Build a Community:
          </span>
          <p>
            - Respond to comments and interact with your viewers <br /> <br /> -
            Encourage viewers to like, share, and subscribe to your channel.{" "}
            <br /> <br /> - Foster a sense of community through live chats, Q&A
            sessions, or dedicated social media groups.
          </p>
        </div>
        <div className=" space-y-2">
          <span className="font-bold text-white">
            9. Track and Analyze Your Channel's Performance:
          </span>
          <p>
            - Utilize Zynoflix Analytics to understand your audience,
            demographics, and video performance. <br /> <br /> - Identify
            trends, popular content, and areas for improvement. <br /> <br /> -
            Use the data to refine your content strategy and optimize your
            monetization efforts.
          </p>
        </div>
        <div className=" space-y-2">
          <span className="font-bold text-white">
            10. Stay Consistent, Be Patient, and Keep Learning:
          </span>
          <p>
            - Building a successful Zynoflix channel takes time and effort.{" "}
            <br /> <br /> - Consistently upload high-quality content and stick
            to a schedule. <br /> <br /> - Stay up-to-date with Zynoflix trends,
            algorithm changes, and best practices. <br /> <br /> - Continuously
            learn and adapt your strategies to meet the evolving needs of your
            audience. Remember, success on Zynoflix doesn't happen overnight. It
            requires dedication, persistence, and a focus on creating valuable
            content that resonates with your audience. Keep refining your
            skills, engaging with your viewers, and exploring various
            monetization avenues
          </p>
        </div>
      </p>
    ),
  },
  {
    id: "5",
    image: "https://img.icons8.com/clouds/256/000000/homer-simpson.png",
    label: " How to make money from streamed videos on Zynoflix?",
    description: " How to make money from streamed videos ",
    content: (
      <p className="text-gray-300">
        <div className="space-y-2">
          <p>
            <strong>1. Enable Ads on Your Live Streams:</strong>
            <br />
            - Once you meet the eligibility requirements (1,000 subscribers and
            4,000 watch hours in the past 12 months), you can apply for the
            Zynoflix Partner Program.
            <br />- Once accepted, you can enable ads to run on your live
            streams. Ad revenue is generated based on ad impressions,
            engagement, and advertiser demand.
          </p>

          <p>
            <strong>2. Super Chat and Super Stickers:</strong>
            <br />
            - Super Chat allows viewers to pay to have their messages
            highlighted during live chats, enabling them to stand out and
            receive acknowledgment from the streamer.
            <br />- Super Stickers are animated stickers that viewers can
            purchase and send during live streams to support and engage with the
            streamer.
          </p>

          <p>
            <strong>3. Channel Memberships:</strong>
            <br />
            - If you have a dedicated and engaged audience, you can offer
            channel memberships.
            <br />- Viewers can choose to become members by paying a monthly
            fee. In return, they gain access to exclusive perks such as badges,
            custom emojis, exclusive content, or community features.
          </p>

          <p>
            <strong>4. Brand Partnerships and Sponsorships:</strong>
            <br />
            - As your live streams gain traction, you may attract brand
            partnerships or sponsorships.
            <br />- Collaborate with brands that align with your content and
            audience, and negotiate sponsorship deals where you promote their
            products or services during your live streams.
          </p>

          <p>
            <strong>5. Affiliate Marketing:</strong>
            <br />
            - Incorporate affiliate marketing into your live streams by
            recommending products or services relevant to your content.
            <br />- Include affiliate links in your video description or use
            tools like affiliate overlays to display affiliate links during your
            live streams. You earn a commission for each sale made through your
            referral links.
          </p>

          <p>
            <strong>6. Donations and Crowdfunding:</strong>
            <br />
            - Some viewers may voluntarily donate money to support your live
            streams.
            <br />- You can use platforms like Zynoflix's Super Chat, Patreon,
            or other crowdfunding platforms to accept donations from your
            audience.
          </p>

          <p>
            <strong>7. Merchandise Sales:</strong>
            <br />
            - Utilize your live streams to promote and sell merchandise related
            to your brand or channel.
            <br />- Direct viewers to your online store or use features like
            Zynoflix's merchandise shelf to showcase and sell your products
            during your live streams.
          </p>

          <p>
            Remember, to monetize live streams on Zynoflix, you'll need to meet
            the Zynoflix Partner Program requirements and follow their policies.
            Focus on providing engaging and valuable content, growing your
            audience, and exploring various monetization avenues to maximize
            your earnings from live streaming.
          </p>
        </div>
      </p>
    ),
  },
  {
    id: "6",
    image: "https://img.icons8.com/clouds/256/000000/homer-simpson.png",
    label: " 9. How to monetize your own videos on Zynoflix?",
    description: "  How to monetize your  ",
    content: (
      <p className="text-gray-300">
        <div className="space-y-2">
          <p>
            <strong>1. Meet the Eligibility Requirements:</strong>
            <br />
            - You need to meet the Zynoflix Partner Program requirements, which
            include having at least 1,000 subscribers and 4,000 watch hours in
            the past 12 months.
            <br />- Focus on consistently creating quality content and promoting
            your channel to attract more subscribers and watch time.
          </p>

          <p>
            <strong>2. Set up an AdSense Account:</strong>
            <br />
            - If you don't have an AdSense account already, create one. AdSense
            is Google's advertising platform that allows you to monetize your
            Zynoflix channel.
            <br />- Ensure that your AdSense account is in good standing and
            compliant with AdSense policies.
          </p>

          <p>
            <strong>3. Enable Monetization on Your Zynoflix Channel:</strong>
            <br />
            - Sign in to your Zynoflix account and go to Zynoflix Studio.
            <br />
            - In the left-hand menu, click on "Monetization."
            <br />
            - Follow the on-screen instructions to review and accept the terms
            of the Zynoflix Partner Program.
            <br />- Connect your Zynoflix channel to your AdSense account.
          </p>

          <p>
            <strong>4. Set Your Monetization Preferences:</strong>
            <br />
            - In the Zynoflix Studio's Monetization section, click on
            "Monetization preferences."
            <br />
            - Choose the types of ads you want to show on your videos, such as
            skippable video ads or display ads.
            <br />- You can also enable other features like channel memberships,
            merchandise shelf, and Super Chat if you meet the eligibility
            criteria.
          </p>

          <p>
            <strong>5. Advertise-Friendly Guidelines:</strong>
            <br />
            - Familiarize yourself with Zynoflix's Advertiser-Friendly Content
            Guidelines.
            <br />
            - Ensure that your videos comply with these guidelines to maintain a
            healthy monetization status.
            <br />- Avoid content that violates copyright laws, is sexually
            explicit, hateful, or promotes violence.
          </p>

          <p>
            <strong>6. Track Your Earnings:</strong>
            <br />
            - Zynoflix provides an Analytics section where you can track your
            channel's performance, estimated earnings, ad revenue, and other key
            metrics.
            <br />- Utilize this data to understand your audience and improve
            your content strategy.
          </p>

          <p>
            <strong>
              Remember, while monetizing your videos on Zynoflix can be a great
              way to earn income, it's essential to focus on creating valuable
              and engaging content. Building an audience and consistently
              producing quality videos are key factors in maximizing your
              earnings on the platform.
            </strong>
          </p>

          <p>
            <strong>
              Remember, while monetizing your videos on Zynoflix can be a great
              way to earn income, it's essential to focus on creating valuable
              and engaging content. Building an audience and consistently
              producing quality videos are key factors in maximizing your
              earnings on the platform.
            </strong>
          </p>
        </div>
      </p>
    ),
  },
]

interface AccordionLabelProps {
  label: string
  image: string
  description: string
}

function AccordionLabel({ label, image, description }: AccordionLabelProps) {
  return (
    <Group>
      {/* <Avatar src={image} radius="xl" size="lg" /> */}
      <div>
        <Text color="white">{label}</Text>
        <Text size="sm" c="dimmed" fw={400}>
          {description}
        </Text>
      </div>
    </Group>
  )
}

const FAQ = () => {
  const items = charactersList.map((item) => (
    <Accordion.Item value={item.id} key={item.label}>
      <Accordion.Control>
        <AccordionLabel {...item} />
      </Accordion.Control>
      <Accordion.Panel>
        <Text size="sm">{item.content}</Text>
      </Accordion.Panel>
    </Accordion.Item>
  ))

  return (
    <div className="pt-24">
      <div className="text-white">
        <div className="text-center py-8 mb-16">
          <p className="mt-4 text-sm leading-7 text-gray-200 font-regular">
            F.A.Q
          </p>
          <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight text-gray-200">
            Frequently Asked <span className="text-indigo-600">Questions</span>
          </h3>
        </div>
        <div className="">
          <div className="px-6  pb-12 lg:px-24">
            <Accordion
              radius={"xl"}
              chevronPosition="right"
              variant="contained"
            >
              {items}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ
