// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-group form{
    display: flex;
    width: 100%;
    /* max-width: 360px; */
    column-gap: 10px;
    justify-content: space-between;
  }
  
  .otp-input {
    width: 100%;
    /* height: 60px; */
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    padding: 0.5rem !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/otpCheckBox/Styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,gBAAgB;IAChB,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,4BAA4B;IAC5B,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,0BAA0B;EAC5B","sourcesContent":[".otp-group form{\n    display: flex;\n    width: 100%;\n    /* max-width: 360px; */\n    column-gap: 10px;\n    justify-content: space-between;\n  }\n  \n  .otp-input {\n    width: 100%;\n    /* height: 60px; */\n    /* border: 1px solid #ccc; */\n    border-radius: 5px;\n    text-align: center;\n    font-size: 32px;\n    font-weight: bold;\n    line-height: 1;\n    padding: 0.5rem !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
