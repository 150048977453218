import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Image,
  MultiSelect,
  SimpleGrid,
  TextInput,
  Textarea,
  Text,
  useMantineTheme,
  rem,
  Group,
  Select,
} from "@mantine/core";
import { createFormContext } from "@mantine/form";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE, MIME_TYPES } from "@mantine/dropzone";

import { useUserFormContext } from "./form-context";
import TextareaField from "./TextareaField";
import DropzoneFieldWrapper from "./DropzoneFieldWrapper";
import { tFormData, uploadFormSchema } from "./uploadFormSchema";
import { CategoryState } from "../../../utils/genericTypes";
import { fetchCategories } from "../../../utils/util";
import { toast } from "react-toastify";

// Create the form context
const [FormProvider, , useForm] = createFormContext();

interface VideoFieldProps {
  setValue: any;
  setSelectedThumbnail: any;
  register: any;
  errors: any;
  setDuration: any;
  handleSubmit: any;
  onSubmit: any;
  setSelectedFile: any;
  onClickValidationHandler: any;
}

const VideoField: React.FC<VideoFieldProps> = ({
  setValue,
  setSelectedThumbnail,
  register,
  errors,
  setDuration,
  handleSubmit,
  onSubmit,
  setSelectedFile,
  onClickValidationHandler,
}) => {
  function convertHMS(props: { value: string }) {
    const { value } = props;
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = `${Math.floor(sec / 3600)}`; // get hours
    let minutes = `${Math.floor((sec - +hours * 3600) / 60)}`; // get minutes
    let seconds = `${sec - +hours * 3600 - +minutes * 60}`; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (+hours < 10) {
      hours = "0" + hours;
    }
    if (+minutes < 10) {
      minutes = "0" + minutes;
    }
    if (+seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  }

  const [customList, setCustomList] = useState<CategoryState[]>([]);

  React.useEffect(() => {
    fetchCategories({
      setCategories: setCustomList,
      static: true,
    });
  }, []);

  const getAudioVideoDuration = async (file: File) => {
    return new Promise((resolve, reject) => {
      const media = document.createElement("video");
      media.onloadedmetadata = () => {
        resolve(media.duration);
      };
      media.src = URL.createObjectURL(file);
    });
  };
  const fileInut = useRef();

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-background_item mt-2 py-6 px-8 rounded-2xl">
          {uploadFormSchema.map((value: any, index: any) => {
            const Tag = value.tag;
            return (
              <InputFieldWrapper title={value.label} des={value.des}>
                {value.tag === "thumbnailFile" ||
                value.tag === "preview_video" ||
                value.tag === "videoFile" ? (
                  <>
                    <input
                      ref={fileInut}
                      type={value.type}
                      inputMode={value.inputMode}
                      className="h-14 w-full"
                      accept={value.accept}
                      {...register(value.tag as keyof tFormData, {
                        required: `Please enter the ${value.name} `,
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (!event.target.files) return;
                          const file = event.target.files[0];

                          const fileSizeInBytes = file.size;
                          const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convert to MB
                          if (fileSizeInMB > value.fileLimit) {
                            toast.error(value.fileerror);
                            return;
                          }

                          if (
                            value.tag === "videoFile" ||
                            value.tag === "preview_video"
                          ) {
                            new Promise(async (resolve, reject) => {
                              let reader = new FileReader();
                              reader.onload = function () {
                                if (!reader.result) return;
                                let aud = new Audio(reader.result as string);
                                aud.onloadedmetadata = function () {
                                  resolve(
                                    convertHMS({ value: `${aud.duration}` })
                                  );
                                };
                              };
                              const durationVideo: any =
                                getAudioVideoDuration(file);
                              durationVideo
                                .then(function (value: any) {
                                  setDuration(value);
                                })
                                .catch(function (error: any) {
                                  console.error("Error:", error);
                                });

                              reader.readAsDataURL(file);
                            }).then((d) => {
                              setSelectedFile(file);

                              // setDuration(d)
                              return true;
                            });
                          } else if (value.name === "thumbnailFile") {
                            setSelectedFile(event.target.files[0]);
                          }
                          return false;
                        },
                      })}
                      placeholder={value.placeholder}
                    />
                  </>
                ) : value.type === "select" ? (
                  <Select
                    onChange={(selectedValues) => {
                      register(value.tag as keyof tFormData, {
                        required: "Please select at least one value",
                        value: selectedValues, // You can include the selected values in the validation object
                      });
                    }}
                    searchable
                    type="fieldGroup"
                    // {...register(value.name as keyof tFormData, {
                    //   required: `Please select a ${value.name}`,
                    // })}
                    className="FormSubmittedText-submit-multi FormSubmittedText-submit "
                    placeholder={value.placeholder}
                    data={customList?.map((value) => value.name)}
                    clearable
                  />
                ) : value.type === "cost" ? (
                  <div className="">
                    <input
                      pattern="[0-9]*"
                      inputMode="numeric"
                      type="number"
                      name={value.tag}
                      placeholder={value.placeholder}
                      className="px-6 border-none outline-none text-lg font-semibold py-3 rounded-2xl w-full bg-background_body"
                      {...register(value.tag as keyof tFormData, {
                        required: `Please enter the ${value.name} `,
                      })}
                    />
                  </div>
                ) : (
                  <div className="">
                    <textarea
                      name={value.tag}
                      placeholder={value.placeholder}
                      rows={value.tag === "description" ? 12 : 3}
                      className="px-6 border-none outline-none text-lg font-semibold py-6 rounded-2xl w-full bg-background_body"
                      type="text"
                      {...register(value.tag as keyof tFormData, {
                        required: `Please enter the ${value.name} `,
                      })}
                    />
                  </div>
                )}

                <p className="text-red-500 text-sm lg:text-sm font-medium">
                  {errors?.[value.tag]?.message}
                </p>
              </InputFieldWrapper>
            );
          })}
        </div>
        <div className="flex items-center justify-end">
          <button
            onClick={onClickValidationHandler}
            className="default-button-color my-8 ml-auto px-6 py-3 rounded-xl text-base font-semibold"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default VideoField;

interface InputFieldWrapperProps {
  children: React.ReactNode;
  title: string;
  des: string;
}

function InputFieldWrapper({ children, title, des }: InputFieldWrapperProps) {
  return (
    <div className="flex lg:flex-row flex-col pb-9 gap-3 lg:gap-9 items-start lg:items-center">
      <div className="w-full lg:w-1/3">
        <h2 className="text-lg font-semibold">{title}</h2>
        <p className="text-sm font-normal text-gray-500">{des}</p>
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
}

// import { FileInput, Group, Text, rem } from "@mantine/core"
// import { Dropzone } from "@mantine/dropzone"
// import { IconX } from "@tabler/icons-react"
// import { IconPhoto } from "@tabler/icons-react"
// import { IconUpload } from "@tabler/icons-react"
// import React, { useState, useRef } from "react"
// import { UploadDropzone } from "react-uploader"
// import { Uploader } from "uploader"
// const uploader = Uploader({
//   apiKey: "free", // Get production API keys from Bytescale
// })

// // Configuration options: https://www.bytescale.com/docs/upload-widget/frameworks/react#customize
// const options = { multi: true }
// function VideoField() {
//   const [selectedFile, setSelectedFile] = useState(null)
//   const fileInputRef: any = useRef()

//   const handleSubmit = (event: any) => {
//     event.preventDefault()
//     if (fileInputRef.current.files.length > 0) {
//       alert(`Selected file - ${fileInputRef.current.files[0].name}`)
//     } else {
//       alert("No file selected")
//     }
//   }
//   console.log(fileInputRef, "fileInputRef")

//   return (
//     <form onSubmit={handleSubmit}>
//       <Dropzone
//         onDrop={(files) => console.log("accepted files", files)}
//         onReject={(files) => console.log("rejected files", files)}
//         maxSize={3 * 1024 ** 2}
//       >
//         <Group mih={220} style={{ pointerEvents: "none" }}>
//           <Dropzone.Accept>
//             <IconUpload
//               style={{
//                 width: rem(52),
//                 height: rem(52),
//                 color: "var(--mantine-color-blue-6)",
//               }}
//               stroke={1.5}
//             />
//           </Dropzone.Accept>
//           <Dropzone.Reject>
//             <IconX
//               style={{
//                 width: rem(52),
//                 height: rem(52),
//                 color: "var(--mantine-color-red-6)",
//               }}
//               stroke={1.5}
//             />
//           </Dropzone.Reject>
//           <Dropzone.Idle>
//             <IconPhoto
//               style={{
//                 width: rem(52),
//                 height: rem(52),
//                 color: "var(--mantine-color-dimmed)",
//               }}
//               stroke={1.5}
//             />
//           </Dropzone.Idle>

//           <div>
//             <Text size="xl" inline>
//               Drag images here or click to select files
//             </Text>
//             <Text size="sm" c="dimmed" inline mt={7}>
//               Attach as many files as you like, each file should not exceed 5mb
//             </Text>
//           </div>
//         </Group>
//       </Dropzone>
//       <br />
//       <button type="submit">Submit</button>
//     </form>
//   )
// }

// export default VideoField
