import { Modal } from "@mantine/core";
import React, { useEffect, useState } from "react";

type ImageDisplayProps = {
  imageUrl: string;
  Page: string;
};

const ImageDisplay: React.FC<ImageDisplayProps> = ({ imageUrl, Page }) => {
  const localStorageKey = `visited${Page}`;
  const localStorageValue = localStorage.getItem(localStorageKey);

  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    if (localStorageValue === "true" || localStorageValue === null) {
      // If the localStorage item doesn't exist or is "true," it's a new user.
      // Set the localStorage item to "true" to remember the visit.
      localStorage.setItem(localStorageKey, "true");
      setShowImage(true);
    }
  }, [localStorageValue, Page]);

  const handleButtonClick = () => {
    setShowImage(false);
    localStorage.setItem(localStorageKey, "false");
  };

  return (
    <div className="">
      <Modal
        closeOnClickOutside={false}
        size={"xl"}
        opened={showImage}
        onClose={handleButtonClick}
        title="Poster"
      >
        <img className="w-full rounded-2xl" src={imageUrl} alt="Your Image" />
      </Modal>
    </div>
  );
};

export default ImageDisplay;
