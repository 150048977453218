import React, { useContext, useEffect, useState } from "react"
import { Heart } from "react-feather"
import { Oval } from "react-loader-spinner"
import { MyContext } from "./MyProvider/MyProvider"
import { createWishlistOperation } from "../utils/controllers/wishlistController"
import { useLocation, useNavigate } from "react-router-dom"
import { fetchWishListItems } from "../utils/userController"
import { USER_ID_NAME } from "../utils/variables"

interface BookmarkProps {
  absolute?: boolean
  video_id: string
  isOwnVideo?: boolean
}

const wishlistActions = {
  ADD: "add",
  REMOVE: "remove",
}

const OvalLoader = () => {
  return (
    <Oval
      height={20}
      width={20}
      color="white"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#4fa94d"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  )
}

const Bookmark: React.FC<BookmarkProps> = (props: BookmarkProps) => {
  const { video_id } = props

  const [wishlistItems, setWishlistItems] = useState<any>()
  const navigate = useNavigate()
  const [inWishlist, setInWishlist] = useState<boolean>(false)

  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const [requestStatus, setRequestStatus] = useState()
  const toggleWishlistItem = async (video_id: string, type: string) => {
    setLoading(true)
    setInWishlist(type === wishlistActions.ADD ? true : false)
    await createWishlistOperation({
      videoId: video_id,
      operationType: type,
      setWishlistItems,
      setRequestStatus,
    })
    setLoading(false)
  }

  useEffect(() => {
    const ID = localStorage.getItem(USER_ID_NAME) || ""
    fetchWishListItems({ userId: ID, setWishlistItems, setRequestStatus })
  }, [])

  const getWishlistIfExists = (video_id: string): string | undefined => {
    if (!Array.isArray(wishlistItems)) {
      return undefined
    }

    const obj = wishlistItems.find((obj: any) => obj.video_id === video_id)
    return obj ? obj._id : undefined
  }

  const ID = localStorage.getItem(USER_ID_NAME) || ""
  const onClickHandler = (props: { type: string; video_id: string }) => {
    if (ID) {
      const { video_id, type } = props
      const ID = localStorage.getItem(USER_ID_NAME) || ""
      setInWishlist(type === wishlistActions.ADD ? true : false)
      toggleWishlistItem(video_id, type)
      fetchWishListItems({ userId: ID, setWishlistItems, setRequestStatus })
    } else {
    }
  }

  useEffect(() => {
    const inWishlistID = getWishlistIfExists(video_id)
    setInWishlist(!!inWishlistID)
  }, [wishlistItems, video_id])

  return (
    <>
      <div
        className={`time-display ${
          props?.absolute && "absolute px-2 right-2.5 top-3.5"
        } text-xs leading-4 py-1 rounded z-[5] cursor-pointer `}
        onClick={() => {
          onClickHandler({
            type: inWishlist ? wishlistActions.REMOVE : wishlistActions.ADD,
            video_id,
          })
          fetchWishListItems({
            userId: ID,
            setWishlistItems,
            setRequestStatus,
          })
        }}
        data-inwishlist={inWishlist}
      >
        {loading ? (
          <OvalLoader />
        ) : (
          <Heart
            width={20}
            className={`${
              inWishlist
                ? "stroke-active-border fill-pink-600 stroke-pink-600 fill-active-border"
                : "hover:fill-active-border"
            }`}
          />
        )}
      </div>
    </>
  )
}

export default Bookmark
