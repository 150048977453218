export const RE_DIGIT = new RegExp(/^\d+$/);

export const REGEX_FIRST_LAST_NAME = /^[a-z ,.'-]+$/i;

export const REGEX_EMAIL = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

export const REGEX_USERNAME = /^(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;

export const REGEX_PHONE = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export const NAVBAR_FOOTER_RESTRICTER_PATH = ['/more-info', '/login', '/signup', '/signup-otp', '/forgot-password', '/reset-password', '/change-password'];

export const SESSION_ALIVE_FOR = 24 * 60 * 60 * 1000; // 24 hours in ms

export const temp_video_id = '63976e4f2e772540b34a7b5d';

export const API_CATEGORIES = { LIKES: 'likes', VIEWS: 'views', DOWNLOADS: 'downloads', RATINGS: 'ratings' };

export const PAY_TO_UPLOAD_CODE = 'ETUBEE_PAY001';

export const FREE_TO_UPLOAD_CODE = 'ETUBEE_FREE001';

export const EMAIL_EXISTS_CHECK_STRING = 'email already exist';

export const USERNAME_EXISTS_CHECK_STRING = 'user_name already exist';
