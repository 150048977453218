import jwtDecode from "jwt-decode";
import { CookiesType, jwtToken, tVideoState } from "./genericTypes";
import { SESSION_ALIVE_FOR } from "./constants";
import {
  APP_NAME,
  ETUBES_URL_PREFIX,
  RAZORPAY_KEY,
  STATIC_TOKEN,
} from "./variables";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { handleFormSubmit } from "../Pages/ApplyForm";

interface CookieOperatorType {
  cookies: CookiesType;
  name: string;
}

const TOKEN_NAME: string = "etubes_token";

const USER_ID_NAME: string = "etubes_id";

// const ETUBES_URL_PREFIX = VITE_DOMAIN;
const ETUBES_URL_PREFIX_ADMIN = ETUBES_URL_PREFIX;

const ETUBES_URL_PREFIX1 = "http://localhost:5001/v1";

const CURRENCY_UNIT = "INR";

export const urlPrefixGen = () => `${ETUBES_URL_PREFIX}`;

export const getToken = (props: CookieOperatorType) => props.cookies;

export const decodeToken = (props: CookieOperatorType) => {
  const token = props.cookies?.ETubes.token;
  if (token) {
    const decoded: jwtToken = jwtDecode(token);
    return decoded;
  }
  return null;
};

export const fetchToken = (tokenName: string) => {
  return localStorage.getItem(tokenName) || "";
};

export const fetchUserId = () => localStorage.getItem(USER_ID_NAME) || "";

export const incrementVideoAttributeCount = (
  video_id: string,
  category: string
) => {
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "PUT",
  };
  fetch(`${ETUBES_URL_PREFIX}/${category}/${video_id}`, options);
};

export const viewProfile = async (
  userId: string,
  setProfile: Function,
  staticToken?: boolean
) => {
  try {
    const URL = `${ETUBES_URL_PREFIX}/view_profile`;
    const TOKEN = staticToken ? STATIC_TOKEN : fetchToken(TOKEN_NAME);
    const options = {
      body: JSON.stringify({
        _id: userId,
      }),
      headers: {
        Authorization: STATIC_TOKEN,
        "Content-type": "application/json",
      },
      method: "POST",
    };
    const response = await fetch(URL, options).then((res) => res.json());
    console.log(response, "RESPONSE_PROFILE");

    if (response.status_code === 200 && response.status === "success") {
      setProfile(response.result[0]);
    } else {
      // TODO: trigger toast with error message
    }
  } catch (error) {
    console.log(error);
  }
};

export const getProfileDetails = async (
  userId: string,
  setSubscripstionDetails: Function
) => {
  try {
    const URL = `${ETUBES_URL_PREFIX}/view_profile`;
    const TOKEN = fetchToken(TOKEN_NAME);
    const options = {
      body: JSON.stringify({
        _id: userId,
      }),
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "POST",
    };
    const response = await fetch(URL, options).then((res) => res.json());
    console.log(getProfileDetails, "RESPONSE_PROFILE");

    if (response.status_code === 200 && response.status === "success") {
      setSubscripstionDetails(response.result[0]);
    } else {
      // TODO: trigger toast with error message
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchCategories = (props: {
  setCategories: Function;

  static?: boolean;
}) => {
  const TOKEN = fetchToken(TOKEN_NAME);
  const jwtToken = props?.static ? STATIC_TOKEN : TOKEN;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/categories`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          props.setCategories(data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log("--- Error while fetching categories", error);
  }
};
export const fetchTvseries = (props: {
  setTvseries: Function;
  setRequestStatus: Function;
  static?: true;
}) => {
  const TOKEN = fetchToken(TOKEN_NAME);
  const jwtToken = STATIC_TOKEN;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };
  try {
    props.setRequestStatus({ loading: true, success: false, error: false });
    fetch(`${ETUBES_URL_PREFIX}/tv_series/`, options)
      .then((response) => response.json())
      .then((data) => {
        props.setRequestStatus({ loading: false, success: true, error: false });
        if (data.status_code === 200) {
          props.setTvseries(data.result);
        }
      })
      .catch((err) => {
        props.setRequestStatus({ loading: false, success: false, error: true });
        console.log(err);
      });
  } catch (error) {
    props.setRequestStatus({ loading: false, success: false, error: true });
    console.log("--- Error while fetching categories", error);
  }
};

export const fetchTrendingVideos = (props: {
  cookies: CookiesType;
  setVideos: Function;
  static?: boolean;
}) => {
  const COOKIE_KEY = Object.keys(props.cookies)[0];
  const jwtToken = props?.static
    ? STATIC_TOKEN
    : props.cookies[COOKIE_KEY]?.token;

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/get_all_trending_videos`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          props.setVideos(data.result);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

export const fetchFeaturedVideos = (props: {
  cookies: CookiesType;
  setFeaturedVideos: Function;
  static?: boolean;
}) => {
  const { setFeaturedVideos } = props;
  const COOKIE_KEY = Object.keys(props.cookies)[0];
  const jwtToken = props?.static
    ? STATIC_TOKEN
    : props.cookies[COOKIE_KEY]?.token;

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/all_feature_videos`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          setFeaturedVideos(data.result);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

export const fetchBannerImages = (props: {
  setBannerImages: Function;
  static: boolean;
}) => {
  const jwtToken = props?.static ? STATIC_TOKEN : fetchToken(TOKEN_NAME);

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/banner_image`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          props.setBannerImages(data.result);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

export const fetchSingleVideo = (props: {
  cookies: CookiesType;
  videoId: string;
  setActiveVideo: Function;
  setUrl: Function;
  pathname?: string;
}) => {
  const { pathname } = props;
  const jwtToken = fetchToken(TOKEN_NAME) || STATIC_TOKEN;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/videos/${props.videoId}`, options)
      .then((response) => response.json())
      .then((data) => {
        if (
          data.status_code === 200 &&
          data.result.length > 0 &&
          data.result[0]?.url
        ) {
          props.setUrl(
            pathname !== "/videos"
              ? data.result[0]?.preview_video || data.result[0].url
              : data.result[0].url
          );
          props.setActiveVideo({ url: data.result[0], id: props.videoId });
          return data.result[0].url;
        } else {
          console.log(data);
          return "";
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

export const fetchSingleVideoIn = (props: {
  AUTH: string;
  videoId: string;
  setSingleActiveVideo: Function;
  addActionHandler: Function;
  setRequestStatus: Function;
}) => {
  const ID = localStorage.getItem(USER_ID_NAME);
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: props.AUTH,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/videos/${props.videoId}`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          const singleActiveVideo = {
            video_id: data.result[0]._id,
            video_cost: data.result[0].cost,
            video_title: data.result[0].title,
            video_description: data.result[0].description,
            video_url: data.result[0].url,
            created_by_id: ID,
            created_by_name: data.result[0].created_by_name,
          };
          props.setSingleActiveVideo(singleActiveVideo);
          props.addActionHandler({
            singleActiveVideo,
            setRequestStatus: props.setRequestStatus,
          });
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

export const checkSession = (cookies: CookiesType, setAction: Function) => {
  const token = cookies?.Etubes?.token;
  if (token) {
    const decoded: jwtToken = jwtDecode(token);
    const isSessionActive =
      +decoded.iat < new Date().getTime() - SESSION_ALIVE_FOR;
    setAction(isSessionActive);
  }
};

export const checkTokenValidity = (
  tokenName: string,
  setAction: Function,
  setValidSession: Function
) => {
  const TOKEN = localStorage.getItem(tokenName);
  if (TOKEN) {
    const decoded: jwtToken = jwtDecode(TOKEN);
    const isSessionActive =
      +decoded.iat < new Date().getTime() - SESSION_ALIVE_FOR;
    setAction(isSessionActive);
    setValidSession(isSessionActive);
  } else {
    setAction(false);
    setValidSession(false);
  }
};

export const fetchPlaylistBannerImages = async (props: {
  setPlaylistBannerImages: Function;
  static?: true;
}) => {
  const URL = `${ETUBES_URL_PREFIX}/bottom_image`;
  const TOKEN = props?.static ? STATIC_TOKEN : fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };
  try {
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200) {
      props.setPlaylistBannerImages(response.result);
    } else {
      console.log(
        "-- Error while fetching playlist banner images --",
        response.status_code,
        " ",
        response.message
      );
    }
  } catch (error) {
    console.log("Error while fetching playlist banner images >>", error);
  }
};
export const fetchGetAllUser = async (props: {
  setAlluser: Function;
  static?: true;
  token?: string;
}) => {
  const URL = `${ETUBES_URL_PREFIX}/get_alluser/`;
  const TOKEN = props?.static ? STATIC_TOKEN : fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };
  try {
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200) {
      props.setAlluser(response.result);
    } else {
      console.log(
        "-- Error while fetching playlist banner images --",
        response.status_code,
        " ",
        response.message
      );
    }
  } catch (error) {
    console.log("Error while fetching playlist banner images >>", error);
  }
};

export const fetchUploadList = async (
  userId: string,
  setUploadList: Function
) => {
  const URL = `${ETUBES_URL_PREFIX}/myupload_list/${userId}`;
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };
  try {
    fetch(URL, options)
      .then((response) => response.json())
      .then((res) => {
        if (res.status_code === 200) {
          setUploadList(res.result);
        }
      });
  } catch (error) {
    console.log(error);
  }
};

export const saveProfile = async (
  body: any,
  setProfile: Function,
  setRequestStatus: Function
) => {
  const URL = `${ETUBES_URL_PREFIX}/edit_profile`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options: any = {
    headers: {
      Authorization: TOKEN,
    },
    method: "PUT",
    body: body,
  };

  try {
    setRequestStatus({
      loading: false,
      success: false,
      error: false,
      buttonLoading: true,
    });
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );

    if (response.status_code === 200 && response.status === "success") {
      setProfile(body);
      toast("Successfully updated profile!");
    } else {
      toast("Something went wrong!");
    }
    setRequestStatus({
      loading: false,
      success: true,
      error: false,
      buttonLoading: false,
    });
  } catch (error) {
    setRequestStatus({
      loading: false,
      success: false,
      error: true,
      buttonLoading: false,
    });
    toast("Failed to update profile");
  }
};

export const updateLikes = async (video_id: string) => {
  const URL = `${ETUBES_URL_PREFIX}/likes/[VIDEO_ID]`;
  const TOKEN = fetchToken(TOKEN_NAME);
  const UPDATED_URL = URL.replace("[VIDEO_ID]", video_id);
  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "PUT",
  };

  try {
    const response = await fetch(UPDATED_URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      toast("Successfully updated!");
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while liking the video", error);
  }
};

export const removeLikes = async (video_id: string) => {
  const URL = `${ETUBES_URL_PREFIX}/dis_likes/[VIDEO_ID]`;
  const TOKEN = fetchToken(TOKEN_NAME);
  const UPDATED_URL = URL.replace("[VIDEO_ID]", video_id);
  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "PUT",
  };

  try {
    const response = await fetch(UPDATED_URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      toast("Successfully updated!");
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while liking the video", error);
  }
};

export const logout = () => {
  // const navigate = useNavigate()
  localStorage.removeItem(TOKEN_NAME);
  localStorage.removeItem(USER_ID_NAME);
  // navigate(to)
  window.location.href = "/login";
  // navigate(0)
};

export const tMode = { RESET: "reset", CHANGE: "change" };

export const changePassword = async (
  mode: string,
  _id: string,
  password: string,
  // toast: Function,
  oldPassword?: string
) => {
  const URL =
    mode === tMode.CHANGE
      ? `${ETUBES_URL_PREFIX}/change_password`
      : `${ETUBES_URL_PREFIX}/reset_password`;
  const options = generateRequestOptions(mode, _id, password, oldPassword);

  const data = await fetch(URL, options).then((res) => res.json());
  if (data.status_code === 200) {
    window.location.href = "/login";
    toast("Successfully Password has been Chnaged");
  } else {
    toast(data.message);
    console.log("error");
  }
};

const generateRequestOptions = (
  mode: string,
  _id: string,
  password: string,
  oldPassword?: string
) => {
  console.log(password, "password");

  if (mode === tMode.CHANGE) {
    const TOKEN = fetchToken(TOKEN_NAME);
    const headers = {
      Authorization: TOKEN,
      "Content-type": "application/json",
    };
    return {
      headers,
      method: "POST",
      body: JSON.stringify({
        _id,
        current_password: oldPassword,
        new_password: password,
      }),
    };
  } else {
    return {
      headers: {
        "Content-type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        _id,
        password: "password",
      }),
    };
  }
};

export const openPayModal = async (
  amt: number,
  gstFee: string,
  processingFee: string,
  buyerData: {
    _id: string;
    email: string;
    first_name: string;
    last_name: string;
    user_name: string;
    contact_number: string;
  },
  videoData: {
    video_id: string;
    video_title: string;
    video_description: string;
    video_url: string;
    cost?: string;
    downloads: number;
    purchaser_id?: string;
  },
  navigate: Function,
  callback: Function,
  setRequestStatus: Function
) => {
  const amount = amt * 1; // Razorpay consider the amount in paise
  const { user_name, first_name, last_name, _id, email, contact_number } =
    buyerData;
  const {
    video_id,
    cost,
    purchaser_id,
    video_description,
    video_title,
    video_url,
    downloads,
  } = videoData;
  const name = `${first_name} ${last_name}`;
  const TOKEN = fetchToken(TOKEN_NAME);
  const USER_ID = fetchUserId();
  const headers = {
    Authorization: TOKEN,
    "Content-type": "application/json",
  };

  let etubes_order_id = "";

  const options = {
    key: RAZORPAY_KEY,
    amount,
    name: APP_NAME,
    description: "",
    order_id: "",
    handler: function (response: any) {
      // { razorpay_signature: string; razorpay_order_id: string; razorpay_payment_id: string }
      console.log("rzp response", response);
      const { razorpay_order_id, razorpay_payment_id, razorpay_signature } =
        response;
      const body = {
        amount_paid: amount,
        status: "paid",
        razorpay_order_id,
        razorpay_payment_id,
        razorpay_signature,
        _id: etubes_order_id,
      };

      const options = {
        method: "PUT",
        headers,
        body: JSON.stringify(body),
      };

      fetch(`${ETUBES_URL_PREFIX}/update_order`, options)
        .then(async (response) => await response.json())
        .then((res) => {
          // TODO: redirect to payment controller page
          // to show the status of the payment
          callback({
            is_update: false,
            keys: { downloads: ++videoData.downloads },
            setRequestStatus,
            videoId: video_id,
          });
          navigate(`/payment-info?id=${etubes_order_id}`, {
            state: { etubes_order_id },
          });
        })
        .catch((error: unknown) => console.log(error));
    },
    prefill: {
      name,
      email,
      contact: contact_number,
    },
    notes: {
      address: "Hello World",
    },
    theme: {
      color: "#528ff0",
    },
  };
  const body = {
    name,
    phone_no: contact_number,
    email,
    amount,
    currency: CURRENCY_UNIT,
    video_id,
    purchased_from_id: purchaser_id,
    created_by_id: USER_ID,
    created_by_name: user_name,
    video_cost: amount,
    video_title,
    video_description,
    video_url,
    gst_fee: gstFee,
    processing_fee: processingFee,
    payment_type: "wanted_videos_subscription_purchase",
    product_name: "Month Membership Wanted",
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/create_order`, {
      method: "POST",
      body: JSON.stringify(body),
      headers,
    })
      .then(async (res) => await res.json())
      .then((response) => {
        if (response.status_code === 200) {
          const { razorpay_order_id, amount, _id } = response.result[0];
          console.log(razorpay_order_id);

          options.order_id = razorpay_order_id;
          options.amount = amount;
          etubes_order_id = _id; // order id maintained by ZynoFlix
          const rzp1 = (window as any).Razorpay(options);
          rzp1.open();
        }
      })
      .catch((error) => console.log("--- Error while creating order: ", error));
  } catch (error) {
    console.log("--- Error while creating order: ", error);
  }
};

export const openPayModalForVideoUpload = async (
  amt: string,
  buyerData: {
    _id: string;
    email: string;
    first_name: string;
    last_name: string;
    user_name: string;
    contact_number: string;
  },
  videoData: {
    video_id: string;
    video_title: string;
    video_description: string;
    video_url: string;
    cost?: string;
    downloads: number;
    purchaser_id?: string;
  },
  navigate: Function,
  callback: Function,
  setRequestStatus?: Function
) => {
  const amount = +amt * 100; //Razorpay consider the amount in paise
  const { user_name, first_name, last_name, _id, email, contact_number } =
    buyerData;
  const {
    video_id,
    cost,
    purchaser_id,
    video_description,
    video_title,
    video_url,
    downloads,
  } = videoData;
  const name = `${first_name} ${last_name}`;
  const TOKEN = fetchToken(TOKEN_NAME);
  const USER_ID = fetchUserId();
  const headers = {
    Authorization: TOKEN,
    "Content-type": "application/json",
  };

  let etubes_order_id = "";

  const options = {
    key: RAZORPAY_KEY,
    amount,
    currency: "INR",
    name: "Zynoflix",
    description: "Test Transaction",
    image: "https://example.com/your_logo",
    order_id: "order_NHH33xiBPLxIDl",
    handler: function (response: {
      razorpay_signature: string;
      razorpay_order_id: string;
      razorpay_payment_id: string;
    }) {
      console.log("rzp response", response);
    },
    prefill: {
      name,
      email,
      contact: contact_number,
    },
    notes: {
      address: "Hello World",
    },
    theme: {
      color: "#528ff0",
    },
  };
  const body = {
    name,
    phone_no: contact_number,
    email,
    amount: amount,
    currency: CURRENCY_UNIT,
    video_id: video_id,
    created_by_id: USER_ID,
    created_by_name: user_name,
    video_cost: cost,
  };

  try {
    // setRequestStatus({
    //   buttonLoading: true,
    //   success: false,
    //   loading: false,
    //   error: false,
    // });
    fetch(`${ETUBES_URL_PREFIX}/create_order`, {
      method: "POST",
      body: JSON.stringify(body),
      headers,
    })
      .then((res) => res.json())
      .then((response) => {
        // setRequestStatus({
        //   buttonLoading: false,
        //   success: true,
        //   loading: false,
        //   error: false,
        // });

        if (response.status_code === 200) {
          const { razorpay_order_id, amount, _id } = response.result[0];
          options.order_id = razorpay_order_id;
          options.amount = amount;
          etubes_order_id = _id; // order id maintained by ZynoFlix
          let rzp: any = new (window as any).Razorpay(options);
          rzp.open();
        }
      })
      .catch((error) => console.log("--- Error while creating order: ", error));
  } catch (error) {
    // setRequestStatus({
    //   buttonLoading: false,
    //   success: false,
    //   loading: false,
    //   error: true,
    // });

    console.log("--- Error while creating order: ", error);
  }
};
export const openPayModalFormembership = async (
  amt: string,
  buyerData: {
    _id: string;
    email: string;
    first_name: string;
    last_name: string;
    user_name: string;
    contact_number: string;
  },
  videoData: {
    video_id: string;
    video_title: string;
    video_description: string;
    video_url: string;
    cost?: string;
    downloads: number;
    purchaser_id?: string;
  },
  navigate: Function,
  callback: Function,
  setRequestStatus?: Function
) => {
  const amount = +amt * 100; //Razorpay consider the amount in paise
  const { user_name, first_name, last_name, _id, email, contact_number } =
    buyerData;
  const {
    video_id,
    cost,
    purchaser_id,
    video_description,
    video_title,
    video_url,
    downloads,
  } = videoData;
  const name = `${user_name}`;
  const TOKEN = fetchToken(TOKEN_NAME);
  const USER_ID = fetchUserId();
  const headers = {
    Authorization: STATIC_TOKEN,
    "Content-type": "application/json",
  };

  let etubes_order_id = "";

  const options = {
    redirect: true,
    key: RAZORPAY_KEY,
    amount,
    currency: "INR",
    name: "Zynoflix ",
    description: "Test Transaction",
    image: "https://example.com/your_logo",
    order_id: "order_NHH33xiBPLxIDl",
    handler: function (response: {
      razorpay_signature: string;
      razorpay_order_id: string;
      razorpay_payment_id: string;
    }) {
      console.log("rzp response", response);
      const { razorpay_order_id, razorpay_payment_id, razorpay_signature } =
        response;
      const body = {
        amount_paid: amount,
        status: "paid",
        razorpay_order_id,
        razorpay_payment_id,
        razorpay_signature,
        _id: etubes_order_id,
      };

      const options = {
        method: "PUT",
        headers,
        body: JSON.stringify(body),
      };
      callback(etubes_order_id);
      fetch(`${ETUBES_URL_PREFIX}/update_order`, options)
        .then(async (response) => await response.json())
        .then((res) => {
          console.log("Success payment", res);
          callback(etubes_order_id);
        })
        .catch((error: unknown) => console.log(error));
    },
    prefill: {
      name,
      email,
      contact: contact_number,
    },
    notes: {
      address: "Hello World",
    },
    theme: {
      color: "#528ff0",
    },
  };
  const body = {
    name,
    phone_no: contact_number,
    email,
    amount: amount,
    currency: CURRENCY_UNIT,
    video_id: video_id,
    created_by_id: purchaser_id,
    created_by_name: user_name,
    video_cost: cost,

    product_name: "Month Membership Wanted",

    payment_type: "wanted_videos_subscription_purchase",
  };

  try {
    // setRequestStatus({
    //   buttonLoading: true,
    //   success: false,
    //   loading: false,
    //   error: false,
    // });
    fetch(`${ETUBES_URL_PREFIX}/create_order`, {
      method: "POST",
      body: JSON.stringify(body),
      headers,
    })
      .then((res) => res.json())
      .then((response) => {
        // setRequestStatus({
        //   buttonLoading: false,
        //   success: true,
        //   loading: false,
        //   error: false,
        // });
        if (response.status_code === 200) {
          const { razorpay_order_id, amount, _id } = response.result[0];
          options.order_id = razorpay_order_id;
          options.amount = amount;
          etubes_order_id = _id; // order id maintained by ZynoFlix
          let rzp: any = new (window as any).Razorpay(options);
          rzp.on("payment.captured", () => {
            toast.success("Order Placed Successfully");
          });
          rzp.open();
        }
      })
      .catch((error) => console.log("--- Error while creating order: ", error));
  } catch (error) {
    // setRequestStatus({
    //   buttonLoading: false,
    //   success: false,
    //   loading: false,
    //   error: true,
    // });

    console.log("--- Error while creating order: ", error);
  }
};

interface tProfile {
  _id: string;
  first_name: string;
  last_name: string;
  user_name: string;
  email: string;
  contact_number: string;
}

export const priceCheckForUploadingVideo = (props: { formData: FormData }) => {
  const { formData } = props;
  const URL = `${ETUBES_URL_PREFIX}/check_video_pricing_and_trail_free/`;
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    headers: {
      Authorization: TOKEN,
    },
    method: "POST",
    body: formData,
  };
  try {
    const response = fetch(URL, options)
      .then((response) => response.json())
      .then((res) => {
        console.log(res, "--------------------RES");

        if (res.status === "success") {
          // if (res.result[0]?.amount === 'free') {
          //   res.result[0].amount = '0';
          // }
          return res.result[0];
        }
        return { code: "", amount: "0" };
      });
    return response;
  } catch (error) {
    console.log("Upload failed!", error);
  }
};

// export const uploadVideo = (props: {
//   formData: FormData
//   _id: string
//   profile: tProfile
//   navigate: Function
//   toast?: Function
//   setUploadedVideoId?: Function
// }) => {
//   const { formData, profile, toast, setUploadedVideoId, navigate, _id } = props
//   const URL = `${ETUBES_URL_PREFIX}/videos`
//   const TOKEN = fetchToken(TOKEN_NAME)
//   const options = {
//     headers: {
//       Authorization: TOKEN,
//     },
//     method: "POST",
//     body: formData,
//   }
//   try {
//     fetch(URL, options)
//       .then((response) => response.json())
//       .then((res) => {
//         if (res.status_code === 200) {
//           toast && toast("Upload successful!")
//           const uploadedVideoId = res.result[0]._id
//           const created_by_id = res.result[0].created_by_id
//           if (setUploadedVideoId) {
//             setUploadedVideoId(uploadedVideoId)
//             updateOrder({ videoId: uploadedVideoId, orderId: _id })
//           }
//         } else {
//           throw new Error("Error while uploading video")
//         }
//       })
//   } catch (error) {
//     toast && toast("Soemthing went wrong, please try again!")
//     console.log("Upload failed!", error)
//   }
// }

export const uploadVideo = (props: any) => {
  const {
    formData,
    profile,
    toast,
    setUploadedVideoId,
    navigate,
    _id,
    setProgress,
    setVideoUploadSuccess,
    success,
  } = props;
  const URL = `${ETUBES_URL_PREFIX}/videos`;

  const TOKEN = fetchToken(TOKEN_NAME);

  const config = {
    onUploadProgress: (progressEvent: any) => {
      const percentage = (progressEvent.loaded / progressEvent.total) * 100;
      console.log(`Upload Progress: ${Math.round(percentage)}%`);
      setProgress(percentage);
    },
    headers: {
      Authorization: TOKEN,
    },
  };

  axios
    .post(URL, formData, config)
    .then((response) => {
      const res = response.data;
      toast && toast("Upload successful!");
      setTimeout(() => {
        {
          success && (window.location.href = "/profile?id=My Videos");
        }
      }, 2000);
      if (res.status_code === 200) {
        console.log(res.status_code === 200, "gokula");

        setVideoUploadSuccess(res);

        const uploadedVideoId = res.result[0]._id;
        const created_by_id = res.result[0].created_by_id;
        if (setUploadedVideoId) {
          setUploadedVideoId(uploadedVideoId);
          updateOrder({ videoId: uploadedVideoId, orderId: _id });
        }

        localStorage.removeItem("img");
        localStorage.removeItem("formData");
        setTimeout(() => {
          localStorage.removeItem("payment_id");
        }, 4000);
      } else {
        throw new Error("Error while uploading video");
      }
    })
    .catch((error) => {
      // toast && toast("Something went wrong, please try again!");
      console.error("Upload failed!", error);
    });
};

// export const uploadVideo = (props: any) => {
//   const { formData, profile, toast, setUploadedVideoId, navigate, _id } = props
//   const URL = `${ETUBES_URL_PREFIX}/videos`
//   const TOKEN = fetchToken(TOKEN_NAME)
//   const options = {
//     headers: {
//       Authorization: TOKEN,
//     },
//     method: "POST",
//     body: formData,
//   }

//   try {
//     const xhr = new XMLHttpRequest()

//     xhr.upload.addEventListener("progress", (event) => {
//       if (event.lengthComputable) {
//         const percentage = (event.loaded / event.total) * 100
//         console.log(`Upload Progress: ${Math.round(percentage)}%`)
//       }
//     })

//     xhr.open("POST", URL, true)

//     xhr.onreadystatechange = () => {
//       if (xhr.readyState === 4) {
//         if (xhr.status === 200) {
//           const res = JSON.parse(xhr.responseText)
//           if (res.status_code === 200) {
//             toast && toast("Upload successful!")
//             const uploadedVideoId = res.result[0]._id
//             const created_by_id = res.result[0].created_by_id
//             if (setUploadedVideoId) {
//               setUploadedVideoId(uploadedVideoId)
//               updateOrder({ videoId: uploadedVideoId, orderId: _id })
//             }
//           } else {
//             throw new Error("Error while uploading video")
//           }
//         } else {
//           toast && toast("Something went wrong, please try again!")
//           console.log("Upload failed!")
//         }
//       }
//     }

//     xhr.send(formData)
//   } catch (error) {
//     toast && toast("Something went wrong, please try again!")
//     console.log("Upload failed!", error)
//   }
// }

// export const uploadVideo = (props: {
//   formData: FormData
//   _id: string
//   profile: tProfile
//   navigate: Function
//   toast?: Function
//   setUploadedVideoId?: Function
//   onProgress?: Function
// }) => {
//   const {
//     formData,
//     profile,
//     toast,
//     setUploadedVideoId,
//     navigate,
//     _id,
//     onProgress,
//   } = props
//   const URL = `${ETUBES_URL_PREFIX}/videos`
//   const TOKEN = fetchToken(TOKEN_NAME)

//   const options = {
//     headers: {
//       Authorization: TOKEN,
//     },
//     method: "POST",
//     body: formData,
//   }

//   try {
//     const xhr = new XMLHttpRequest()

//     // Add an event listener to track upload progress
//     xhr.upload.addEventListener("progress", (e) => {
//       if (e.lengthComputable) {
//         const percentComplete = (e.loaded / e.total) * 100
//         if (onProgress) {
//           onProgress(percentComplete)
//         }
//       }
//     })

//     xhr.open("POST", URL, true)

//     xhr.onload = function () {
//       if (xhr.status === 200) {
//         const res = JSON.parse(xhr.responseText)
//         if (res.status_code === 200) {
//           toast && toast("Upload successful!")
//           const uploadedVideoId = res.result[0]._id
//           const created_by_id = res.result[0].created_by_id
//           if (setUploadedVideoId) {
//             setUploadedVideoId(uploadedVideoId)
//             updateOrder({ videoId: uploadedVideoId, orderId: _id })
//           }
//         } else {
//           throw new Error("Error while uploading video")
//         }
//       } else {
//         toast && toast("Something went wrong, please try again!")
//         console.log("Upload failed!")
//       }
//     }

//     xhr.send(formData)
//   } catch (error) {
//     toast && toast("Something went wrong, please try again!")
//     console.log("Upload failed!", error)
//   }
// }

export const uploadAdVideo = (props: {
  formData: FormData;
  _id: string;
  setRequestStatus: Function;
  reset: Function;
  profile: tProfile;
  toast?: Function;
  setUploadedVideoId?: Function;
  navigate?: Function;
}) => {
  const {
    setRequestStatus,
    formData,
    profile,
    toast,
    setUploadedVideoId,
    navigate,
    _id,
    reset,
  } = props;
  const URL = `${ETUBES_URL_PREFIX}/ad_form_videos`;
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    headers: {
      Authorization: TOKEN,
    },
    method: "POST",
    body: formData,
  };

  setRequestStatus({
    buttonLoading: true,
    success: false,
    loading: false,
    error: false,
  });
  try {
    fetch(URL, options)
      .then((response) => response.json())
      .then((res) => {
        props.setRequestStatus({
          buttonLoading: false,
          success: true,
          loading: false,
          error: false,
        });
        if (res.status_code === 200) {
          toast && toast("Upload successful!");
          reset();
        } else {
          throw new Error("Error while uploading video");
        }
      });
  } catch (error) {
    props.setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: false,
      error: true,
    });
    toast && toast("Soemthing went wrong, please try again!");
    console.log("Upload failed!", error);
  }
};

export const getOrder = async (props: {
  etubes_order_id: string;
  setRequestStatus: Function;
  setOrders: Function;
}) => {
  const { etubes_order_id, setRequestStatus, setOrders } = props;
  const URL = `${ETUBES_URL_PREFIX}/get_single_order/${etubes_order_id}`;
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    headers: {
      Authorization: TOKEN,
    },
    method: "GET",
  };
  setRequestStatus({
    buttonLoading: false,
    success: false,
    loading: true,
    error: false,
  });
  try {
    const response = await fetch(URL, options)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status_code === 200) {
          setOrders(responseJson.result);
        }
      });
    props.setRequestStatus({
      buttonLoading: false,
      success: true,
      loading: false,
      error: false,
    });
    return response;
  } catch (error) {
    props.setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: false,
      error: true,
    });
    console.log("Upload failed!", error);
  }
};

export const getUserWhislist = (props: {
  cookies: CookiesType;
  ID: string;
  setWishlistVideos: Function;
}) => {
  const COOKIE_KEY = Object.keys(props.cookies)[0];
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: TOKEN,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/my_wish_list/${USER_ID_NAME}`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          props.setWishlistVideos(data.result);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};
export const getUserTopVideoUploaded = (props: {
  setTopUser: Function;
  static: boolean;
}) => {
  const TOKEN = props.static ? STATIC_TOKEN : fetchToken(TOKEN_NAME);
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: TOKEN,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX_ADMIN}/top_ten_video_uploaded_users`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          props.setTopUser(data.result);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};
export const fetchAdvertisement_banner = (props: {
  setAdv: Function;
  static: boolean;
}) => {
  const TOKEN = props.static ? STATIC_TOKEN : fetchToken(TOKEN_NAME);
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: TOKEN,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/advertisement_banner`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          props.setAdv(data.result);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};
export const fetchWantedVideo = (props: { setWantedVideo: Function }) => {
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: TOKEN,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/wanted_videos`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          props.setWantedVideo(data.result);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

export const removeUserWhislist = (props: {
  cookies: CookiesType;
  ID: string;
  setWishlistVideos: Function;
}) => {
  const COOKIE_KEY = Object.keys(props.cookies)[0];
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: TOKEN,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/my_wish_list/`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          console.log(data.message);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

export const incrementVideoCount = (props: string) => {
  try {
    const TOKEN = fetchToken(TOKEN_NAME);
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "PUT",
    };
    fetch(`${ETUBES_URL_PREFIX}/views/${props}`, options)
      .then((res) => res.json())
      .then((response) => {
        if (response.status_code === 200) {
          console.log("--- Successfully updated the views: ");
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log("--- Error while updating video data:", error);
  }
};

export const updateOrder = (props: { videoId: string; orderId: string }) => {
  const { videoId, orderId } = props;
  try {
    const TOKEN = fetchToken(TOKEN_NAME);
    const headers = {
      Authorization: TOKEN,
      "Content-type": "application/json",
    };
    const body = {
      _id: orderId,
      video_id: videoId,
    };

    const options = {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    };

    fetch(`${ETUBES_URL_PREFIX}/update_order`, options)
      .then((response) => response.json())
      .then((res) => {
        console.log("--- Order updated succesfully", res);
      })
      .catch((error: unknown) => console.log(error));
  } catch (error) {
    console.log("--- Error while updating order:", error);
  }
};

export const getCategoryVideos = (props: {
  category: string;
  setRequestStatus: Function;
  setAction: Function;
  setLoading?: Function;
}) => {
  const { category, setRequestStatus, setLoading, setAction } = props;
  try {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: true,
      error: false,
    });
    setLoading && setLoading(true);
    const options = {
      headers: {
        Authorization: STATIC_TOKEN,
        "Content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        category: [category],
      }),
    };
    fetch(`${ETUBES_URL_PREFIX}/filter`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({
          buttonLoading: false,
          success: true,
          loading: false,
          error: false,
        });
        setLoading && setLoading(false);

        if (response.status_code === 200) {
          setAction(response.result);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    setLoading && setLoading(false);
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: false,
      error: true,
    });
    console.log("--- Error while fetching category video data:", error);
  }
};

export const searchGlobalVideos = (props: {
  searchString: string;
  setRequestStatus: Function;
  setAction: Function;
}) => {
  const { searchString, setRequestStatus, setAction } = props;
  try {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: true,
      error: false,
    });
    const options = {
      headers: {
        Authorization: STATIC_TOKEN,
        "Content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        search_data: searchString,
      }),
    };
    fetch(`${ETUBES_URL_PREFIX}/search_videos`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({
          buttonLoading: false,
          success: true,
          loading: false,
          error: false,
        });

        if (response.status_code === 200) {
          setAction(response.result);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: false,
      error: true,
    });
    console.log("--- Error while fetching category video data:", error);
  }
};

export const fetchAllAdVideos = (props: { setAction: Function }) => {
  const { setAction } = props;
  try {
    const options = {
      headers: {
        Authorization: STATIC_TOKEN,
      },
      method: "GET",
    };
    fetch(`${ETUBES_URL_PREFIX}/ad_videos`, options)
      .then((res) => res.json())
      .then((response) => {
        if (response.status_code === 200) {
          setAction(response.result);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log("--- Error while fetching ad video data:", error);
  }
};

export const uploadProfilePicture = (props: {
  formData: FormData;
  setAction: Function;
  setRequestStatus: Function;
  utilFunction: Function;
}) => {
  const { setRequestStatus, formData, setAction, utilFunction } = props;
  const URL = `${ETUBES_URL_PREFIX}/avatar_image`;
  try {
    setRequestStatus({
      buttonLoading: true,
      success: false,
      loading: false,
      error: false,
    });
    const options = {
      headers: {
        Authorization: fetchToken(TOKEN_NAME),
      },
      method: "POST",
      body: formData,
    };
    fetch(URL, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({
          buttonLoading: false,
          success: true,
          loading: false,
          error: false,
        });
        console.log("uploadProfilepicture", response);
        if (response.status_code === 200) {
          // setAction(response.result[0]['avatar_url']);
          toast("Profile picture uploaded successfully!");
          utilFunction();
        }
      })
      .catch((err) => {
        console.log("--- error while uploading profile picture", err);
        setRequestStatus({
          buttonLoading: false,
          success: false,
          loading: false,
          error: true,
        });
        toast(
          "Something went wrong uploading profile picture (SE), please try again!"
        );
      });
  } catch (error) {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: false,
      error: true,
    });
    console.log("--- Error while fetching ad video data:", error);
    toast("Something went wrong uploading profile picture, please try again!");
  }
};

export const updateSubscribedList = async (
  authorId: string,
  isSubscribing: boolean,
  setAction: Function,
  setRequestStatus: Function
) => {
  const SUB_URL = `${ETUBES_URL_PREFIX}/subscribe`;
  const UNSUB_URL = `${ETUBES_URL_PREFIX}/unsubscribe`;
  const URL = isSubscribing ? SUB_URL : UNSUB_URL;
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      subscriber_id: authorId,
    }),
  };

  try {
    setRequestStatus({
      loading: false,
      success: false,
      error: false,
      buttonLoading: true,
    });
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      setAction(isSubscribing);
      toast("Successfully updated!");
    } else {
      toast("Something went wrong!");
    }
    setRequestStatus({
      loading: false,
      success: true,
      error: false,
      buttonLoading: false,
    });
  } catch (error) {
    setRequestStatus({
      loading: false,
      success: false,
      error: true,
      buttonLoading: false,
    });
    console.log("Error while subscribing to the author", error);
  }
};

export const isOwnVideo = (props: {
  video_id?: string;
  uploadedVideoList: tVideoState[];
}): boolean => {
  const { video_id, uploadedVideoList } = props;

  if (!video_id) return false;
  return !(uploadedVideoList.find((video) => video._id === video_id) == null);
};

export const reportVideoForm = async (
  created_by_id?: string,
  created_by_name?: string,
  reason?: string,
  video_id?: string
) => {
  const URL = `${ETUBES_URL_PREFIX}/video_reports`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      created_by_id: created_by_id,
      created_by_name: created_by_name,
      reason: reason,
      video_id: video_id, // Assuming 'authorId' is the video ID in the original code
    }),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      toast("Successfully updated!");
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};

// export const fetchAllWantedVideo = (props: { setSetWantedVideo: Function }) => {
//   const { setSetWantedVideo } = props
//   try {
//     const options = {
//       headers: {
//         Authorization: STATIC_TOKEN,
//       },
//       method: "GET",
//     }
//     fetch(
//       `${ETUBES_URL_PREFIX}/wanted_videos/65145c3f3ecae79abdbeacea`,
//       options
//     )
//       .then((res) => res.json())
//       .then((response) => {
//         if (response.status_code === 200) {
//           setSetWantedVideo(response.result)
//         }
//       })
//       .catch((err) => console.log(err))
//   } catch (error) {
//     console.log("--- Error while fetching ad video data:", error)
//   }
// }

export const fetchAllWantedVideo = (props: {
  setSetWantedVideo: Function;
  static: boolean;
}) => {
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: props.static ? STATIC_TOKEN : TOKEN,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/wanted_videos`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          props.setSetWantedVideo(data.result);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};
export const SearchWantedVideo = async (search_data?: string) => {
  const URL = `${ETUBES_URL_PREFIX}/search_wanted_videos`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      search_data: search_data,
    }),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      toast("Successfully updated!");
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};
export const filterWantedVideo = async (props: any) => {
  const { category, country, language, setSearchValue } = props;
  const URL = `${ETUBES_URL_PREFIX}/filter_wanted_videos`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      category: category,
      country: country,
      language: language,
    }),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      setSearchValue(response.result[0]);
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};

export const MyAppliedWandedVideo = async (
  user_id: string,
  setMyAppliedWantedVideo: Function
) => {
  const URL = `${ETUBES_URL_PREFIX}/my_applied_wanted_videos`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      user_id: user_id,
    }),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      setMyAppliedWantedVideo(response.result);
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};

export const MyPostedWandedVideo = async (props: any) => {
  const { user_id, setMyPostedWantedVideo } = props;
  const URL = `${ETUBES_URL_PREFIX}/my_post_wanted_videos`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      user_id: user_id,
    }),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      setMyPostedWantedVideo(response.result);
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};

export const uploadWantedVideoForm = async (props: any) => {
  const { formDataObject, setUploadSuccess } = props;
  const URL = `${ETUBES_URL_PREFIX}/wanted_videos`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const requestData = {
    title: formDataObject.title,
    description: formDataObject.description,
    category: formDataObject.category,
    country: formDataObject.country,
    state: formDataObject.state,
    sub_title: formDataObject.sub_title,
    contact_number: formDataObject.contact_number,
    email: formDataObject.email,
    price: formDataObject.price,
    language: formDataObject.language,
    created_by_id: formDataObject.created_by_id,
    created_by_name: formDataObject.created_by_name,
    poster_image: formDataObject.poster_image,
  };

  const options = {
    headers: {
      Authorization: TOKEN,
    },
    method: "POST",
    body: formDataObject,
  };

  try {
    const response = await fetch(URL, options);
    const res = await response.json();

    if (res.status_code === 200) {
      toast && toast("Upload successful!");
      setUploadSuccess(res.status_code === 200);
    } else {
      throw new Error("Error while uploading video");
    }
  } catch (error) {
    toast && toast("Something went wrong, please try again!");
    console.log("Upload failed!", error);
  }
};

// export const uploadWantedVideoForm = async (props: any) => {
//   const {
//     title,
//     description,
//     category,
//     state,
//     country,
//     sub_title,
//     contact_number,
//     email,
//     price,
//     language,
//     created_by_id,
//     created_by_name,
//   } = props
//   const URL = `${ETUBES_URL_PREFIX}/wanted_videos`
//   const TOKEN = fetchToken(TOKEN_NAME)

//   const requestData = {
//     title: title,
//     description: description,
//     category: category,
//     country: country,
//     state: state,
//     sub_title: sub_title,
//     contact_number: contact_number,
//     email: email,
//     price: price,
//     language: language,
//     created_by_id: created_by_id,
//     created_by_name: created_by_name,
//   }

//   const options = {
//     headers: {
//       Authorization: TOKEN,
//       "Content-Type": "application/json",
//     },
//     method: "POST",
//     body: JSON.stringify(requestData),
//   }

//   try {
//     const response = await fetch(URL, options)
//     const res = await response.json()

//     if (res.status_code === 200) {
//       toast && toast("Upload successful!")
//     } else {
//       throw new Error("Error while uploading video")
//     }
//   } catch (error) {
//     toast && toast("Something went wrong, please try again!")
//     console.log("Upload failed!", error)
//   }
// }

export const fetchAllTrendingTrailer = (props: {
  setTrendingTrailer: Function;
  static: boolean;
}) => {
  const TOKEN = props.static ? STATIC_TOKEN : fetchToken(TOKEN_NAME);
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: TOKEN,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/trending_trailer`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          props.setTrendingTrailer(data.result);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

export const uploadApplyForm = async (props: any) => {
  const {
    name,
    description,
    email,
    phone_no,
    created_by_id,
    created_by_name,
    form_user_id,
  } = props;
  const URL = `${ETUBES_URL_PREFIX}/apply_constact_form`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const requestData = {
    name: name,
    email: email,
    phone_no: phone_no,
    description: description,
    created_by_id: created_by_id,
    created_by_name: created_by_name,
    form_user_id: form_user_id,
  };

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(requestData),
  };

  try {
    const response = await fetch(URL, options);
    const res = await response.json();

    if (res.status_code === 200) {
      toast && toast("Upload successful!");
    } else {
      throw new Error("Error while uploading video");
    }
  } catch (error) {
    toast && toast("Something went wrong, please try again!");
    console.log("Upload failed!", error);
  }
};
export const updateSearchHistory = (props: {
  setRequestStatus: Function;
  searchString: string;
  userName: string;
  userId: string;
}) => {
  const { setRequestStatus, searchString, userName, userId } = props;
  try {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: true,
      error: false,
    });
    const TOKEN = fetchToken(TOKEN_NAME);
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        search_content: searchString,
        created_by_name: userName,
        created_by_id: userId,
      }),
    };
    fetch(`${ETUBES_URL_PREFIX}/search_history_video`, options)
      .then(async (res) => await res.json())
      .then((response) => {
        setRequestStatus({
          buttonLoading: false,
          success: true,
          loading: false,
          error: false,
        });

        if (response.status_code === 200) {
          console.log("Search history updated!");
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: false,
      error: true,
    });
    console.log("--- Error while updateing search history data:", error);
  }
};

// export const fetchGetSingleUser = async (props: {
//   setSingleUser: Function;
//   static?: true;
//   user_Id: string;
// }) => {
//   const URL = `${ETUBES_URL_PREFIX}/get_alluser/${props.user_Id}`;
//   const TOKEN = fetchToken(TOKEN_NAME);

//   const options = {
//     headers: {
//       Authorization: TOKEN,
//       "Content-type": "application/json",
//     },
//     method: "GET",
//   };
//   try {
//     const response = await fetch(URL, options).then((response) =>
//       response.json()
//     );
//     if (response.status_code === 200) {
//       props.setSingleUser(response.result[0]);
//     } else {
//       console.log(
//         "-- Error while fetching playlist banner images --",
//         response.status_code,
//         " ",
//         response.message
//       );
//     }
//   } catch (error) {
//     console.log("Error while fetching playlist banner images >>", error);
//   }
// };

export const fetchGetSingleUser = async (props: {
  setSingleUser: Function;
  static?: true;
  user_Id: string;
}) => {
  const URL = `${ETUBES_URL_PREFIX}/view_profile/${props.user_Id}`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options: any = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: {
      _id: props.user_Id,
    },
  };
  try {
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200) {
      props.setSingleUser(response.result[0]);
    } else {
      console.log(
        "-- Error while fetching playlist banner images --",
        response.status_code,
        " ",
        response.message
      );
    }
  } catch (error) {
    console.log("Error while fetching playlist banner images >>", error);
  }
};

export const fetchLiveStreamingEvent = async (props: {
  setLiveStreamEvent: Function;
  static?: true;
}) => {
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/live_streaming_event`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };
  try {
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200) {
      props.setLiveStreamEvent(response.result);
    } else {
      console.log(
        "-- Error while fetching playlist banner images --",
        response.status_code,
        " ",
        response.message
      );
    }
  } catch (error) {
    console.log("Error while fetching playlist banner images >>", error);
  }
};

export const fetchMyPurchaseList = async (props: {
  setMyPurchareList: Function;
  static?: true;
  userId?: string;
}) => {
  const URL = `${ETUBES_URL_PREFIX}/my_purchase_list/${props.userId}`;
  const TOKEN = props?.static ? STATIC_TOKEN : fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };
  try {
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200) {
      props.setMyPurchareList(response.result);
    } else {
      console.log(
        "-- Error while fetching playlist banner images --",
        response.status_code,
        " ",
        response.message
      );
    }
  } catch (error) {
    console.log("Error while fetching playlist banner images >>", error);
  }
};

export const UploadTargeting = (props: {
  setRequestStatus: Function;
  name: string;
  age: string;
  userId: string;
  gender: string;
  location: string;
  interests: string;
  setTargeting_option: Function;
}) => {
  const { setRequestStatus, name, interests, location, age, userId, gender } =
    props;
  try {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: true,
      error: false,
    });
    const TOKEN = fetchToken(TOKEN_NAME);
    const options = {
      headers: {
        // Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        name: name,
        demographics: {
          age: age,
          gender: gender,
          location: location,
          interests: interests,
        },
      }),
    };
    fetch(`${ETUBES_URL_PREFIX1}/targeting/create`, options)
      .then(async (res) => await res.json())
      .then((response) => {
        setRequestStatus({
          buttonLoading: false,
          success: true,
          loading: false,
          error: false,
        });

        if (response.status_code === 200) {
          console.log("Search history updated!");
          props.setTargeting_option(response.data.insertedId);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: false,
      error: true,
    });
    console.log("--- Error while updateing search history data:", error);
  }
};
export const UploadBoostPost = (props: {
  setRequestStatus: Function;
  is_active: string;
  video_id: string;
  userId: string;
  budget: number;
  reaches: number;
  duration: string;
  websitelink?: string | undefined;
  targeting_option: string;
  selected_goal: any;
  thumbnail?: string;
}) => {
  const {
    setRequestStatus,
    duration,
    is_active,
    video_id,
    userId,
    budget,
    reaches,
    websitelink,
    targeting_option,
    // selected_goal,
    thumbnail,
  } = props;
  try {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: true,
      error: false,
    });
    const TOKEN = fetchToken(TOKEN_NAME);
    const options = {
      headers: {
        // Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        video_id: video_id,
        is_active: is_active,
        duration: duration,
        budget: budget,
        insights: {
          reaches: reaches,
        },
        websitelink: websitelink,
        targeting_option: targeting_option,
        // selected_goal: selected_goal,
        thumbnail: thumbnail,
      }),
    };
    fetch(`${ETUBES_URL_PREFIX1}/boostpost`, options)
      .then(async (res) => await res.json())
      .then((response) => {
        setRequestStatus({
          buttonLoading: false,
          success: true,
          loading: false,
          error: false,
        });

        if (response.status_code === 200) {
          console.log("Search history updated!");
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: false,
      error: true,
    });
    console.log("--- Error while updateing search history data:", error);
  }
};
export const fetchSingleLiveStreamingEvent = async (props: {
  setLiveStreamEvent: Function;
  static?: true;
  eventId?: string | null;
}) => {
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/live_streaming_event/${props.eventId}`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };
  try {
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200) {
      props.setLiveStreamEvent(response.result[0]);
    } else {
      console.log(
        "-- Error while fetching playlist banner images --",
        response.status_code,
        " ",
        response.message
      );
    }
  } catch (error) {
    console.log("Error while fetching playlist banner images >>", error);
  }
};

export const uploadLiveStreamEventPost = (props: {
  setRequestStatus: Function;
  formData: any;
  setUploadSuccess: Function;
}) => {
  const { setRequestStatus, formData, setUploadSuccess } = props;
  try {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: true,
      error: false,
    });
    const TOKEN = fetchToken(TOKEN_NAME);
    console.log(TOKEN);

    const options = {
      headers: {
        Authorization: TOKEN,
      },
      method: "POST",
      body: formData,
    };
    fetch(`${ETUBES_URL_PREFIX_ADMIN}/live_streaming_event`, options)
      .then(async (res) => await res.json())
      .then((response) => {
        setRequestStatus({
          buttonLoading: false,
          success: true,
          loading: false,
          error: false,
        });

        if (response.status_code === 200) {
          setUploadSuccess(response.status_code === 200);
          console.log("Search history updated!");
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    setRequestStatus({
      buttonLoading: false,
      success: false,
      loading: false,
      error: true,
    });
    console.log("--- Error while updateing search history data:", error);
  }
};

// export const uploadLiveStreamEventPost = (props: {
//   setRequestStatus: Function
//   formData: any
//   setUploadSuccess: Function
// }) => {
//   const { setRequestStatus, formData, setUploadSuccess } = props

//   try {
//     setRequestStatus({
//       buttonLoading: false,
//       success: false,
//       loading: true,
//       error: false,
//     })
//     const TOKEN = fetchToken(TOKEN_NAME)
//     console.log(TOKEN)

//     const options = {
//       headers: {
//         Authorization: TOKEN,
//       },
//       method: "POST",
//       body: formData,
//     }

//     // Create a new XMLHttpRequest to track upload progress.
//     const xhr = new XMLHttpRequest()

//     xhr.upload.addEventListener("progress", (event) => {
//       if (event.lengthComputable) {
//         const percentage = (event.loaded / event.total) * 100
//         console.log(`Upload Progress: ${percentage}%`)
//       }
//     })

//     xhr.open("POST", `${ETUBES_URL_PREFIX_ADMIN}/live_streaming_event`, true)
//     xhr.send(formData)

//     xhr.onload = () => {
//       if (xhr.status === 200) {
//         setRequestStatus({
//           buttonLoading: false,
//           success: true,
//           loading: false,
//           error: false,
//         })
//         const response = JSON.parse(xhr.responseText)
//         if (response.status_code === 200) {
//           setUploadSuccess(response.status_code === 200)
//           console.log("Search history updated!")
//         }
//       }
//     }

//     xhr.onerror = (err) => {
//       setRequestStatus({
//         buttonLoading: false,
//         success: false,
//         loading: false,
//         error: true,
//       })
//       console.log("--- Error while updating search history data:", err)
//     }
//   } catch (error) {
//     setRequestStatus({
//       buttonLoading: false,
//       success: false,
//       loading: false,
//       error: true,
//     })
//     console.log("--- Error while updating search history data:", error)
//   }
// }

export const recommendedVideos = async (props: any) => {
  const { user_id, setRecommededVideo } = props;
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/recommended_video`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: STATIC_TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      setRecommededVideo(response.result);
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};

export const filterLiveStreamingEvent = async (props: any) => {
  const { category, country, language, setLiveStreamEvent } = props;
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/filter_live_streaming_event`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      category: category,
      country: country,
      language: language,
    }),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      setLiveStreamEvent(response.result[0]);
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};

export const searchLiveStreamingEvent = async (props: any) => {
  const { search_data, setLiveStreamEvent } = props;
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/search_live_streaming_event`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      search_data: search_data,
    }),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      setLiveStreamEvent(response.result);
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};

export const myPostLiveStreamingEvent = async (props: any) => {
  const { user_id, setMyPostedEvent } = props;
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/my_post_live_streaming_event`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      user_id: user_id,
    }),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      setMyPostedEvent(response.result);
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};

export const fetchWantedVideoCategoryCount = async (props: {
  setWantedVideoCout: Function;
  static?: true;
}) => {
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/wanted_videos_category_with_count`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: props.static ? STATIC_TOKEN : TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };
  try {
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200) {
      props.setWantedVideoCout(response.result);
    } else {
      console.log(
        "-- Error while fetching playlist banner images --",
        response.status_code,
        " ",
        response.message
      );
    }
  } catch (error) {
    console.log("Error while fetching playlist banner images >>", error);
  }
};

export const CreatePayment = async (props: any) => {
  const {
    setPaymentSuccess,
    name,
    phone_no,
    email,
    amount,
    currency,
    video_cost,
    product_name,
    video_title,
    video_id,
    video_description,
    video_url,
    purchased_from_id,
    gst_fee,
    created_by_id,
    created_by_name,
    processing_fee,
    VideoBas64,
    payment_type,
  } = props;
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/stripe_create_order`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      name: name,
      phone_no: phone_no,
      email: email,
      amount: amount,
      currency: currency,
      product_name: product_name,
      video_cost: video_cost,
      video_title: video_title,
      video_id: video_id,
      video_description: video_description,
      video_url: video_url,
      purchased_from_id: purchased_from_id,
      created_by_id: created_by_id,
      gst_fee: gst_fee,
      processing_fee: processing_fee,
      VideoBas64: VideoBas64,
      created_by_name: created_by_name,
      payment_type: payment_type,
    }),
  };

  try {
    console.log(options, "options");

    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    console.log(response, "response22");
    if (response.status_code === 200 && response.status === "success") {
      setPaymentSuccess(response);
      localStorage.setItem("payment_id", response.result[0]._id);
      localStorage.setItem("Success", response?.result[0]?.product_name);
      const paymentLink = response?.payment_link;
      if (paymentLink) {
        // const width = 640 // Set the width of the video window
        // const height = 690 // Set the height of the video window
        // const windowFeatures = `width=${width},height=${height},menubar=no,toolbar=no,location=no,scrollbars=no,resizable=no`
        // window.open(paymentLink, windowFeatures, "Payment")
        window.location.href = paymentLink;
      }
      toast("Payment successfull");
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};

export const fetchSinglepayment = async (props: {
  setWantedVideoCout: Function;
  static?: true;
}) => {
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/stripe_single_order/65392ae5538c654dec4daca8`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };
  try {
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200) {
      props.setWantedVideoCout(response.result);
    } else {
      console.log(
        "-- Error while fetching playlist banner images --",
        response.status_code,
        " ",
        response.message
      );
    }
  } catch (error) {
    console.log("Error while fetching playlist banner images >>", error);
  }
};

export const UploadApplyForm = async (props: any) => {
  const {
    name,
    email,
    phone_no,
    description,
    created_by_id,
    created_by_name,
    form_user_id,
  } = props;
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/apply_constact_form`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      name: name,
      email: email,
      phone_no: phone_no,
      description: description,
      created_by_id: created_by_id,
      created_by_name: created_by_name,
      form_user_id: form_user_id,
    }),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      toast("success");
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while uploading apply form", error);
  }
};

export const NewUserRegister = async (props: any) => {
  const {
    first_name,
    last_name,
    email,
    password,
    user_name,
    confirmPassword,
    date_of_birth,
    is_active,
    address,
    contact_number,
    age,
    gst_no,
    region,
    gender,
    taxId,
    swiftCode,
    accountNumber,
    accountName,
    contactNumber,
    navigation,
  } = props;

  const URL = `${ETUBES_URL_PREFIX}/register`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
      user_name: user_name,
      confirm_password: confirmPassword,
      date_of_birth: date_of_birth,
      is_active: is_active,
      address: address,
      contact_number: contact_number,
      age: age,
      gst_no: gst_no,
      region: region,
      gender: gender,
      taxId: taxId,
      swiftCode: swiftCode,
      accountNumber: accountNumber,
      accountName: accountName,
      contactNumber: contactNumber,
    }),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    console.log(response, "response");

    if (response.status_code === 200 && response.status === "success") {
      localStorage.setItem("etubes_token", response?.result?.[0]?.token);
      localStorage.setItem("etubes_id", response?.result?.[0]?._id);
      navigation(-1);
      toast.success("success fully register");
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while registering new user", error);
  }
};

export const CreateBulkPayment = async (props: any) => {
  const { setPaymentSuccess, ...restprops } = props;
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/stripe_bulk_order`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(props),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      setPaymentSuccess(response);
      localStorage.setItem("payment_id", response.result[0]._id);
      localStorage.setItem("Success", response?.result[0]?.product_name);
      const paymentLink = response?.payment_link;
      if (paymentLink) {
        // const width = 640 // Set the width of the video window
        // const height = 690 // Set the height of the video window
        // const windowFeatures = `width=${width},height=${height},menubar=no,toolbar=no,location=no,scrollbars=no,resizable=no`
        // window.open(paymentLink, windowFeatures, "Payment")
        window.location.href = paymentLink;
      }
      toast("Payment successfull");
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};

export const FetchPaymentStatus = async (props: {
  setWantedVideoCout: Function;
  static?: true;
  strp_payment_id: any;
}) => {
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/get_order_details/${props.strp_payment_id}`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };
  try {
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200) {
      props.setWantedVideoCout(response.result);
    } else {
      console.log(
        "-- Error while fetching playlist banner images --",
        response.status_code,
        " ",
        response.message
      );
    }
  } catch (error) {
    console.log("Error while fetching playlist banner images >>", error);
  }
};

export const removeVideo = (props: { videoId: string }) => {
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: TOKEN,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/videos/${props.videoId}`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          console.log(data.message);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

export const FetchComments = async (props: {
  setComment: Function;
  eventId: any;
}) => {
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/get_event_comments/${props.eventId}`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };
  try {
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200) {
      props.setComment(response?.result);
    } else {
      console.log(
        "-- Error while fetching playlist banner images --",
        response.status_code,
        " ",
        response.message
      );
    }
  } catch (error) {
    console.log("Error while fetching playlist banner images >>", error);
  }
};

export const FetchRating = async (props: {
  setComment: Function;
  eventId: any;
}) => {
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/get_event_ratings/${props.eventId}`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "GET",
  };
  try {
    const response = await fetch(URL, options).then((response) =>
      response.json()
    );
    if (response.status_code === 200) {
      props.setComment(response?.result[0]);
    } else {
      console.log(
        "-- Error while fetching playlist banner images --",
        response.status_code,
        " ",
        response.message
      );
    }
  } catch (error) {
    console.log("Error while fetching playlist banner images >>", error);
  }
};

export const PostStarRating = async (props: any) => {
  const { setStarSuccess, ...restprops } = props;
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/ratings`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(props),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      setStarSuccess(response.result);
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};
export const PostComments = async (props: any) => {
  const { setStarSuccess, ...restprops } = props;
  const URL = `${ETUBES_URL_PREFIX_ADMIN}/comments`;
  const TOKEN = fetchToken(TOKEN_NAME);

  const options = {
    headers: {
      Authorization: TOKEN,
      "Content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(props),
  };

  try {
    const response = await fetch(URL, options).then(
      async (response) => await response.json()
    );
    if (response.status_code === 200 && response.status === "success") {
      setStarSuccess(response.result);
    } else {
      toast("Something went wrong!");
    }
  } catch (error) {
    console.log("Error while subscribing to the author", error);
  }
};

export const uploadbackgroundImage = (props: FormData) => {
  const formData = props;
  const URL = `${ETUBES_URL_PREFIX}/background_image`;
  try {
    const options = {
      headers: {
        Authorization: fetchToken(TOKEN_NAME),
      },
      method: "POST",
      body: formData,
    };
    fetch(URL, options)
      .then((res) => res.json())
      .then((response) => {
        console.log("uploadBackgroundImage", response);
        if (response.status_code === 200) {
          // setAction(response.result[0]['avatar_url']);
          toast("background image uploaded successfully!");
        }
      })
      .catch((err) => {
        console.log("--- error while uploading profile picture", err);
        toast(
          "Something went wrong uploading background image (SE), please try again!"
        );
      });
  } catch (error) {
    console.log("--- Error while fetching ad video data:", error);
    toast(
      "Something went wrong uploading background image , please try again!"
    );
  }
};

export const RemoveEventPost = (props: { videoId: string }) => {
  const TOKEN = fetchToken(TOKEN_NAME);
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: TOKEN,
    },
  };
  try {
    fetch(`${ETUBES_URL_PREFIX}/live_streaming_event/${props.videoId}`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          console.log(data.message);
        } else {
          console.log(data);
        }
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};
