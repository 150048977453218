// import { tDropdown } from '../../utils/genericTypes';

import { tDropdown } from "../../../utils/genericTypes";

export const UPLOAD_TYPE_LIST = [
  { title: "Banner Image", href: "banner_image" },
  { title: "Bottom Banner Image", href: "bottom_banner_image" },
  { title: "Advertisement Video", href: "ad_videos" },
];

export const uploadFormSchema: tFormSchema[] = [
  {
    tag: "title",
    des: "The Power of Video Titles in Increasing Sales and Engaging Your Audience",
    label: "Video title",
    placeholder: "Enter video title",
    inputMode: "text",
  },
  {
    tag: "category",
    des: "Researching Popular Videos in Your Niche and Identifying Successful Categories",
    label: "Video category",
    placeholder: "Select video categories",
    type: "select",
    inputMode: "text",
    data: [
      { title: "Fun", href: "fun" },
      { title: "History", href: "history" },
      { title: "Cars", href: "cars" },
    ],
  },
  {
    tag: "cost",
    label: "Video cost",
    placeholder: "Enter video cost",
    pattern: /^\d+$/, ///^[0-9]*$/,
    inputMode: "numeric",
    type: "cost",
    des: " Determining the Perceived Value of Your Video Content Brief: :- ( How much cost of your content video ) fix the value :- Min 0 Rs - 50cr",
  },
  {
    tag: "thumbnailFile",
    label: "Upload thumbnail",
    placeholder: "",
    type: "file",
    inputMode: "text",
    accept: "image/*",
    des: "Transform Your Video Sales with Engaging Thumbnail Content Photos",
    fileLimit: 3,
    fileerror:
      "Video file size exceeds the 3 MB limit. Please choose a smaller video. - 3MB",
  },
  {
    tag: "description",
    label: "Video description",
    placeholder: "Enter description",
    type: "area",
    inputMode: "text",
    des: "Keep your video content short and sweet. It helps people understand the video story and increase your revenue",
  },
  {
    tag: "preview_video",
    label: "Upload preview video",
    placeholder: "",
    type: "file",
    inputMode: "text",
    accept: "video/*",
    des: "Upload Your TRAILER Video Here, This trailer video only we will show to users. If users likes your trailer video they can purchase. | Trailer vIdeo Duration 45Sec maximum | Trailer Video Size maximum 100mb",
    fileLimit: 100,
    fileerror:
      "Video file size exceeds the 100 MB limit. Please choose a smaller video.",
  },
  {
    tag: "videoFile",
    label: "Upload video",
    placeholder: "",
    type: "file",
    inputMode: "text",
    accept: "video/*",
    des: "Upload Your Orginal video here, Peoples watch your trailer video if they paid money. This orginal video will be download automatically | Video size maximum 2GB",
    fileLimit: 2048,
    fileerror:
      "Video file size exceeds the 2 GB limit. Please choose a smaller video.",
  },
];

export const uploadAdFormSchema: tAdFormSchema[] = [
  {
    tag: "title",
    label: "Upload title",
    placeholder: "Enter the title",
    inputMode: "text",
  },
  {
    tag: "type",
    label: "Upload type",
    placeholder: "Enter video categories",
    type: "select",
    inputMode: "text",
    data: UPLOAD_TYPE_LIST,
  },

  {
    tag: "phone_no",
    label: "Phone Number",
    placeholder: "Enter your phone number",
    inputMode: "numeric",
    type: "numeric",
  },
  {
    tag: "email",
    label: "E-mail Address",
    placeholder: "Enter your e-mail address",
    inputMode: "text",
  },
  {
    tag: "thumbnailFile",
    label: "Upload thumbnail",
    placeholder: "",
    type: "file",
    inputMode: "text",
    accept: "image/*",
    showIfType: "banner || bottom_banner",
  },
  {
    tag: "souce_url",
    label: "Source URL",
    placeholder: "Enter the source URL",
    inputMode: "text",
    showIfType: "ad_video",
  },
  {
    tag: "description",
    label: "Upload description",
    placeholder: "Enter description",
    type: "area",
    inputMode: "text",
  },
  {
    tag: "videoFile",
    label: "Upload video",
    placeholder: "",
    type: "file",
    inputMode: "text",
    accept: "video/*",
    showIfType: "ad_video",
  },
];

export interface tFormData {
  videoFile: File;
  title: string;
  rating: string;
  category: string;
  cost: string;
  description: string;
  downloads: string;
  language: string;
  status: string;
  thumbnailFile: File;
}

export interface tAdFormData {
  videoFile: File;
  title: string;
  type: string;
  description: string;
  status: string;
  email: string;
  phone_number: string;
  bannerImage: File;
}

export interface tFormSchema {
  tag: string;
  label: string;
  des: string;
  placeholder: string;
  fileLimit?: number;
  fileerror?: string;
  type?: string;
  data?: tDropdown[];
  pattern?: RegExp;
  accept?: string;
  inputMode: "text" | "numeric";
}

export interface tAdFormSchema {
  tag: string;
  label: string;
  placeholder: string;
  type?: string;
  data?: tDropdown[];
  pattern?: RegExp;
  accept?: string;
  inputMode: "text" | "numeric";
  showIfType?: string;
}

export interface bFromSchema {
  interests: string;
  location: string;
  gender: string;
  age: string;
  name: string;
}
export interface JobFormSchema {
  categories: string;
  title: string;
  sub_title: string;
  email: string;
  contact_number: string;
  description: string;
  price: string;
  poster_image: any;
}

export const uploadWantedVideo: any = [
  {
    tag: "title",
    label: "title",
    placeholder: "Enter video title",
    inputMode: "text",
    des: " The text requires the user to provide information about the video requirement. ",
  },
  {
    tag: "sub_title",
    label: "sub_title",
    placeholder: "Enter video Sub_title",
    inputMode: "text",
    des: "The text requires the input of information about the video sub title requirement. ",
  },
  {
    tag: "category",
    label: " category",
    placeholder: "Enter video categories",
    type: "select",
    inputMode: "text",
    data: [
      { title: "Fun", href: "fun" },
      { title: "History", href: "history" },
      { title: "Cars", href: "cars" },
    ],
    des: "Choose your blockbuster category",
  },
  {
    tag: "language",
    label: " Language",
    placeholder: "Enter  Language",
    type: "select",
    inputMode: "text",
    data: [
      { title: "Fun", href: "fun" },
      { title: "History", href: "history" },
      { title: "Cars", href: "cars" },
    ],
    des: "choose your language perfectly",
  },

  {
    tag: "price",
    label: " Price",
    placeholder: "Enter video Price",
    pattern: /^\d+$/, ///^[0-9]*$/,
    inputMode: "numeric",
    type: "numeric",
    des: " How much invest for your script? mention here ",
  },
  {
    tag: "description",
    label: " description",
    placeholder: "Enter description",
    type: "area",
    inputMode: "text",
    des: " simply explain your wanted video script maximum 500 letters",
  },
  {
    tag: "email",
    label: "Email address",
    placeholder: "Enter Email Address",
    type: "area",
    inputMode: "text",
    des: "please enter your mail id, we will send you the notification",
  },
  {
    tag: "country",
    label: " Country",
    placeholder: "Enter  Country",
    type: "select",
    inputMode: "text",
    data: [
      { title: "Fun", href: "fun" },
      { title: "History", href: "history" },
      { title: "Cars", href: "cars" },
    ],
    des: "choose your country thast helps to peoples find you",
  },
  // {
  //   tag: "state",
  //   label: " State",
  //   placeholder: "Enter  State",
  //   type: "select",
  //   inputMode: "text",
  //   data: [
  //     { title: "Fun", href: "fun" },
  //     { title: "History", href: "history" },
  //     { title: "Cars", href: "cars" },
  //   ],
  //   des: "choose your state thats helps to peoples find you",
  // },
  {
    tag: "contact_number",
    label: "Contact Number",
    placeholder: "Enter Contact Number",
    type: "area",
    inputMode: "text",
    des: "please enter your Contact Number",
  },
  {
    tag: "poster_image",
    label: "Upload poster Image",
    placeholder: "",
    type: "file",
    inputMode: "text",
    accept: "image/*",
    des: "upload poster image with content, peoples can understand about your Poster",
  },
];

export const uploadLiveStreamEvent: any = [
  {
    tag: "heading",
    label: "Streaming Heading",
    placeholder: "Enter heading",
    inputMode: "text",
    des: "Enter Catchy Title About Your Live Streaming ",
  },
  {
    tag: "description",
    label: "Streaming Discription",
    placeholder: "Enter description",
    type: "area",
    inputMode: "text",
    des: " Discribe About Your Streaming , Peoples Can Understand Easily",
  },
  {
    tag: "category",
    label: "Streaming Category",
    placeholder: "Enter video categories",
    type: "select",
    inputMode: "text",
    data: [
      { title: "Fun", href: "fun" },
      { title: "History", href: "history" },
      { title: "Cars", href: "cars" },
    ],
    des: "The Streaming Category is a specific category of streaming services.",
  },
  {
    tag: "language",
    label: "Streaming language",
    placeholder: "Enter video language",
    type: "select",
    inputMode: "text",
    data: [
      { title: "Fun", href: "fun" },
      { title: "History", href: "history" },
      { title: "Cars", href: "cars" },
    ],
    des: "The streaming language is a method of communication that allows users to access and interact with content through a streaming platform.",
  },

  {
    tag: "duration_time",
    label: "Host Duration Time",
    placeholder: "Enter host duration Time",
    type: "text",
    des: "Please Enter the total duration time of your streaming.",
  },
  {
    tag: "host_name",
    label: "Host Name",
    placeholder: "Enter host name",
    type: "text",
    des: "Please Enter the host name ",
  },
  {
    tag: "ticket_cost",
    label: "Ticket Cost For Streaming",
    placeholder: "Enter host Ticket Cost",
    type: "numeric",
    des: " Please enter the ticket cost for you streaming.",
  },
  {
    tag: "host_age",
    label: "Host AGE",
    placeholder: "Enter host age",
    inputMode: "numeric",
    type: "numeric",
    des: "host mention your age honestly",
  },
  {
    tag: "host_address",
    label: "Host Confirm Address",
    placeholder: "Enter host Address",
    inputMode: "numeric",
    type: "numeric",
    des: " please confirm the address for our confirmation",
  },
  {
    tag: "country",
    label: "Host Country",
    placeholder: "Pick your country",
    type: "select",
    des: " choose your country",
  },
  {
    tag: "streaming_date_time",
    label: "Streaming Date and Time",
    placeholder: "Pick your streaming Date and Time",
    type: "time",
    des: "mention streaming date and time ",
  },
  {
    tag: "is_18",
    label: "Video summary",
    placeholder: "Enter Video Summary",
    type: "text",
    inputMode: "text",

    des: "please choose this, because kids also watching",
  },
  {
    tag: "banner_video",
    label: "Upload Banner video",
    placeholder: "",
    type: "file",
    inputMode: "text",
    accept: "video/*",
    des: "please upload banner video 30sec thats gives more impression from our users. ",
  },
  {
    tag: "intro_video",
    label: "Intro video",
    placeholder: "",
    type: "file",
    inputMode: "text",
    accept: "video/*",
    des: "upload intro video maximum 30sec.",
  },
  {
    tag: "poster_image",
    label: "Upload poster Image",
    placeholder: "",
    type: "file",
    inputMode: "text",
    accept: "image/*",
    des: "upload poster image with content, peoples can understand about your streaming. ",
  },
];

export const uploadApplyForm: any = [
  {
    tag: "name",
    label: "name",
    placeholder: "Enter video name",
    inputMode: "text",
    des: " The text requires the user to provide information about the video requirement.",
  },
  {
    tag: "email",
    label: "email",
    placeholder: "Enter video email",
    inputMode: "email",
    des: "The text requires the input of information about the video sub title requirement. ",
  },
  {
    tag: "phone_no",
    label: " phone_no",
    placeholder: "Enter video phone Number",
    type: "text",
    inputMode: "text",
    data: [
      { title: "Fun", href: "fun" },
      { title: "History", href: "history" },
      { title: "Cars", href: "cars" },
    ],
    des: "Choose your blockbuster category",
  },
  {
    tag: "description",
    label: " description",
    placeholder: "Enter  description",
    type: "select",
    inputMode: "text",
    data: [
      { title: "Fun", href: "fun" },
      { title: "History", href: "history" },
      { title: "Cars", href: "cars" },
    ],
    des: "choose your description perfectly",
  },
];

export const uploadVideoSuccess: tFormSchema[] = [
  {
    tag: "preview_video",
    label: "Upload preview video",
    placeholder: "",
    type: "file",
    inputMode: "text",
    accept: "video/*",
    des: "Upload your trailer original HD video and if people like your trailer video easily they can buy your video and earn lots of money - 100MB",
    fileLimit: 100,
    fileerror:
      "Video file size exceeds the 100 MB limit. Please choose a smaller video.",
  },
  {
    tag: "videoFile",
    label: "Upload video",
    placeholder: "",
    type: "file",
    inputMode: "text",
    accept: "video/*",
    des: "Upload the original video file here and people will automatically download your trailer videos if people pay for them - 2GB",
    fileLimit: 2048,
    fileerror:
      "Video file size exceeds the 2 GB limit. Please choose a smaller video.",
  },
];
