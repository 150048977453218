import { useEffect, useRef, useState } from "react"
import "./Cursor.css"
import { motion } from "framer-motion"

interface CursorProps {}

const Cursor: React.FC<CursorProps> = () => {
  const [largecircle, setlargecircle] = useState({ x: 0, y: 0 })
  const [smallcircle, setsmallcircle] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const mousemove = (e: MouseEvent) => {
      setlargecircle({ x: e.clientX, y: e.clientY })
      setsmallcircle({ x: e.clientX, y: e.clientY })
    }
    window.addEventListener("mousemove", mousemove)

    return () => {
      window.removeEventListener("mousemove", mousemove)
    }
  }, [])

  return (
    <div>
      <motion.div
        animate={{
          x: largecircle.x - 32,
          y: largecircle.y - 32,
        }}
        className="large_circle"
      ></motion.div>
    </div>
  )
}

export default Cursor
// import React, { useState, useEffect, useRef } from "react"
// import { motion } from "framer-motion"

// const Cursor: React.FC = () => {
//   const [coords, setCoords] = useState({ x: 0, y: 0 })
//   const circlesRef = useRef<HTMLDivElement[] | any>([])
//   const colors = ["#ffb56b", "#fdaf69", "#f89d63", "#f59761", "#ef865e"]

//   useEffect(() => {
//     circlesRef.current = circlesRef.current.slice(0, colors.length)

//     circlesRef.current.forEach((circle: any, index: any) => {
//       circle.style.backgroundColor = colors[index % colors.length]
//     })
//   }, [])

//   const handleMouseMove = (e: React.MouseEvent) => {
//     setCoords({ x: e.clientX, y: e.clientY })
//   }

//   useEffect(() => {
//     const animateCircles = () => {
//       let x = coords.x
//       let y = coords.y

//       circlesRef.current.forEach((circle: any, index: any) => {
//         circle.style.left = x - 12 + "px"
//         circle.style.top = y - 12 + "px"

//         circle.style.transform = `scale(${
//           (circlesRef.current.length - index) / circlesRef.current.length
//         })`

//         const nextCircle: any =
//           circlesRef.current[index + 1] || circlesRef.current[0]
//         x += (nextCircle.x - x) * 0.3
//         y += (nextCircle.y - y) * 0.3
//       })

//       requestAnimationFrame(animateCircles)
//     }

//     animateCircles()
//   }, [coords])
//   const [largecircle, setlargecircle] = useState({ x: 0, y: 0 })
//   const [smallcircle, setsmallcircle] = useState({ x: 0, y: 0 })

//   useEffect(() => {
//     const mousemove = (e: MouseEvent) => {
//       setlargecircle({ x: e.clientX, y: e.clientY })
//       setsmallcircle({ x: e.clientX, y: e.clientY })
//     }
//     window.addEventListener("mousemove", mousemove)

//     return () => {
//       window.removeEventListener("mousemove", mousemove)
//     }
//   }, [])

//   return (
//     <div>
//       <>
//         {/* {colors.map((color, index) => (
//         <div
//         key={index}
//         ref={(el) => (circlesRef.current[index] = el)}
//         className="circle"
//         ></div>
//         )} */}
//         <motion.div
//           animate={{
//             x: largecircle.x - 32,
//             y: largecircle.y - 32,
//           }}
//           className="large_circle"
//         ></motion.div>
//         {/* {colors.map((color, index) => (
//           <div
//             key={index}
//             ref={(el) => (circlesRef.current[index] = el)}
//             className="circle"
//           ></div>
//         ))} */}
//       </>
//     </div>
//   )
// }
// export default Cursor
// // import React from "react"

// // const Cursor = () => {
// //   return <div></div>
// // }

// // export default Cursor
// // import React from "react"

// // const Cursor = () => {
// //   return <div>Cursor</div>
// // }

// // export default Cursor
