import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Button,
  Image,
  MultiSelect,
  Select,
  SimpleGrid,
  TextInput,
  Textarea,
} from "@mantine/core";
import * as Yup from "yup";
import { useMantineTheme } from "@mantine/core";

import { DateInput, TimeInput } from "@mantine/dates";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  JobFormSchema,
  uploadApplyForm,
  uploadWantedVideo,
} from "../SubmitVideo/components/uploadFormSchema";
import Language from "../../JsonData/languages.json";
import Country from "../../JsonData/Country.json";
import {
  CreatePayment,
  UploadApplyForm,
  fetchCategories,
  fetchToken,
  openPayModalForVideoUpload,
  openPayModalFormembership,
  viewProfile,
} from "../../utils/util";
import { MyContext } from "../../components/MyProvider/MyProvider";
import {
  ETUBES_URL_PREFIX,
  STATIC_TOKEN,
  TOKEN_NAME,
  USER_ID_NAME,
} from "../../utils/variables";
import { CategoryState } from "../../utils/genericTypes";
import { useNavigate } from "react-router-dom";
import { openPayModal } from "../../utils/openPayModal";
const ApplyForm = () => {
  return (
    <div className="bg-background_body text-white">
      <div className="lg:px-24 px-4 pt-24 lg:pt-44 text-white">
        <h1 className="text-start text-xl lg:text-3xl font-bold">
          Wanted Video Apply
        </h1>
        <TicketForm />
      </div>
    </div>
  );
};

export default ApplyForm;
const TOKEN = fetchToken(TOKEN_NAME);
export const handleFormSubmit = async (id: any) => {
  try {
    const body = {
      _id: id,
      is_wanted_video_subscription: false,
    };
    const headers = {
      Authorization: STATIC_TOKEN,
      "Content-type": "application/json",
    };
    const response = await fetch(`${ETUBES_URL_PREFIX}/edit_profile`, {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    });
    if (response.ok) {
      console.log("Profile updated successfully!");
      // Optionally, you can handle success by redirecting or showing a success message
    } else {
      console.error("Failed to update profile");
      // Handle error, show an error message, or redirect as needed
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
const TicketForm = () => {
  const generateYupSchema = (fields: any) => {
    const schemaObject: any = {};

    return Yup.object().shape(schemaObject);
  };
  const [profile, setProfile] = useState<any>();
  useEffect(() => {
    if (true) {
      const ID = localStorage.getItem(USER_ID_NAME);
      if (ID && ID !== "") {
        viewProfile(ID, setProfile);
      }
    }
  }, []);
  const formSchema = generateYupSchema(uploadWantedVideo);
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const [selectedFile, setSelectedFile] = useState<any>();
  const [customList, setCustomList] = useState<CategoryState[]>([]);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  React.useEffect(() => {
    fetchCategories({
      setCategories: setCustomList,
      static: true,
    });
  }, []);
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    UploadApplyForm({
      name: data.name,
      email: data.email,
      phone_no: data.phone_no,
      description: data.description,
      created_by_id: profile?._id,
      created_by_name: profile?.user_name,
      form_user_id: profile?._id,
    });
    window.location.href = "/wanted/my-applied";
  };
  const [requestStatus, setRequestStatus] = useState<any>();
  const [paymentSuccess, setPaymentSuccess] = useState();
  const ID = localStorage.getItem(USER_ID_NAME);
  handleFormSubmit(ID);
  const onClickHandler = () => {
    // Set loading to true before making the API request
    const PROFILE_DATA: any = {
      _id: profile?._id,
      first_name: profile?.first_name,
      last_name: profile?.last_name,
      user_name: profile?.user_name,
      email: profile?.email,
      contact_number: profile?.contact_number,
    };
    const ID = localStorage.getItem(USER_ID_NAME);
    handleFormSubmit(ID);
    openPayModalFormembership(
      "199",
      PROFILE_DATA,
      {
        video_id: "video_id",
        cost: "199",
        purchaser_id: profile?._id,
        video_description: "video_description",
        video_title: "video_title",
        video_url: "video_url",
        downloads: 0,
      },
      navigate,
      async (etubes_order_id: string) => {
        await handleFormSubmit(ID);
        reset();
      },
      setRequestStatus
    );
    // openPayModalFormembership(
    //   "199",
    //   PROFILE_DATA,
    //   {
    //     video_id: "video_id",
    //     cost: "199",
    //     purchaser_id: profile?._id,
    //     video_description: "video_description",
    //     video_title: "video_title",
    //     video_url: "video_url",
    //     downloads: 0,
    //   },
    //   navigate,
    //   async (etubes_order_id: string) => {
    //     await console.log(etubes_order_id);
    //     reset();
    //   },
    //   setRequestStatus
    // );
    // openPayModalForVideoUpload(
    //   "199",
    //   PROFILE_DATA,
    //   {
    // video_id: "video_id",
    // cost: "199",
    // purchaser_id: profile?._id,
    // video_description: "video_description",
    // video_title: "video_title",
    // video_url: "video_url",
    // downloads: 0,
    //   },
    //   navigate,
    //   async (etubes_order_id: string) => {
    //     console.log(etubes_order_id);

    //     reset();
    //   },
    //   setRequestStatus
    // );
    // CreatePayment({
    //   setPaymentSuccess: setPaymentSuccess,
    //   name: profile?.user_name,
    //   phone_no: profile?.phone_no,
    //   email: profile?.contact_number,
    //   amount: 19900,
    //   currency: "inr",
    //   product_name: "Month Membership Wanted",
    //   video_id: profile?._id,
    //   // video_description: "test description april 06",
    //   // video_url: "https://etubees.s3.amazonaws.com/videos/video_doctor.mp4",
    //   purchased_from_id: profile?._id,
    //   created_by_id: profile?._id,
    //   gst_fee: 0,
    //   processing_fee: 0,
    //   created_by_name: profile?.user_name,
    //   payment_type: "wanted_videos_subscription_purchase",
    // }).then(() => {
    //   console.log("Success");
    // });
  };
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://checkout.razorpay.com/v1/checkout.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, []);
  const isSub = profile?.is_wanted_video_subscription;

  return (
    <div className="">
      {!!isSub ? (
        <div className="">
          {" "}
          <section>
            <div className="text-white py-6 sm:py-6">
              <div className="mx-auto max-w-7xl px-0 lg:px-8">
                <div className="mx-auto max-w-2xl sm:text-center">
                  <h2 className="lg:text-3xl text-lg font-bold tracking-tight sm:text-4xl">
                    Simple no-tricks pricing
                  </h2>
                  <p className="mt-3 text-xs lg:text-lg leading-8">
                    Our user-friendly event creation tools allow you to set
                    event details, manage RSVPs, and customize event pages with
                    ease. In addition to creating beautiful event listings, you
                    can effortlessly set up live streams, ensuring a smooth
                    experience for both you and your attendees.
                  </p>
                </div>
                <div className="mx-auto mt-4 lg:mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                  <div className="p-8 sm:p-10 lg:flex-auto">
                    <h3 className="lg:text-2xl text-lg font-bold tracking-tight ">
                      month membership
                    </h3>
                    <p className="mt-6 text-xs lg:text-base leading-7 ">
                      Experience the power of seamless event creation and live
                      streaming with our Month Membership. This all-in-one
                      package empowers you to create, promote, and broadcast
                      your events effortlessly. Whether it's a conference, a
                      virtual seminar, or an online concert, you can now turn
                      your vision into reality and connect with a global
                      audience. <br /> <br />
                      Sign up for the Month Membership for Event Creation and
                      Live Streaming today and elevate your event planning
                      experience. Start hosting events that stand out and leave
                      a lasting impact with the integration of live streaming
                      technology.
                    </p>
                    <div className="mt-10 flex items-center gap-x-4">
                      <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
                        What’s included
                      </h4>
                      <div className="h-px flex-auto bg-gray-100"></div>
                    </div>
                    <ul
                      role="list"
                      className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6  sm:grid-cols-2 sm:gap-6"
                    >
                      <li className="flex gap-x-3">
                        <svg
                          className="h-6 w-5 flex-none text-indigo-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        1 month subscription fees
                      </li>
                      {/* <li className="flex gap-x-3">
                        <svg
                          className="h-6 w-5 flex-none text-indigo-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        1 month subscription fees
                      </li> */}
                      <li className="flex gap-x-3">
                        <svg
                          className="h-6 w-5 flex-none text-indigo-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Apply unlimited Jobs
                      </li>
                      <li className="flex gap-x-3">
                        <svg
                          className="h-6 w-5 flex-none text-indigo-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Earn unlimitedly
                      </li>
                    </ul>
                  </div>
                  <div className="-mt-2 !text-gray-600 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                    <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                      <div className="mx-auto max-w-xs px-8">
                        <p className="text-base font-semibold ">
                          Pay once, own it month
                        </p>
                        <p className="mt-6 flex items-baseline justify-center gap-x-2">
                          <span className="text-5xl font-bold tracking-tight text-gray-900">
                            ₹199
                          </span>
                          <span className="text-sm font-semibold leading-6 tracking-wide ">
                            INR
                          </span>
                        </p>
                        {/* <a
                        href="#"
                        class="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Get access
                      </a> */}
                        <button
                          onClick={onClickHandler}
                          className="px-6 text-white font-semibold rounded-xl py-3 my-4 default-button-color "
                        >
                          Pay Now
                        </button>
                        <p className="mt-6 text-xs leading-5 ">
                          Invoices and receipts available for easy company
                          reimbursement
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-background_item mt-8 py-6 px-8 rounded-2xl">
            {uploadApplyForm?.map((value: any) => {
              const errorMessage = errors?.[value.tag]?.message;
              return (
                <React.Fragment key={value.tag}>
                  <InputFieldWrapper title={value.label} des={value.des}>
                    <div className="">
                      <input
                        className={`${
                          errorMessage
                            ? "border border-red-500 placeholder:text-red-500"
                            : ""
                        } px-6 py-3 outline-none rounded-xl w-full bg-background_body`}
                        {...register(value.tag as keyof JobFormSchema, {
                          required: `Please enter the ${value.label}`,
                        })}
                        placeholder={`Enter your ${value.label}...`}
                      />
                    </div>
                    <div className="mt-2">
                      {errorMessage && typeof errorMessage === "string" ? (
                        <span className="text-red-500 ">{errorMessage}</span>
                      ) : null}
                    </div>
                  </InputFieldWrapper>
                </React.Fragment>
              );
            })}
          </div>
          <div className="ml-auto flex items-end justify-end">
            <button
              className="bg-btn_bgcolor3 px-6 py-3 rounded-xl my-8 "
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

function InputFieldWrapper({ children, title, des }: any) {
  return (
    <div className="flex pb-6 lg:pb-9 lg:flex-row flex-col gap-4 lg:gap-9 items-center">
      <div className="w-full lg:w-1/3">
        <h2 className="text-lg font-semibold capitalize">{title}</h2>
        <p className="text-sm font-normal text-gray-500">{des}</p>
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
}
