import React, { useState, useEffect } from "react"
// import searchHistory_icon from "./../../assets/searchModal_icons/searchHistory-icon.svg"
import suggestion_icon from "./../../assets/searchModal_icons/suggestion-icon.svg"
import { fetchSearchHistory } from "../../utils/userController"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useNavigate } from "react-router-dom"

interface SearchModalProps {
  divRef: React.Ref<HTMLDivElement>
}

const SearchModal: React.FC<SearchModalProps> = (props) => {
  const navigate = useNavigate()
  const [searchHistory, setSearchHistory] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [requestStatus, setRequestStatus] = useState()
  useEffect(() => {
    fetchSearchHistory({ setRequestStatus, setSearchHistory })
  }, [])

  return (
    <>
      <div
        className="search-modal rounded-2xl w-full px-8 py-8 shadow-2xl bg-background_item text-white top-9  absolute z-20"
        ref={props.divRef}
      >
        <ul className="search-list my-4 flex flex-col gap-4 border-bottom-grey pb-8">
          {searchHistory?.length > 0 ? (
            searchHistory?.map((item: any) => {
              return (
                <li
                  role="button"
                  onClick={() =>
                    navigate(
                      `/search-results?searchString=${item.search_content}`
                    )
                  }
                  className="flex items-center gap-4"
                  key={item._id}
                >
                  {/* <img alt="search icon" src={"searchHistory_icon"}></img> */}
                  <MagnifyingGlassIcon className="w-6 h-6" />
                  <span>{item.search_content}</span>
                </li>
              )
            })
          ) : (
            <h2 className="text-[14px] font-bold">No Search History!!!</h2>
          )}
        </ul>
        <ul className="suggestion-list my-4 flex flex-col gap-4 pb-4">
          {suggestions?.length > 0 ? (
            suggestions?.map((item: any) => {
              return (
                <li
                  onClick={() =>
                    navigate(
                      `/search-results?searchString=${item.search_content}`
                    )
                  }
                  className="flex items-center gap-4"
                  key={item._id}
                >
                  <img alt="search icon" src={suggestion_icon}></img>
                  <span>{item.search_content}</span>
                </li>
              )
            })
          ) : (
            <h2 className="text-[14px] font-bold">No Suggestions for now!!!</h2>
          )}
        </ul>
      </div>
    </>
  )
}

export default SearchModal
