import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stepper, Button, Group, Slider, Modal } from "@mantine/core";
import VideoField from "./components/VideoField";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UPLOAD_VIDEO_MAX_SIZE, USER_ID_NAME } from "../../utils/variables";
import { useForm } from "react-hook-form";
import {
  tFormData,
  uploadAdFormSchema,
  uploadFormSchema,
} from "./components/uploadFormSchema";
import {
  CreatePayment,
  FetchPaymentStatus,
  openPayModalForVideoUpload,
  priceCheckForUploadingVideo,
  uploadVideo,
  viewProfile,
} from "../../utils/util";
import useRazorpay, { RazorpayOptions } from "react-razorpay";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const SubmitVideo = () => {
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));
  const [selectedFile, setSelectedFile] = useState<Blob>();
  const [selectedPreviewFile, setSelectedPreviewFile] = useState<Blob>();
  const [selectedThumbnail, setSelectedThumbnail] = useState<Blob>();
  const [duration, setDuration] = useState<any>();
  const [uploadedVideoId, setUploadedVideoId] = useState<string>();
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>();

  const navigate = useNavigate();

  useEffect(() => {
    const ID = localStorage.getItem(USER_ID_NAME);
    if (ID && ID !== "") {
      viewProfile(ID, setProfile);
    }
  }, []);

  const formSchema = Yup.object().shape({});

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isPragressOpen, setIsPragressOpen] = useState(false);
  const [priceCheckData, setPriceCheckData] = useState<
    { code: string; amount: string } | undefined
  >();
  const [uploadFormData, setUploadFormData] = useState<FormData | undefined>();
  const closeModal = (props: { type?: string }) => {
    setModalIsOpen(false);
    if (props?.type === "continue") {
      triggerUpload({
        formData: uploadFormData,
        _priceCheckData: priceCheckData,
      });
      setIsPragressOpen(true);
    }
  };

  const FREE_TO_UPLOAD_CODE = "demo";
  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onabort = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const onSubmit = async (data: any) => {
    try {
      if (!!data?.videoFile[0]) {
        setUploadLoading(true);
        const formData = new FormData();

        formData.append("videos", data?.videoFile[0] as File);
        formData.set("preview_video", data?.preview_video?.[0] as File);
        formData.append("created_by_id", profile?._id);
        formData.append("created_by_name", profile?.user_name);
        const entries = data ? Object.keys(data) : [];
        const entriesLength = entries.length;
        if (entriesLength > 0 && data) {
          for (let index = 0; index < entriesLength; index++) {
            if (entries[index] === "category") {
              const element = data[entries[index] as keyof tFormData];
              formData.append(entries[index], element.toString().toLowerCase());
            } else if (entries[index] !== "videoFile") {
              const element = data[entries[index] as keyof tFormData];
              formData.append(entries[index], element);
            }
            if (entries[index] === "thumbnailFile") {
              formData.append("thumbnail", data.thumbnailFile[0] as File);
              getBase64(data.thumbnailFile[0]).then((base64) => {
                localStorage["img"] = base64;
              });
            }
          }
        }

        // Extract minutes and seconds
        const minutes = Math.floor(duration / 60);
        const seconds = Math.round(duration % 60);

        // Format the result as "00.00"
        const formattedDuration = `${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

        formData.append("duration", formattedDuration);

        localStorage.setItem(
          "formData",
          JSON.stringify({
            duration: formattedDuration,
            created_by_id: profile?._id,
            created_by_name: profile?.user_name,
            category: data.category,
            cost: data.cost,
            title: data.title,
            description: data.description,
          })
        );
        const _priceCheckData = await priceCheckForUploadingVideo({
          formData,
        });

        setModalIsOpen(true);
        setUploadLoading(false);
        setPriceCheckData(_priceCheckData);
        setUploadFormData(formData);
        const formDataObject: any = {};
        formData.forEach((value, key) => {
          formDataObject[key] = value;
        });

        localStorage.setItem("formData", JSON.parse(formDataObject));
      }
    } catch (error) {
      console.log(" --- Error while price checking: ", error);
    }
  };
  const [paymentSuccess, setPaymentSuccess] = useState<any>();
  console.log(paymentSuccess, "paymentSuccess");
  localStorage.setItem("paymentSuccess", JSON.stringify(paymentSuccess));
  const [progress, setProgress] = useState(0);
  const [Razorpay] = useRazorpay();

  // const handlePayment = useCallback(async () => {
  //   const order = await CreatePayment({
  //     setPaymentSuccess: setPaymentSuccess,
  //     name: profile?.first_name,
  //     phone_no: profile?.contact_number,
  //     email: profile?.email,
  //     amount: "2500",
  //     currency: "inr",
  //     product_name: "title",
  //     video_cost: "2434",
  //     video_title: "title",
  //     video_id: "6549aa2a69a0948362a1539e",
  //     video_description: "testing",
  //     video_url: "test",
  //     purchased_from_id: "test",
  //     created_by_id: "test",
  //     gst_fee: 18,
  //     processing_fee: 322,
  //     created_by_name: "cartItem[0]?.created_by_name",
  //     payment_type: "App User",
  //   });

  //   const options: RazorpayOptions = {
  //     key: "rzp_live_kPIgDdgd9AgWWI",
  //     amount: "400",
  //     currency: "INR",
  //     name: "Acme Corp",
  //     description: "Test Transaction",
  //     image: "https://example.com/your_logo",
  //     order_id: "order_NHGSGZ5LjBndcm",
  //     handler: (res) => {
  //       console.log(res);
  //     },
  //     prefill: {
  //       name: "Piyush Garg",
  //       email: "youremail@example.com",
  //       contact: "9999999999",
  //     },
  //     notes: {
  //       address: "Razorpay Corporate Office",
  //     },
  //     theme: {
  //       color: "#3399cc",
  //     },
  //   };

  //   const rzpay = new Razorpay(options);
  //   rzpay.open();
  // }, [Razorpay]);

  const [paymentStatus, setPaymentStatus] = useState<any>();
  // console.log(priceCheckData?.amount === "free", "VideoBas64");
  // const triggerUpload = async (props: {
  //   formData: FormData | undefined;
  //   _priceCheckData: { code: string; amount: string } | undefined;
  // }) => {
  //   const { formData, _priceCheckData } = props;
  //   function loadScript(src: any) {
  //     return new Promise((resolve) => {
  //       const script = document.createElement("script");
  //       script.src = src;
  //       script.onload = () => {
  //         resolve(true);
  //       };
  //       script.onerror = () => {
  //         resolve(false);
  //       };
  //       document.body.appendChild(script);
  //     });
  //   }
  //   try {
  //     const PROFILE_DATA = {
  //       _id: profile?._id,
  //       first_name: profile?.first_name,
  //       last_name: profile?.last_name,
  //       user_name: profile?.user_name,
  //       email: profile?.email,
  //       contact_number: profile?.contact_number,
  //     };
  //     //PAYTOUPLOAD CODE
  //     if (_priceCheckData && formData) {
  //       // if (_priceCheckData?.code === FREE_TO_UPLOAD_CODE && formData) {
  //       const video_id =
  //           (formData.get("video_id" as keyof tFormData) as string) || "",
  //         cost = (formData.get("cost") as string) || "",
  //         purchaser_id = (formData.get("purchaser_id") as string) || "",
  //         video_description =
  //           (formData.get("video_description") as string) || "",
  //         video_title = (formData.get("video_title") as string) || "",
  //         video_url = (formData.get("video_url") as string) || "";

  //       {
  //         _priceCheckData.amount === "free"
  //           ? uploadVideo({
  //               formData,
  //               _id: profile?._id,
  //               profile: PROFILE_DATA,
  //               navigate,
  //               toast,
  //               setProgress: setProgress,
  //               success: _priceCheckData.amount === "free",
  //             })
  //           : handlePayment();
  //       }
  //       // if (paymentSuccess?.payment_link) {
  //       //   window.open(paymentSuccess.payment_link, "_self")
  //       // }

  //       // uploadVideo({
  //       //   formData,
  //       //   _id: profile?._id,
  //       //   profile: PROFILE_DATA,
  //       //   navigate,
  //       //   toast,
  //       //   setProgress: setProgress,
  //       // })
  //     }
  //     // } else if (
  //     //   _priceCheckData &&
  //     //   _priceCheckData.code === FREE_TO_UPLOAD_CODE &&
  //     //   formData
  //     // ) {
  //     //   // await uploadVideo({
  //     //   //   formData,
  //     //   //   _id: profile?._id,
  //     //   //   profile: PROFILE_DATA,
  //     //   //   navigate,
  //     //   //   toast,
  //     //   // })
  //     //   reset()
  //     // }
  //   } catch (error) {
  //     console.log("--- Error while uploading video", error);
  //   }
  // };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  const [requestStatus, setRequestStatus] = useState<any>();
  const triggerUpload = async (props: {
    formData: FormData | undefined;
    _priceCheckData: { code: string; amount: string } | undefined;
  }) => {
    const { formData, _priceCheckData } = props;
    try {
      const PROFILE_DATA = {
        _id: profile?._id,
        first_name: profile?.first_name,
        last_name: profile?.last_name,
        user_name: profile?.user_name,
        email: profile?.email,
        contact_number: profile?.contact_number,
      };
      if (_priceCheckData && formData) {
        const video_id =
            (formData.get("video_id" as keyof tFormData) as string) || "",
          cost = (formData.get("cost") as string) || "",
          purchaser_id = (formData.get("purchaser_id") as string) || "",
          video_description =
            (formData.get("video_description") as string) || "",
          video_title = (formData.get("video_title") as string) || "",
          video_url = (formData.get("video_url") as string) || "";
        if (_priceCheckData.amount === "free") {
          uploadVideo({
            formData,
            _id: profile?._id,
            profile: PROFILE_DATA,
            navigate,
            toast,
            setProgress: setProgress,
            success: _priceCheckData.amount === "free",
          });
        } else {
          openPayModalForVideoUpload(
            _priceCheckData.amount,
            PROFILE_DATA,
            {
              video_id,
              cost,
              purchaser_id,
              video_description,
              video_title,
              video_url,
              downloads: 0,
            },
            navigate,
            async (etubes_order_id: string) => {
              await uploadVideo({
                formData,
                _id: profile?._id,
                profile: PROFILE_DATA,
                navigate,
                toast,
                setProgress: setProgress,
                success: true,
              });

              reset();
            },
            setRequestStatus
          );
        }
      } else if (_priceCheckData && formData) {
        await uploadVideo({
          formData,
          _id: profile?._id,
          profile: PROFILE_DATA,
          navigate,
          toast,
        });
        reset();
      }
    } catch (error) {
      console.log("--- Error while uploading video", error);
    }
  };
  const onClickValidationHandler = () => {
    const errorValues = Object.values(errors);
    errorValues.map((error: any) => toast(error.message));
  };
  const PAY_TO_UPLOAD_CODE = "demo";

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {uploadLoading ? (
        <div className="flex inset-0 items-center bg-black justify-center flex-col w-full h-screen">
          <img className="" src="/Images/upload.gif" alt="" />
          <h1 className="text-2xl  font-semibold z-50 relative text-white">
            Please wait it will take time{" "}
          </h1>
        </div>
      ) : (
        <div className="bg-background_body pt-44">
          <div className="px-2 lg:px-24 text-white">
            <h3 className="text-3xl font-bold">Upload Your Video</h3>
            <h1 className="text-lg font-semibold">
              {" "}
              FIRST VIDEO UPLOAD FREE, UPLOAD 20 VIDEOS UNLOCK MONETIZATION.
            </h1>
            <Modal
              closeOnClickOutside={false}
              size={"lg"}
              centered
              opened={progress === 100 ? false : isPragressOpen}
              onClose={() => setIsPragressOpen(false)}
              title="Authentication"
            >
              <div className="py-6">
                <h3>Upload percentage</h3>
                <Slider
                  value={progress}
                  color="blue"
                  marks={[
                    { value: 0, label: "0%" },
                    { value: 50, label: "50%" },
                    { value: 100, label: "100%" },
                  ]}
                />
              </div>
            </Modal>

            <Modal
              centered
              onClose={() => setModalIsOpen(false)}
              opened={modalIsOpen}
              title="Do you want to proceed to upload?"
            >
              <div className="w-full h-full m-0 flex gap-4 flex-col">
                <h2 className="bg-primary-background font-bold text-xl text-active-border">
                  Uploading Video
                </h2>
                {priceCheckData && priceCheckData.amount === "0" ? (
                  <p>Something went wrong, please try again!</p>
                ) : (
                  <p className="text-gray-300">
                    Would you like to proceed with the provided information ?
                  </p>
                )}

                {priceCheckData &&
                priceCheckData.code === PAY_TO_UPLOAD_CODE ? (
                  <div>
                    <div>
                      You'll be redirected to pay{" "}
                      <span className="font-bold">
                        &#8377; {priceCheckData.amount}
                      </span>
                      &nbsp; to continue with the upload.
                    </div>
                  </div>
                ) : null}
                <div className="flex gap-4 justify-between pt-4">
                  <button
                    className="px-6 py-3 rounded-xl bg-gray-400  items-center"
                    onClick={() => closeModal({ type: "close" })}
                  >
                    Close
                  </button>
                  {priceCheckData && priceCheckData.amount !== "0" ? (
                    <button
                      className="px-6 py-3 rounded-xl bg-main text-center flex items-center"
                      onClick={() => closeModal({ type: "continue" })}
                    >
                      <span className="font-bold">Continue</span>
                    </button>
                  ) : null}
                </div>
              </div>
            </Modal>

            <VideoField
              onClickValidationHandler={onClickValidationHandler}
              setSelectedFile={setSelectedFile}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              setValue={setValue}
              setSelectedThumbnail={setSelectedThumbnail}
              register={register}
              errors={errors}
              setDuration={setDuration}
            />
            {/* <UploadDetails /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default SubmitVideo;

export function UploadDetails() {
  return (
    <section className="pb-12 text-white pt-24 px-4 lg:px-24">
      <div className="">
        <div className="">
          <div className="">
            <div className="">
              <div className="">
                <div className="text-center py-6">
                  <h6 className="lg:text-2xl text-lg  text-gray-400">
                    ZynoFlix
                  </h6>
                  <h2 className="lg:text-4xl text-lg  font-bold">
                    Unlocking Video Upload Achievement and Maximizing
                    Advertisement Earnings
                  </h2>
                </div>
              </div>
              <div className="">
                {/* <div slot="button-group">
                  <button data-type="primary">ZynoFlix&nbsp;</button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="pt-3">
            <div className="flex">
              <div className="flex  lg:flex-row space-y-2 lg:space-y-0 flex-col w-full">
                <div className="">
                  <article>
                    <div>
                      <h2 className="mb-2 text-lg font-semibold text-white">
                        Upload 20 video's start Monetization.
                      </h2>
                      <p className="text-sm font-medium text-gray-300">
                        {" "}
                        ZynoFlix 1000 views minimum to maximum approximate views
                        cost
                      </p>
                      <p className="text-sm font-medium text-gray-400">
                        <ul>
                          <li> * MOVIES REVIEW : 29Rs - 60Rs</li>
                          <li> * EDUCATIONAL : 15Rs - 70Rs</li>
                          <li> * COMEDY CHANNEL : 30Rs - 40Rs</li>
                          <li> * SCIENCE & TECH : 50Rs - 170Rs</li>
                          <li> * DIY : 11Rs - 44Rs</li>
                          <li> * ENTERTAINMENT OR GAMING : 14Rs - 80Rs</li>
                          <li> * RECIPES : 8Rs - 77Rs</li>
                        </ul>
                      </p>
                    </div>
                  </article>
                </div>
                <div className="w-full lg:hidden block">
                  <h2 className="py-6 text-4xl font-bold">ZynoFlix Awards</h2>
                  <p className="text-lg">
                    ZynoFlix, the cutting-edge platform for video uploads,
                    offers an unparalleled real-time award system for two
                    prestigious categories. ZynoFlix empowers users to submit
                    videos, participate in live events, and receive instant
                    recognition through captivating award images, setting the
                    stage for a revolutionary digital content-sharing
                    experience.
                  </p>
                  <div className="flex mt-8 w-full flex-col lg:flex-row gap-12 items-center">
                    <img
                      className="lg:w-[50%] w-full rounded-3xl"
                      src="/Images/silveraward.png"
                      alt=""
                    />
                    <img
                      src="/Images/gold-award.png"
                      className="lg:w-[50%] w-full rounded-3xl"
                      alt=""
                    />
                  </div>
                </div>
                <div className="">
                  <article>
                    <div className="max-w-md space-y-1  list-disc list-inside text-gray-400">
                      <h2 className="mb-2 text-lg font-semibold  text-white">
                        Available Categories
                      </h2>
                      <p>&nbsp;GAMING </p>
                      <p>MUSIC</p>
                      <p>AUTOS AND VEHICLESE</p>
                      <p>DUCATION</p>
                      <p>ENTERTAINMENT&nbsp;</p>
                      <p>PETS &amp; ANIMALS&nbsp;</p>
                      <p>COMEDY</p>
                      <p>NEWS &amp; POLITICS</p>
                      <p>PEOPLE AND BLOGSSCIENCE AND TECHNOLOGIES</p>
                      <p>FILM </p>
                      <p>ANIMATIONS&nbsp;</p>
                      <p>NON PROFT&nbsp;</p>
                      <p> ACTIVISMASMR</p>
                      <p>EDUCATIONAL</p>
                      <p> VIDEOS REVIEW</p>
                      <p>STRAVEL</p>
                      <p>SPORTS</p>
                      <p>BEAUTY</p>
                      <p>CHALLENGES</p>
                      <p>COOKING</p>
                      <p>HOW TO AND STYLE&nbsp;</p>
                    </div>
                  </article>
                </div>
                <div className="">
                  <article>
                    <div className="max-w-md space-y-1  list-disc list-inside text-gray-400">
                      <h2 className="mb-2 text-lg font-semibold text-white">
                        ad revenue cost&nbsp;
                      </h2>
                      <p>AD REVENUE FOR USERS&nbsp;</p>
                      <p>
                        {" "}
                        1 MILLION VIEWS[ 1 MILLION VIEWS ] 1000 * 10 = 10,000/-{" "}
                      </p>
                      <p>[ 1 MILLION VIEWS ]1000 * 15 = 15,000/-</p>
                      <p>[ 1 MILLION VIEWS ]1000 * 25 = 25,000/-</p>
                      <p>[ 1 MILLION VIEWS ]1000 * 30 = 30,000/-</p>
                      <p>[ 1 MILLION VIEWS ]1000 * 35 = 35,000/-</p>
                      <p>[ 1 MILLION VIEWS ]1000 * 41 = 41,000/-</p>
                      <p>[ 1 MILLION VIEWS ]1000 * 48 = 48,000/-</p>
                      <p>[ 1 MILLION VIEWS ]1000 * 53 = 53,000/-</p>
                      <p>[ 1 MILLION VIEWS ]1000 * 59 = 59,000/-</p>
                      <p>[ 1 MILLION VIEWS ]1000 * 62 = 62,000/-</p>
                      <p>[ 1 MILLION VIEWS ]1000 * 65 = 65,000/-</p>
                      <p>[ 1 MILLION VIEWS ]1000 * 70 = 70,000/-</p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
          <section className="ws-section ws-surface ws-light-0">
            <div className="ws-container">
              <div className="ws-m-block-title-770">
                <div slot="block-title">
                  <h6>
                    <br />
                  </h6>
                  <h2 className="text-2xl font-bold text-center py-6">
                    Video&nbsp; Duration Time &amp; Rev
                  </h2>
                  <h5>
                    <br />
                  </h5>
                </div>
              </div>
              <div className="ws-m-pricing-with-icon" data-surface={1}>
                <div className="flex  lg:flex-row flex-col justify-between w-full">
                  <div className="">
                    <article>
                      <div className="lg:text-center text-start flex items-center justify-center">
                        <svg
                          height={44}
                          width={44}
                          viewBox="0 0 64 64"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-labelledby="title"
                          aria-describedby="desc"
                          role="img"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <title>Dollar Bill</title>
                          <desc>
                            A line styled icon from Orion Icon Library.
                          </desc>
                          <path
                            strokeWidth={2}
                            strokeMiterlimit={10}
                            stroke="#ccc"
                            fill="none"
                            d="M54 32a8 8 0 0 1 8-8V14H2v10a8 8 0 1 1 0 16v10h60V40a8 8 0 0 1-8-8z"
                            data-name="layer2"
                            strokeLinejoin="round"
                            strokeLinecap="round"
                          />
                          <path
                            data-name="layer1"
                            strokeWidth={2}
                            strokeMiterlimit={10}
                            stroke="#ccc"
                            fill="none"
                            d="M36.93 27A5 5 0 0 0 32 22.835c-2.762 0-5 1.821-5 4.584s2.5 4.012 5 4.581 5 1.823 5 4.583-2.238 4.584-5 4.584a5 5 0 0 1-5-5"
                            strokeLinejoin="round"
                            strokeLinecap="round"
                          />
                          <path
                            d="M32 22.835v-2.501m0 23.335v-2.5"
                            strokeWidth={2}
                            strokeMiterlimit={10}
                            stroke="#ccc"
                            fill="none"
                            data-name="layer1"
                            strokeLinejoin="round"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                      <div className="max-w-md space-y-1  list-disc list-inside text-gray-400">
                        <h5>ZynoFlix VIDEO DURATION TIME</h5>
                        <ul className="text-start lg:text-center">
                          <li>
                            <p>
                              &nbsp;MINIMUM 1&nbsp; (&nbsp; RS 50 /-&nbsp;
                              &nbsp;)
                            </p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>MIN&nbsp;&nbsp;1 MIN - 2 MIN ( RS 150 /- )</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>2 MIN - 5 MIN ( RS 350 /- )</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>5 MIN - 7MIN ( RS 550 /- )</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>7 MIN - 10 MIN ( RS 850 /- )</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>&nbsp;10MIN - 15 MIN&nbsp; ( RS 1050 /- )</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>15 MIN - 30 MIN ( RS 1250 /- )</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>30 MIN - 1 HR ( RS 1750 /- )</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>&nbsp;1 HR - 2 HRS ( RS 2250 /- )&nbsp;</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>2HRS - 3 HRS ( RS 2850 /- )</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>3HRS - 4 HRS ( RS 3250 /- )</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>
                              &nbsp;4 HRS - 24 HRS&nbsp; (&nbsp; RS 5250 /- )
                            </p>
                          </li>
                        </ul>
                      </div>
                    </article>
                  </div>
                  <div className="">
                    <article>
                      <div className="flex text-start lg:items-center justify-center">
                        <svg
                          height={44}
                          width={44}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64"
                          aria-labelledby="title"
                          aria-describedby="desc"
                          role="img"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <title>Credit Card</title>
                          <desc>
                            A line styled icon from Orion Icon Library.
                          </desc>
                          <path
                            data-name="layer2"
                            d="M62 16a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v7h60zM2 23v25a3 3 0 0 0 3 3h54a3 3 0 0 0 3-3V23"
                            fill="none"
                            stroke="#ccc"
                            strokeMiterlimit={10}
                            strokeWidth={2}
                            strokeLinejoin="round"
                            strokeLinecap="round"
                          />
                          <path
                            data-name="layer1"
                            fill="none"
                            stroke="#ccc"
                            strokeMiterlimit={10}
                            strokeWidth={2}
                            d="M10 31h20m18 0h6m-44 8h8"
                            strokeLinejoin="round"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                      <div className="max-w-md space-y-1  list-disc list-inside text-gray-400">
                        <h5>ZynoFlix&nbsp;VIDEO UPLOADING CHARGES</h5>
                        <ul className="lg:text-center text-start">
                          <li>
                            <p>RS 50 /-&nbsp; IMAGE AD</p>
                          </li>
                          <li>
                            <p>RS 150 /- IMAGE AD</p>
                          </li>
                          <li>
                            <p>RS 350 /- VIDEO AD</p>
                          </li>
                          <li>
                            <p>RS 550 /- VIDEO AD</p>
                          </li>
                          <li>
                            <p>RS 850 /-&nbsp;VIDEO AD</p>
                          </li>
                          <li>
                            <p>RS 1050 /-&nbsp;VIDEO AD</p>
                          </li>
                          <li>
                            <p>RS 1250 /-&nbsp;VIDEO AD</p>
                          </li>
                          <li>
                            <p>RS 1750 /-&nbsp;VIDEO AD</p>
                          </li>
                          <li>
                            <p>RS 2250 /-&nbsp;VIDEO AD</p>
                          </li>
                          <li>
                            <p>RS 2850 /-&nbsp;VIDEO AD</p>
                          </li>
                          <li>
                            <p>RS 3250 /-&nbsp;VIDEO AD</p>
                          </li>
                          <li>
                            <p>RS 5250 /-&nbsp;VIDEO AD</p>
                          </li>
                        </ul>
                      </div>
                    </article>
                  </div>
                  {/* <div className="">
                    <article>
                      <div className="flex items-center justify-center">
                        <svg
                          height={44}
                          width={44}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64"
                          aria-labelledby="title"
                          aria-describedby="desc"
                          role="img"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <title>Bank Cards</title>
                          <desc>
                            A line styled icon from Orion Icon Library.
                          </desc>
                          <path
                            data-name="layer2"
                            d="M10.3 29l-2.2-6.1a2.4 2.4 0 0 1 1.4-3.1L50.1 5.1a2.4 2.4 0 0 1 3.1 1.4l8.7 24a2.4 2.4 0 0 1-1.4 3.1L50 37.4m-39.9-9.2L55 11.8m-8.3 9.8l4.5-1.6"
                            fill="none"
                            stroke="#ccc"
                            strokeMiterlimit={10}
                            strokeWidth={2}
                            strokeLinejoin="round"
                            strokeLinecap="round"
                          />
                          <rect
                            data-name="layer1"
                            x={2}
                            y={29}
                            width={48}
                            height={30}
                            rx="2.4"
                            ry="2.4"
                            fill="none"
                            stroke="#ccc"
                            strokeMiterlimit={10}
                            strokeWidth={2}
                            strokeLinejoin="round"
                            strokeLinecap="round"
                          />
                          <path
                            data-name="layer1"
                            fill="none"
                            stroke="#ccc"
                            strokeMiterlimit={10}
                            strokeWidth={2}
                            d="M10 43h14m12-6h6M10 51h2m8 0h2m8 0h2m8 0h2"
                            strokeLinejoin="round"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                      <div className="max-w-md space-y-1  list-disc list-inside text-gray-400">
                        <h5>ZynoFlix&nbsp;MINIMUM TO MAXIMUM AD COST&nbsp;</h5>
                        <ul className="lg:text-center text-start">
                          <li>
                            <p>AD COST FOR 1000 VIEWS</p>
                          </li>
                          <li>
                            <p>10RS.</p>
                          </li>
                          <li>
                            <p> 15RS.</p>
                          </li>
                          <li>
                            <p> 25RS. </p>
                          </li>
                          <li>
                            <p>30RS.</p>
                          </li>
                          <li>
                            <p> 35RS. </p>
                          </li>
                          <li>
                            <p>41RS. </p>
                          </li>
                          <li>
                            <p>48RS.</p>
                          </li>
                          <li>
                            <p> 53RS. </p>
                          </li>
                          <li>
                            <p>59RS.</p>
                          </li>
                          <li>
                            <p> 62RS. </p>
                          </li>
                          <li>
                            <p>65RS. </p>
                          </li>
                          <li>
                            <p>70RS</p>
                          </li>
                        </ul>
                      </div>
                    </article>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
          <div className="w-full lg:block hidden">
            <h2 className="py-6 text-4xl font-bold">ZynoFlix Win Awards</h2>
            <p className="text-lg">
              ZynoFlix, the cutting-edge platform for video uploads, offers an
              unparalleled real-time award system for two prestigious
              categories.ZynoFlix empowers users to submit videos, participate
              in live events, and receive instant recognition through
              captivating award images, setting the stage for a revolutionary
              digital content-sharing experience.
            </p>
            <div className="flex mt-8 w-full flex-col lg:flex-row gap-12 items-center">
              <img
                className="lg:w-[50%] w-full rounded-3xl"
                src="/Images/silveraward.png"
                alt=""
              />
              <img
                src="/Images/gold-award.png"
                className="lg:w-[50%] w-full rounded-3xl"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
