import React from "react";

const Founder = () => {
  return (
    <>
      <h2 className="text-center text-4xl font-semibold  text-white pt-36">
        Team
      </h2>
      {/* Features */}
      <div className="!pt-7  py-10 sm:px-6 lg:py-14 mx-auto">
        {/* Grid */}
        <div className="md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-12">
          <div className="flex items-center justify-center">
            <img
              className=" h-96 rounded-full object-left w-96 object-cover"
              src="/Images/founder.png"
              alt="Image Description"
            />
          </div>
          {/* End Col */}
          <div className="mt-5 sm:mt-10 px-6  lg:mt-0">
            <div className="space-y-6 sm:space-y-8">
              {/* Title */}
              <div className="space-y-2 md:space-y-4">
                <h2 className="font-bold text-3xl lg:text-4xl text-gray-200">
                  FOUNDER & CEO
                </h2>
                <div className="text-white text-base font-medium">
                  <h4>Name: Mr. UDHAYAPRAKASH P</h4>
                  <h4>POSITION:(Founder & CEO)</h4>
                </div>
                <div className="text-base">
                  <p className="text-gray-400">
                    comes with an international experience in Project
                    development and business development having worked with a
                    multinational company in Dubai. He is a mechatronics
                    Graduate with 5 years practical learning and development
                    experience in IT Enabled services and excellent direct
                    networker in UAE Ministry, He has worked with more than 3
                    owners and CEO'S of the leading companies n KSA QATAR/UAE in
                    terms of Policy implementation and project Execution.
                  </p>
                </div>
              </div>
              {/* End Title */}
            </div>
          </div>
          {/* End Col */}
        </div>
        {/* End Grid */}
      </div>
      {/* End Features */}
    </>
  );
};

export default Founder;
