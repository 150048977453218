import React from "react";
import { Link } from "react-router-dom";

const SellVidoe = () => {
  return (
    <div>
      <div className="text-white px-2 pb-6 lg:px-24 pt-24 lg:pt-0 h-auto lg:h-screen flex items-center justify-center flex-col">
        <h1 className="text-white pb-6 font-bold text-center text-xl lg:text-4xl">
          Sell Videos With us
        </h1>

        <div className=" text-xs lg:text-lg pb-6 text-gray-200">
          <p>
            At ZynoFlix, we specialize in purchasing a wide range of videos from
            our users, including short films, feature films, TV series, prank
            videos, vlogs, cooking videos, blogs, and more. We offer the best
            prices for your content. The process involves three rounds:
          </p>
          <br />
          <ul>
            <li>
              1. In the first round, we carefully review your video to ensure
              its quality and suitability for our platform.
            </li>
            <li>
              2. In the second round, we will discuss with you further details
              about the video and negotiate a fair price.
            </li>
            <li>
              3. Lastly, if all goes well in the previous rounds and both
              parties are satisfied with the terms, we will proceed to purchase
              your video.
            </li>
          </ul>
          <br />
          <p>
            Please note that a registration fee of 5000/- may apply. To get
            started on this exciting opportunity to sell your videos and make
            money, simply fill out our contact form and register your details.{" "}
            <br /> <br /> Our dedicated team at ZynoFlix will be in touch with
            you promptly to guide you through the process. Thank you for
            considering us as a platform to showcase and profit from your
            creative work!
          </p>
        </div>
        <Link
          to={"/contact-us"}
          className=" text-white mt-7 rounded-xl bg-main px-24 text-lg font-bold py-3"
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default SellVidoe;
