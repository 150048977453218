import React, {
  Children,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import {
  ShoppingCartIcon,
  VideoCameraIcon,
  HeartIcon,
  UserCircleIcon,
  Bars3Icon,
  CreditCardIcon,
  EyeIcon,
  XMarkIcon,
  PlusIcon,
  CameraIcon,
  HomeIcon,
  BriefcaseIcon,
  PaperAirplaneIcon,
  BellIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { navLinkList, navWantedList } from "../../store/mockStore";
import { AlertCircle, Icon, ShoppingBag, User } from "react-feather";
import { IconComponentProps } from "../../utils/genericTypes";
import Profile from "../profile/Profile";
import { useCookies } from "react-cookie";
import { useDisclosure } from "@mantine/hooks";
import { Drawer, Menu } from "@mantine/core";
import { fetchWishListItems } from "../../utils/userController";
import { TOKEN_NAME, USER_ID_NAME } from "../../utils/variables";
import SearchModal from "../searchComponents/SearchModal";
import SideNav from "./SideNav";
import { AddressBook } from "tabler-icons-react";
import useOnClickOutside from "../../utils/useOnClickOutside";
import { IconCameraBolt } from "@tabler/icons-react";
import { MyContext } from "../MyProvider/MyProvider";
import { fetchToken, logout, viewProfile } from "../../utils/util";

const handleNoRouteNavigation = (props: {
  name: string;
  setActiveTab: Function;
}) => {
  const { name, setActiveTab } = props;
  setActiveTab(name);
  const element: any = document.getElementById(name);
  // element.scrollIntoView({ behavior: "smooth" })
};

const WantedHeader = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const TOKEN = fetchToken(TOKEN_NAME);
  const isAuthenticated = TOKEN;
  const backDropref = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const sideNavCloseButtonRef = useRef<HTMLImageElement>(null);
  const [searchString, setSearchString] = useState("");
  const [showSideNav, setShowSideNav] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState<any>(false);
  const [searchEnabled, setSearchEnabled] = useState<any>();
  const [profile, setProfile] = useState<any>();
  useEffect(() => {
    if (true) {
      const ID = localStorage.getItem(USER_ID_NAME);
      if (ID && ID !== "") {
        viewProfile(ID, setProfile);
      }
    }
  }, []);
  useOnClickOutside(backDropref, () => setShowSideNav(false));
  useOnClickOutside(backDropref, () => setShowSearchModal(false));
  useOnClickOutside(backDropref, () => setOpenSearch(false));
  useEffect(() => {
    const activeItem = navLinkList.filter(
      (item) => item.to === location.pathname
    )[0];
    activeItem && setActiveTab(activeItem.name);

    if (location.pathname !== "/search-results") {
      setSearchString("");
    } else {
      const searchStringFromUrl = location.search.split("=")[1];
      setSearchString(searchStringFromUrl);
    }
  }, [location]);

  const [MobileNavActive, setMobileNavActive] = useState<any>();
  const onSearchClickHandler = () => {
    setShowSearchModal(!showSearchModal);
    setSearchEnabled(!showSearchModal);
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const MobileNavBarData = [
    { title: "Home", to: "/", icon: HomeIcon },
    { title: "Posted", to: "/wanted/my-post", icon: BriefcaseIcon },
    { title: "add", to: "/wanted/video/create", icon: PlusIcon, isAdd: true },
    { title: "Applied", to: "/wanted/my-applied", icon: CreditCardIcon },
    { title: "Profile", to: "/profile", icon: PaperAirplaneIcon },
  ];
  const [activeSession, setActiveSession] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [openSearch, setOpenSearch] = useState<boolean>();
  return (
    <>
      <header ref={backDropref} className="bg-transparent  top-0 z-50 relative">
        <nav
          className="flex items-center h-[95px] absolute top-0 w-full right-0 justify-between p-6 lg:px-12"
          aria-label="Global"
        >
          <div className="video-overlay-header"></div>
          <div className="flex w-full justify-between items-center">
            <div className="">
              <div className="flex lg:flex-1">
                <Link to="/" className="-m-1.5">
                  <img
                    className="pr-2 w-36 md:w-56"
                    src="/Images/Logo/logo.png"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className=" flex  items-center gap-6">
              {/* <div className="hidden relative items-center gap-6 lg:flex lg:flex-1 lg:justify-end">
                <div className="flex items-center relative">
                  <input
                    value={searchString}
                    name="searchVideos"
                    onClick={onSearchClickHandler}
                    onChange={onChangeHandler}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter" && searchString !== "") {
                        navigate(`/search-results?searchString=${searchString}`)
                        setSearchEnabled(false)
                        setShowSearchModal(false)
                      }
                    }}
                    type="text"
                    placeholder="Search for videos..."
                    className="outline-none pl-12 text-white placeholder:text-white bg-transparent focus:border-blue-500 h-[44px] rounded-xl  border  px-6 w-full"
                  />
                  <MagnifyingGlassIcon className="h-6 w-6 absolute top-auto left-3 text-white" />
                </div>
                <div
                  id="searchModalWrapper"
                  className={`${
                    showSearchModal
                      ? "block z-10 active overflow-hidden max-w-xl"
                      : "hidden"
                  } w-screen h-screen duration-300 left-48 flex items-end justify-end absolute top-6`}
                >
                  {showSearchModal && <SearchModal divRef={ref} />}
                </div>
              </div> */}
              <div className="hidden  lg:flex lg:gap-x-12">
                <ul
                  className={`flex text-white  justify-end items-center ${
                    isAuthenticated ? "grow-[2]" : "grow"
                  } gap-4`}
                >
                  {navWantedList
                    .filter(
                      (item) =>
                        (isAuthenticated && item.userState === "logged_in") ||
                        (!isAuthenticated && item.userState === "logged_out")
                    )
                    .map((link) => (
                      <CustomLinkComponent
                        key={link.name}
                        to={link?.to}
                        setActiveTab={setActiveTab}
                        name={link.name}
                      >
                        <PageTag
                          activeTab={activeTab}
                          name={link.name}
                          src={link.src}
                          iconComponent={link?.iconComponent}
                          type={link?.type}
                          buttonType={link?.buttonType}
                          count={notifications.length}
                          userState={link.userState}
                          isSessionValid={activeSession}
                          icon={link?.icon || AlertCircle}
                          avatar_url={profile?.avatar_url}
                          user_name={profile?.user_name}
                        />
                      </CustomLinkComponent>
                    ))}
                </ul>
              </div>
            </div>
            <div className="lg:hidden flex items-center gap-2  black">
              {/* <button
                onClick={() => setOpenSearch(!openSearch)}
                className="bg-transparent"
              >
                <MagnifyingGlassIcon className="w-6 text-white h-6" />
              </button> */}

              {!isAuthenticated && (
                <button
                  onClick={() => navigate("/login")}
                  className=" text-xs text-white font-bold rounded bg-main px-2 py-1"
                >
                  Login
                </button>
              )}
              {!isAuthenticated && (
                <button
                  onClick={() => navigate("/signup")}
                  className=" text-xs text-white font-bold rounded bg-main px-2 py-1"
                >
                  Signup
                </button>
              )}
              {isAuthenticated && (
                <div className="w-8">
                  <Menu withArrow position="bottom-end" shadow="md" width={200}>
                    <Menu.Target>
                      <button className="">
                        {profile?.avatar_url ? (
                          <img
                            src={profile?.avatar_url}
                            className={`rounded-full w-44 h-8 object-cover content-center `}
                          />
                        ) : (
                          <User color="white" className="mt-1" size={24} />
                        )}
                      </button>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item component="a" href="/profile">
                        Profile
                      </Menu.Item>
                      <Menu.Item component="a" href="/change-password">
                        Change Password
                      </Menu.Item>
                      <Menu.Item onClick={() => logout()}>Logout</Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
              )}
              {/* {isAuthenticated && (
                <button
                  onClick={() => navigate("/notifications")}
                  className="bg-transparent"
                >
                  <BellIcon className="w-6 text-white h-6" />
                </button>
              )}
              {isAuthenticated && (
                <button
                  onClick={() => navigate("/profile")}
                  className="bg-transparent"
                >
                  <WalletIcon className="w-6 text-white h-6" />
                </button>
              )} */}

              {openSearch && (
                <div className="absolute top-0 left-2 py-4 right-2">
                  <div className="flex  bg-background_body top-0 items-center relative">
                    <input
                      value={searchString}
                      name="searchVideos"
                      onClick={onSearchClickHandler}
                      onChange={onChangeHandler}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter" && searchString !== "") {
                          navigate(
                            `/search-results?searchString=${searchString}`
                          );
                          setSearchEnabled(false);
                          setShowSearchModal(false);
                        }
                      }}
                      type="text"
                      placeholder="Search for videos..."
                      className="outline-none pl-12 text-white placeholder:text-white bg-transparent focus:border-blue-500 h-[44px] rounded-xl  border  px-6 w-full"
                    />
                    <MagnifyingGlassIcon className="h-6 w-6 absolute top-auto left-3 text-white" />
                  </div>
                </div>
              )}
              {showSideNav && (
                <SideNav
                  backDropref={backDropref}
                  setShowSideNav={setShowSideNav}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  showSideNav={showSideNav}
                  sideNavCloseButtonRef={sideNavCloseButtonRef}
                />
              )}
            </div>
          </div>
        </nav>
        <nav className="lg:hidden text-white glass-color bg-background_item  flex justify-between  items-center px-6 py-3  fixed bottom-0 left-0 right-0  z-50">
          {MobileNavBarData?.map((value: any, index) => (
            <Link
              onClick={() => setMobileNavActive(index)}
              to={value.to}
              className=""
            >
              {/* {value.isAdd ? (
                <div className="flex items-center z-50 relative flex-col ">
                  <div className="bg-[#9e61ff] rounded-full -mt-8  !z-50  py-4 px-4">
                    <PlusIcon className="w-6 h-6" />
                  </div>
                </div>
              ) : (
                <div
                  className={`flex items-center ${
                    MobileNavActive === index ? "text-[#9e61ff]" : "text-white"
                  }  flex-col `}
                >
                  <value.icon className="w-6 h-6 " />

                  <h2 className="text-sm font-medium">{value.title}</h2>
                </div>
              )} */}
              <div
                className={`flex items-center ${
                  MobileNavActive === index ? "text-[#9e61ff]" : "text-white"
                }  flex-col `}
              >
                {value.isAdd ? (
                  <div className="border border-[#9e61ff] rounded-full px-1 py-1">
                    <value.icon className="w-6 h-6  rounded-full " />
                  </div>
                ) : (
                  <value.icon className="w-4 h-4 " />
                )}
                {value.isAdd ? null : (
                  <h2 className="text-xs font-medium">{value.title}</h2>
                )}
              </div>
            </Link>
          ))}
        </nav>
      </header>
      {children}{" "}
    </>
  );
};

export default WantedHeader;

interface CustomLinkComponentProps {
  to: string;
  name: string;
  setActiveTab: Function;
  children: React.ReactNode;
}
const CustomLinkComponent: React.FC<CustomLinkComponentProps> = ({
  to,
  name,
  setActiveTab,
  children,
}) => {
  const location = useLocation();
  // Access the URL path after the root
  const pathAfterRoot = location.pathname.substr(1);

  return (
    <li className={`nav-custom-link p-2 capitalize `}>
      {(pathAfterRoot === "" && name === "trending") ||
      (pathAfterRoot === "" && name === "featured") ? (
        <div
          className={`nav-links text-sm cursor-pointer`}
          onClick={() => handleNoRouteNavigation({ name, setActiveTab })}
        >
          {children}
        </div>
      ) : (
        <>
          {to !== "" ? (
            <Link
              to={to}
              className="nav-links text-sm"
              onClick={() => setActiveTab(name)}
            >
              {children}
            </Link>
          ) : (
            <div
              className={`nav-links text-sm cursor-pointer`}
              onClick={() => handleNoRouteNavigation({ name, setActiveTab })}
            >
              {children}
            </div>
          )}
        </>
      )}
    </li>
  );
};

const PageTag = (props: {
  name: string;
  src: string;
  count: number;
  userState: string;
  isSessionValid: boolean;
  activeTab: string;
  buttonType?: string;
  type?: string;
  iconComponent?: React.FC<IconComponentProps>;
  icon: Icon;
  avatar_url: string;
  user_name: string;
}) => {
  let element: JSX.Element = <div></div>;
  const { avatar_url, user_name, name, activeTab, type, src } = props;
  const location = useLocation();
  // const isActive = name.toLowerCase() === activeTab.toLowerCase()
  const isActive = false;

  const tabName = (
    <span
      className={`${isActive && "text-[var(--theme_purple)]"}  tracking-normal`}
    >
      {name}
    </span>
  );

  if (type === "button") {
    element = (
      <button
        className={`action-btn default-button-color rounded-xl px-4 text-sm font-semibold w-full py-3 action-btn-secondary${
          props.buttonType === "secondary" ? "-border" : ""
        } w-28 h-11 flex items-center justify-center `}
      >
        {name}
      </button>
    );
  } else if (type === "profile") {
    element = <Profile name={user_name} src={avatar_url} size={12} />;
  } else if (type === "icon") {
    element = (
      <div
        className={`flex gap-4 items-center justify-center relative ${name.toLowerCase()}-icon`}
      >
        {props.icon && (
          <props.icon
            strokeWidth={`${isActive ? "3" : "2"}`}
            color={`${isActive ? "#619bff" : "white"}`}
          />
        )}
        <span
          className={`purple-notification-badge ${!props.count && "hidden"}`}
        ></span>
      </div>
    );
  } else if (type === "icon_component") {
    element = (
      <div
        className={`flex gap-2 items-center justify-center relative ${name.toLowerCase()}-icon`}
      >
        {props.icon && (
          <props.icon
            strokeWidth={`${isActive ? "3" : "2"}`}
            color={`${isActive ? "#619bff" : "white"}`}
            size={20}
          />
        )}
        {tabName}
        {props.count > 0 && (
          <span className={`purple-notification-badge`}></span>
        )}
      </div>
    );
  } else {
    element = (
      <div className="flex gap-2 items-center">
        {src && <img alt={`${name} icon`} src={src}></img>}
        {tabName}
      </div>
    );
  }

  return element;
};
