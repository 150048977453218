import React, { useEffect, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"

import { fetchUploadList, viewProfile } from "../utils/util"
import { Check, User } from "react-feather"
import VideoComponent from "./LandingPage/component/video_duration"
import { ArrowDownTrayIcon, EyeIcon } from "@heroicons/react/24/outline"
import VideoCard from "./LandingPage/component/VideoCard"

interface AuthorProfileProps {}

const AuthorProfile: React.FC<AuthorProfileProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [author_profile, setAuthorProfile] = useState<any>()
  const [uploadList, setUploadList] = useState<any>()
  console.log("author profile first_name >>", author_profile?.first_name)
  useEffect(() => {
    const authorId = searchParams.get("author_id")
    if (authorId) {
      fetchUploadList(authorId, setUploadList)
      viewProfile(authorId, setAuthorProfile)
    }
  }, [searchParams])

  return (
    <>
      {author_profile && (
        <div className="flex flex-col pt-24 text-white justify-center md:flex-row px-4 md:px-16 py-0">
          <div className="profile-page-wrapper grow border-bottom-grey md:border-0">
            <div className="flex text-2xl font-bold justify-between p-2 pb-4">
              <div className="flex gap-2 items-center">
                <User color="purple" />
                <span>Author's Profile</span>
              </div>
            </div>

            {/* profile tab */}
            <div className="md:flex md:gap-4 md:border-bottom md:mb-4 md:border-bottom-grey">
              <div className="profile-tab">
                <div className="my-2 md:my-0 flex-col md:h-80 flex gap-8 grow justify-around md:justify-center items-center py-4 px-6 md:p-8 bg-[#131927] rounded-lg md:flex md:flex-col md:w-64">
                  {author_profile?.avatar_url ? (
                    <img
                      src={author_profile?.avatar_url}
                      alt={`profile picture`}
                      className={`profile-picture rounded-full w-16 h-16 md:h-32 md:w-32`}
                    ></img>
                  ) : (
                    <User size={90} />
                  )}
                  <div className="flex flex-col gap-2 justify-start grow md:grow-0">
                    {author_profile?.user_name ? (
                      <>
                        <div className="flex gap-4 items-center">
                          <span className="text-xl block w-36 whitespace-nowrap overflow-hidden text-ellipsis">
                            @{author_profile?.user_name}
                          </span>
                          <span className="rounded-full bg-active-border p-1">
                            <Check size="16px" />
                          </span>
                        </div>
                        <span className="text-slate-500 whitespace-nowrap">
                          {author_profile?.first_name}&nbsp;
                          {author_profile?.last_name}
                        </span>
                        <div className="flex w-full text-xs">
                          <div>{`Followers: ${
                            author_profile?.total_subscribers.length || 0
                          }`}</div>
                        </div>
                      </>
                    ) : (
                      <div className="flex gap-4 items-center">
                        <span className="text-xl block w-36 whitespace-nowrap overflow-hidden text-ellipsis">
                          @Admin
                        </span>
                        <span className="rounded-full bg-active-border p-1">
                          <Check size="16px" />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="profile-info py-8  md:py-0 border-bottom-grey md:border-0 md:grid md:grid-cols-3 md:grow gap-4">
                <div className="flex flex-col gap-4">
                  <span className="flex gap-2 md:gap-4 flex-col">
                    <div
                      className={`flex flex-col gap-4 md:gap-2 py-2 md:p-0 capitalize`}
                    >
                      <label className="field-title capitalize">
                        First Name
                      </label>
                      <input
                        className={`cursor-not-allowed pointer-events-none field-value text-slate-400 px-4 h-[60px] grow rounded-lg bg-[#131927] whitespace-nowrap overflow-hidden flex items-center`}
                        readOnly={true}
                        value={author_profile?.first_name || "N/A"}
                        disabled={true}
                      />
                    </div>
                    <div
                      className={`flex flex-col gap-4 md:gap-2 py-2 md:p-0 capitalize`}
                    >
                      <label className="field-title capitalize">
                        Last Name
                      </label>
                      <input
                        className={`cursor-not-allowed pointer-events-none field-value text-slate-400 px-4 h-[60px] grow rounded-lg bg-[#131927] whitespace-nowrap overflow-hidden flex items-center`}
                        readOnly={true}
                        value={author_profile?.last_name || "N/A"}
                        disabled={true}
                      />
                    </div>
                    <div
                      className={`flex flex-col gap-4 md:gap-2 py-2 md:p-0 capitalize`}
                    >
                      <label className="field-title capitalize">
                        Contact Number
                      </label>
                      <input
                        className={`cursor-not-allowed pointer-events-none field-value text-slate-400 px-4 h-[60px] grow rounded-lg bg-[#131927] whitespace-nowrap overflow-hidden flex items-center`}
                        readOnly={true}
                        value={author_profile?.contact_number || "N/A"}
                        disabled={true}
                      />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 py-6 lg:py-6 px-4 lg:px-14 lg:grid-cols-4 gap-6">
        {uploadList?.map((value: any) => (
          <VideoCard trendingVideos={value} />
        ))}
      </div>
    </>
  )
}

export default AuthorProfile
