import React, { useEffect, useState } from "react"

import { logout } from "../../utils/util"
import { useLocation, useNavigate } from "react-router-dom"
import { User } from "react-feather"
import { Button, Menu } from "@mantine/core"
import { IconPhoto, IconSettings } from "@tabler/icons-react"
// import { useLoginStore } from "../../store/login"

interface ProfileProps {
  name: string
  src: string
  showName?: boolean
  customClassNames?: string
  nameOn?: "right" | "bottom"
  grow?: number
  size?: number
  mdSize?: number
  noDropDown?: boolean
}

const links = [
  { href: "/profile", title: "Profile" },
  { href: "/change-password", title: "Change Password" },
  {
    href: "",
    title: "Logout",
    handler: (navigate: Function, to: string, setRequestStatus: Function) => {
      logout()
    },
  },
]

const Profile: React.FC<ProfileProps> = (props) => {
  const _mdSize = props?.mdSize || 12
  // const { setRequestStatus } = useLoginStore()
  const [activeLink, setActiveLink] = useState("")

  const navigate = useNavigate()
  const location = useLocation()

  const onClickHandler = (props: {
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    title: string
    handler?: Function
    href: string
  }) => {
    props.event.preventDefault()

    setActiveLink(props.title)
    if (props.handler) {
      // props.handler(navigate, "/", setRequestStatus)
    } else if (props.href !== "") {
      navigate(props?.href)
    }
  }

  useEffect(() => {
    if (location.pathname === "/profile") {
      setActiveLink("Profile")
    } else {
      setActiveLink("")
    }
  }, [location])

  return (
    <>
      <div
        id="profile-container"
        className={`relative profile-wrapper grow-[${
          props?.grow
        }] flex justify-center items-center ${
          !props?.noDropDown && "dropdown"
        } ${
          props.nameOn === "right"
            ? "flex-row gap-4 capitalize"
            : "flex-col md:w-16 h-6 md:h-16"
        }  ${props?.customClassNames}`}
      >
        <Menu withArrow position="bottom-end" shadow="md" width={200}>
          <Menu.Target>
            <button className="my-2 flex bg-transparent  grow justify-center items-center ">
              {props.src ? (
                <img
                  src={props.src !== "" ? props.src : ""}
                  alt={`${props.name} picture`}
                  data-component-id={"side-nav-profile"}
                  className={`profile-picture dropbtn rounded-full w-${
                    props?.size ? props.size : "6"
                  } h-${
                    props?.size ? props.size : "6"
                  } md:w-${_mdSize} md:h-${_mdSize}`}
                ></img>
              ) : (
                <User size={30} />
              )}
            </button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item component="a" href="/profile">
              Profile
            </Menu.Item>
            <Menu.Item component="a" href="/forgot-password">
              Change Password
            </Menu.Item>
            <Menu.Item onClick={() => logout()}>Logout</Menu.Item>
          </Menu.Dropdown>
        </Menu>
        {/* {props?.showName && <p className="no-wrap">{props.name}</p>} */}
        {/* <div className="dropdown">
          <div
            className={` absolute z-20 top-[-10px] right-[-25px] w-40 hover:border-stone-50 dropdown-content bg-secondary-background rounded-tl-lg rounded-b-lg`}
          >
            {links.map((link) => (
              <a
                href={link.href}
                key={link.title}
                className={`first:rounded-tl-lg last:rounded-b-lg border-active-border ${
                  activeLink === link.title ? "bg-active-border active " : ""
                }`}
                onClick={(e) =>
                  onClickHandler({
                    event: e,
                    title: link.title,
                    handler: link.handler,
                    href: link.href,
                  })
                }
              >
                {link.title}
              </a>
            ))}
          </div>
        </div> */}
      </div>
    </>
  )
}

export default Profile
