import "./Styles.css"
import React, { useMemo } from "react"
import { RE_DIGIT } from "./../../utils/constants"
// import { useSignUpStore } from '../../store/signup';

export type Props = {
  value: string
  valueLength: number
  onChange: Function
  enableOTP: boolean
}

export default function OtpInput({
  value,
  valueLength,
  onChange,
  enableOTP,
}: Props) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    return false
  }

  const inputfocus = (elmnt: React.KeyboardEvent<HTMLInputElement>) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.currentTarget.tabIndex - 2
      if (next > -1 && elmnt.currentTarget.form) {
        ;(elmnt.currentTarget.form.elements[next] as HTMLElement).focus()
      }
    } else if (elmnt.key >= "0" && elmnt.key <= "9") {
      const next = elmnt.currentTarget.tabIndex
      if (next < 6 && elmnt.currentTarget.form) {
        ;(elmnt.currentTarget.form.elements[next] as HTMLElement).focus()
      }
    }
  }

  const valueItems = useMemo(() => {
    const valueArray = value.split("")
    const items: Array<{ char: string }> = []
    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i]

      if (RE_DIGIT.test(char)) {
        items.push({ char })
      } else {
        items.push({ char: "" })
      }
    }

    return items
  }, [value, valueLength])

  return (
    <div className="otp-group flex justify-between grow gap-4">
      <form onSubmit={handleSubmit}>
        {valueItems.map(({ char }, idx) => (
          <input
            key={idx}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{1}"
            maxLength={valueLength}
            className={`otp-input text-black h-14 ${
              !enableOTP && "opacity-3 cursor-not-allowed pointer-events-none"
            }`}
            value={char}
            tabIndex={++idx}
            onKeyUp={(e) => inputfocus(e)}
            onKeyDown={(e) => {
              if (e.key.toLowerCase() === "backspace") {
                onChange(
                  `${value.substring(0, idx - 1)}${value.substring(idx)}`
                )
              } else if (value.length < 6 && e.key >= "0" && e.key <= "9") {
                onChange(`${value.trim()}${e.key}`)
              }
            }}
          />
        ))}
      </form>
    </div>
  )
}
