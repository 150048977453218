import { Modal, Select, Tooltip } from "@mantine/core";
import React, { useEffect, useState } from "react";
import Language from "../../JsonData/languages.json";
import {
  SearchWantedVideo,
  fetchAllWantedVideo,
  fetchCategories,
  fetchWantedVideoCategoryCount,
  filterWantedVideo,
  getProfileDetails,
  viewProfile,
} from "../../utils/util";
import countryapi from "../../JsonData/Country.json";
import { Carousel } from "@mantine/carousel";
import { Link, useNavigate } from "react-router-dom";
import ImageDisplay from "../../components/ImageDisplay";
import { useCookies } from "react-cookie";
import {
  ETUBES_URL_PREFIX,
  TOKEN_NAME,
  USER_ID_NAME,
} from "../../utils/variables";

import {
  Bars4Icon,
  BriefcaseIcon,
  ClockIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";

const WantedVideoMain = () => {
  const [WantedVideo, setSetWantedVideo] = useState<any>([]);
  const [category, setCategories] = useState<any>("");
  const [country, setCountry] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [activeCategory, setActiveCategory] = useState<any>("");
  const search_data = "sep";
  const [opened, { open, close }] = useDisclosure(false);

  const [SearchValue, setSearchValue] = useState<any>();
  const [wantedVideoCout, setWantedVideoCout] = useState<any>();
  const [profileDetails, setProfileDetails] = useState<any>({});
  const ID = localStorage.getItem(USER_ID_NAME);
  useEffect(() => {
    fetchAllWantedVideo({ setSetWantedVideo, static: true });
    SearchWantedVideo(search_data);
    fetchCategories({ setCategories, static: true });
    fetchWantedVideoCategoryCount({
      setWantedVideoCout: setWantedVideoCout,
      static: true,
    });
    if (ID && ID !== "") {
      getProfileDetails(ID, setProfileDetails);
    }
  }, [setSetWantedVideo, search_data, setCategories]);
  const navigation = useNavigate();
  const HangleSearch = () => {
    filterWantedVideo({
      category: activeCategory,
      country,
      language: state,
      setSearchValue: setSearchValue,
    });

    const targetElement = document.querySelector("#WantedVideocard");

    if (targetElement) {
      // Scroll to the target element with smooth animation
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start", // You can adjust the alignment as needed
      });
    }
  };
  const LanguageData = [
    {
      countries: [
        {
          name: "United States",
          languages: "English",
        },
        {
          name: "Germany",
          languages: "German",
        },
        {
          name: "France",
          languages: "French",
        },
        {
          name: "China",
          languages: "Mandarin",
        },
        {
          name: "India",
          languages: "Hindi",
        },
      ],
    },
  ];
  const getRandomNumber = () => Math.floor(100 + Math.random() * 900);
  const [profile, setProfile] = useState<any>();
  useEffect(() => {
    if (true) {
      const ID = localStorage.getItem(USER_ID_NAME);
      if (ID && ID !== "") {
        viewProfile(ID, setProfile);
      }
    }
  }, []);
  const isSub = profile?.is_wanted_video_subscription;

  return (
    <div className="bg-background_body relative w-full text-white">
      <ImageDisplay
        // PagePath="/wanted/video"
        Page="wanted"
        imageUrl="/Images/newUser/Wanted.png"
      />
      <div className="relative h-screen">
        <video
          className="right-0 z-10 backdrop-brightness-75 object-cover bottom-0 w-full  h-screen absolute top-0 left-0 rounded-2xl"
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
          src={"/Images/city1.mp4"}
        />
        <div className="pt-24 z-20 relative">
          <section className=" z-50 h-full lg:h-screen flex items-center justify-center ">
            <div className="w-full h-full flex items-center justify-center ">
              <div className="mx-auto !z-50 px-4 sm:px-6 lg:px-8 pb-16 text-center">
                <h1 className="mx-auto max-w-4xl pt-32 lg:pt-0 font-display text-3xl lg:text-5xl font-medium tracking-tight  sm:text-7xl">
                  Customize{/* */}
                  <span className="relative whitespace-nowrap text-[#85FF83]">
                    <svg
                      aria-hidden="true"
                      viewBox="0 0 418 42"
                      className="absolute left-0 top-2/3 h-[0.58em] w-full fill-[#619bff]"
                      preserveAspectRatio="none"
                    >
                      <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
                    </svg>
                    <span className="relative">Your Video</span>
                  </span>{" "}
                  {/* */} Needs With Us
                </h1>
                <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-300">
                  APPLY & EARN UNLIMITED
                </p>
                <div className="flex rounded-xl py-6 my-6 items-center justify-center">
                  <div className="bg-[#1a1d33] py-6 rounded-xl px-8 w-[90%] lg:w-[80%]">
                    <div className=" w-full flex lg:flex-row flex-col gap-2 items-center">
                      <div className="flex bg-background_body  w-full rounded-xl px-6 items-center gap-2">
                        <BriefcaseIcon className="w-10 h-10" />
                        <Select
                          onChange={(e) => setActiveCategory(e)}
                          searchable
                          withinPortal
                          className="Search-input-video"
                          placeholder="Category"
                          data={
                            (category &&
                              category?.map((value: any) => value?.name)) || [
                              "wait",
                            ]
                          }
                        />
                      </div>
                      <div className="flex bg-background_body  w-full rounded-xl px-6 items-center gap-2">
                        <MapPinIcon className="w-10 h-10" />
                        <Select
                          onChange={setCountry}
                          searchable
                          withinPortal
                          className="Search-input-video dropdown"
                          placeholder="Location"
                          data={
                            countryapi?.map((value) => value.name) || ["state"]
                          }
                        />
                        {/* <select name="" id="">
                        {iataDetails &&
                          iataDetails?.map((value: any) => (
                            <option value={value?.state}>{value.state}</option>
                          ))}
                      </select> */}
                      </div>
                      <div className="flex bg-background_body  w-full rounded-xl px-6 items-center gap-2">
                        <Bars4Icon className="w-10 h-10" />
                        <Select
                          onChange={setState}
                          className="Search-input-video"
                          placeholder="Language"
                          data={Language?.map((value) => value.name)}
                        />
                      </div>
                      <button
                        onClick={HangleSearch}
                        className="default-button-color w-full duration-500 px-6 lg:px-6 py-2 lg:py-4 rounded-xl mt-4 lg:mt-0  text-white hover:text-black text-sm hover:bg-white lg:text-base font-bold"
                      >
                        Search Profile
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="flex items-center lg:flex-row flex-col justify-center">
                <span className="pl-2">Popular Searches: </span>{" "}
                <h3 className="underline pl-2">
                  Content Writer , Finance , Human Resource , Management
                </h3>
              </div> */}
                {/* <div className="py-3 flex items-center justify-center gap-8">
                <div className="">
                  <h2 className="not-italic font-bold text-lg lg:text-2xl leading-tight">
                    64 K +
                  </h2>
                  <p className="text-sm text-gray-300 font-semibold">
                    Daily jobs posted
                  </p>
                </div>
                <div className="">
                  <h2 className="not-italic font-bold text-lg lg:text-2xl  leading-tight">
                    64 K +
                  </h2>
                  <p className="text-sm text-gray-300 font-semibold">
                    Daily jobs posted
                  </p>
                </div>
                <div className="">
                  <h2 className="not-italic font-bold text-lg lg:text-2xl  leading-tight">
                    64 K +
                  </h2>
                  <p className="text-sm text-gray-300 font-semibold">
                    Daily jobs posted
                  </p>
                </div>
                <div className="">
                  <h2 className="not-italic font-bold text-lg lg:text-2xl  leading-tight">
                    64 K +
                  </h2>
                  <p className="text-sm text-gray-300 font-semibold">
                    Daily jobs posted
                  </p>
                </div>
              </div> */}
              </div>
            </div>
          </section>
        </div>
      </div>
      <section className="relative pt-6 lg:pt-0">
        <div className=" ">
          <div className="lg:py-6 py-0 ">
            <h1 className="text-center text-lg lg:text-[34px] font-bold">
              Browse by category
            </h1>
            <p className="text-center font-medium text-xs lg:text-base duration-500 text-gray-500">
              Find the job that’s perfect for you. about 800+ new jobs everyday
            </p>
          </div>
          <div className="flex py-6 ">
            <div className="overflow-hidden">
              <div className="flex overflow-hidden px-2 lg:px-6 gap-6">
                <Carousel slideSize="0%" slideGap="md" align="start">
                  {wantedVideoCout?.map((value: any) => (
                    <Carousel.Slide>
                      <div className="flex w-max bg-background_item hover:border-blue-400  py-6 px-6 rounded-xl border border-gray-500 items-center gap-3">
                        <div className="">
                          <img
                            className="w-6 h-6"
                            src="https://jthemes.com/themes/wp/jobbox/wp-content/uploads/2023/03/research.svg"
                            alt=""
                          />
                        </div>
                        <div className="">
                          <h4 className="lg:text-lg text-xs font-bold">
                            {value?._id?.category}{" "}
                          </h4>
                          <p className="lg:text-base text-xs font-medium text-gray-500">
                            {getRandomNumber()} Jobs Available{" "}
                            {/* other card content */}
                          </p>
                        </div>
                      </div>
                    </Carousel.Slide>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="WantedVideocard"
        className="flex pb-6 w-full px-4   items-center justify-center"
      >
        <div className="">
          <h2 className="not-italic font-bold text-base lg:text-4xl leading-tight">
            Jobs of the day
          </h2>
          <p className="pb-6 text-xs font-normal text-gray-500">
            Search and connect with the right candidates faster
          </p>
          <div
            id="WantedVideocard"
            className="grid grid-flow-row gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
          >
            {/* Card */}
            {!SearchValue
              ? WantedVideo?.map((value: any, index: any) => (
                  <JobCard
                    _id={value._id}
                    title={value.title}
                    description={value.description}
                    category={value.category}
                    country={value.country}
                    state={value.state}
                    sub_title={value.sub_title}
                    contact_number={value.contact_number}
                    email={value.email}
                    price={value.price}
                    language={value.language}
                    created_by_id={value.created_by_id}
                    created_by_name={value.created_by_name}
                    created_date={value.created_date}
                    poster_img={value.live_streaming_event}
                    isSubscribed={profileDetails.is_wanted_video_subscription}
                  />
                ))
              : null}

            {!SearchValue || (SearchValue && SearchValue.length === 0) ? (
              <div
                className={`h-[400px] ${
                  SearchValue ? "block" : "hidden"
                } col-span-4 flex flex-col border shadow-sm rounded-xl hover:border-blue-500 bg-gray-800 border-gray-700 shadow-slate-700/[.7]`}
              >
                <div className="flex flex-auto w-full flex-col justify-center items-center p-4 md:p-5">
                  <svg
                    className="max-w-[5rem]"
                    viewBox="0 0 375 428"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M254.509 253.872L226.509 226.872"
                      className=" stroke-white"
                      stroke="currentColor"
                      strokeWidth={7}
                      strokeLinecap="round"
                    />
                    <path
                      d="M237.219 54.3721C254.387 76.4666 264.609 104.226 264.609 134.372C264.609 206.445 206.182 264.872 134.109 264.872C62.0355 264.872 3.60864 206.445 3.60864 134.372C3.60864 62.2989 62.0355 3.87207 134.109 3.87207C160.463 3.87207 184.993 11.6844 205.509 25.1196"
                      className=" stroke-white"
                      stroke="currentColor"
                      strokeWidth={7}
                      strokeLinecap="round"
                    />
                    <rect
                      x="270.524"
                      y="221.872"
                      width="137.404"
                      height="73.2425"
                      rx="36.6212"
                      transform="rotate(40.8596 270.524 221.872)"
                      className=" fill-white"
                      fill="currentColor"
                    />
                    <ellipse
                      cx="133.109"
                      cy="404.372"
                      rx="121.5"
                      ry="23.5"
                      className=" fill-white"
                      fill="currentColor"
                    />
                    <path
                      d="M111.608 188.872C120.959 177.043 141.18 171.616 156.608 188.872"
                      className=" stroke-white"
                      stroke="currentColor"
                      strokeWidth={7}
                      strokeLinecap="round"
                    />
                    <ellipse
                      cx="96.6084"
                      cy="116.872"
                      rx={9}
                      ry={12}
                      className=" fill-white"
                      fill="currentColor"
                    />
                    <ellipse
                      cx="172.608"
                      cy="117.872"
                      rx={9}
                      ry={12}
                      className=" fill-white"
                      fill="currentColor"
                    />
                    <path
                      d="M194.339 147.588C189.547 148.866 189.114 142.999 189.728 138.038C189.918 136.501 191.738 135.958 192.749 137.131C196.12 141.047 199.165 146.301 194.339 147.588Z"
                      className=" fill-white"
                      fill="currentColor"
                    />
                  </svg>
                  <p className="mt-5 text-sm text-gray-500">No data to show</p>
                </div>
              </div>
            ) : (
              SearchValue?.map((value: any, index: any) => (
                <JobCard
                  _id={value._id}
                  title={value.title}
                  description={value.description}
                  category={value.category}
                  country={value.country}
                  state={value.state}
                  sub_title={value.sub_title}
                  contact_number={value.contact_number}
                  email={value.email}
                  price={value.price}
                  language={value.language}
                  created_by_id={value.created_by_id}
                  created_by_name={value.created_by_name}
                  created_date={value.created_date}
                  poster_img={value.live_streaming_event}
                  isSubscribed={profileDetails.is_wanted_video_subscription}
                />
              ))
            )}
          </div>
          {!isSub && (
            <div className="py-6 flex items-center justify-center">
              <button
                onClick={() => open()}
                className="bg-main  px-4 py-2 rounded-xl"
              >
                More
              </button>
              <Modal opened={opened} onClose={close} title="membership">
                <div className="p-4">
                  <p className="text-lg mb-4">
                    For more job opportunities, you need to buy a 1-month
                    membership.
                  </p>
                  {/* Add a button or link to redirect to the membership page */}
                  <Link
                    to={`/wanted/video/apply`}
                    className="bg-blue-500 text-white px-4 py-2 rounded-xl hover:bg-blue-600"
                  >
                    Buy Membership
                  </Link>
                </div>
              </Modal>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default WantedVideoMain;

export function JobCard(props: any) {
  const apiDate = props.created_date;
  const formattedDate = new Date(apiDate).toLocaleDateString("en-IN", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  const navigation = useNavigate();
  const ID = localStorage.getItem(USER_ID_NAME) || "";
  // const handletoDelete = async () => {
  //   const response = await axios.delete(
  //     ETUBES_URL_PREFIX + "/wanted_videos/" + props._id,
  //     {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem(TOKEN_NAME),
  //       },
  //     }
  //   );

  //   if (response.data.success) {
  //     alert("Job deleted successfully!");
  //     navigation("/wanted/video");
  //   }

  //   // alert("working");
  // };

  return (
    <div className="w-full border hover:border-blue-500 duration-300 rounded-xl shadow bg-[rgba(146,146,146,0.20)] border-[#ffffff1a]">
      {/* <button onClick={handletoDelete} className="px-6 py-3 rounded-3xl">
        Delete
      </button> */}
      <img
        className="rounded-2xl object-cover h-64 w-full object-center px-2 py-2"
        src={props.poster_img}
        alt=""
      />
      <div className="p-5">
        <div className="flex items-center justify-between">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-white">
            {props.title}
          </h5>
          <button
            onClick={() => navigation(`/wanted/video/apply?id=${ID}`)}
            className=" px-6 rounded-xl bg-[#1E50FF] text-xs font-semibold lg:text-base  py-2"
          >
            Apply
          </button>
        </div>
        <p>{props.sub_title}</p>

        <div className="flex gap-2 items-center">
          <p className="flex gap-2 items-center">
            <MapPinIcon className="w-6 h-6" /> {props.country}
          </p>
          <p className="flex items-center gap-1">
            <ClockIcon className="w-6 h-6" /> {formattedDate}
          </p>
          <p className="flex items-center gap-1">{props?.language}</p>
        </div>
        <div className="py-4 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="bg-[#1E50FF] px-6 py-1 rounded-xl text-base font-bold">
              {props?.category}
            </div>
          </div>
          <div className="text-xl not-italic font-semibold leading-[30px] capitalize">
            ₹ {props.price}
          </div>
        </div>
        <h2 className="">
          <span className="bg-transparent">Contact : </span>
          <Tooltip label="Pay for View">
            <span className={!props.isSubscribed ? "opacity-100 blur-sm" : ""}>
              {props.isSubscribed ? props.contact_number : `**********`}
            </span>
          </Tooltip>
        </h2>
        <h2 className="">
          <span>Email : </span>
          <Tooltip label="Pay for View">
            <span className={!props.isSubscribed ? "opacity-100 blur-sm" : ""}>
              {props.isSubscribed ? props.email : `**********`}
            </span>
          </Tooltip>
        </h2>
        <p className="mb-3 font-normal  line-clamp-4 text-gray-400">
          {props.description}
        </p>
      </div>
    </div>
  );
}
