import { tOrderState, tVideoState, tWishlistState } from "./genericTypes"
import { fetchToken } from "./util"
import {
  ETUBES_URL_PREFIX,
  STATIC_TOKEN,
  TOKEN_NAME,
  USER_ID_NAME,
} from "./variables"

export const fetchOrders = async (props: {
  userId: string
  setOrders: Function
  setTransactions: Function
  setRequestStatus: Function
}) => {
  const { userId, setOrders, setRequestStatus, setTransactions } = props
  try {
    setRequestStatus({ loading: true, success: false, error: false })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "GET",
    }

    fetch(`${ETUBES_URL_PREFIX}/get_order/${userId}`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({ loading: false, success: true, error: false })
        if (response.status_code === 200) {
          setOrders(
            response.result.filter(
              (order: tOrderState) => order.status === "paid"
            )
          )
          setTransactions(response.result)
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({ loading: false, success: false, error: true })
    console.log("--- Error while fetching orders:", error)
  }
}

export const fetchPurchaseList = async (props: {
  userId: string
  setPurchaseList: Function
  setRequestStatus: Function
}) => {
  const { userId, setRequestStatus, setPurchaseList } = props
  try {
    setRequestStatus({ loading: true, success: false, error: false })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "GET",
    }

    fetch(`${ETUBES_URL_PREFIX}/my_purchase_list/${userId}`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({ loading: false, success: true, error: false })
        if (response.status_code === 200) {
          setPurchaseList(
            response.result.filter(
              (order: tOrderState) => order.status === "paid"
            )
          )
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({ loading: false, success: false, error: true })
    console.log("--- Error while fetching purchase list:", error)
  }
}

export const fetchCartItems = async (props: {
  userId: string
  setCartItems: Function
  setRequestStatus: Function
}) => {
  const { userId, setCartItems, setRequestStatus } = props
  try {
    setRequestStatus({ loading: true, success: false, error: false })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "GET",
    }

    fetch(`${ETUBES_URL_PREFIX}/my_cart/${userId}`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({ loading: false, success: true, error: false })
        if (response.status_code === 200) {
          setCartItems(response.result)
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({ loading: false, success: false, error: true })
    console.log("--- Error while fetching cart items:", error)
  }
}

export const addToCartHandler = (props: {
  singleActiveVideo: tVideoState
  setRequestStatus: Function
}) => {
  const { singleActiveVideo, setRequestStatus } = props
  try {
    setRequestStatus({ loading: true, success: false, error: false })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(singleActiveVideo),
    }

    fetch(`${ETUBES_URL_PREFIX}/my_cart`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({ loading: false, success: true, error: false })
        if (response.status_code === 200) {
          console.log(response.message)
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({ loading: false, success: false, error: true })
    console.log("--- Error while Add cart items:", error)
  }
}

export const addToCartNewHandler = (props: {
  videoId: string
  setRequestStatus: Function
}) => {
  const { setRequestStatus, videoId } = props

  try {
    setRequestStatus({ loading: false, success: false, error: false })
    const TOKEN = fetchToken(TOKEN_NAME)
    const ID = localStorage.getItem(USER_ID_NAME)
    const fetchSingleVideoOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: TOKEN,
      },
    }

    fetch(`${ETUBES_URL_PREFIX}/videos/${videoId}`, fetchSingleVideoOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status_code === 200) {
          const singleActiveVideo = {
            video_id: data.result[0]._id,
            video_cost: data.result[0].cost,
            video_title: data.result[0].title,
            video_description: data.result[0].description,
            video_url: data.result[0].url,
            created_by_id: ID,
            created_by_name: data.result[0].created_by_name,
          }
          const options = {
            headers: {
              Authorization: TOKEN,
              "Content-type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(singleActiveVideo),
          }
          fetch(`${ETUBES_URL_PREFIX}/my_cart`, options)
            .then((res) => res.json())
            .then((response) => {
              setRequestStatus({ loading: false, success: true, error: false })
              if (response.status_code === 200) {
                console.log(response.message)
              }
            })
            .catch((err) => console.log(err))
        } else {
          console.log(data)
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({ loading: false, success: false, error: true })
    console.log("--- Error while Add cart items:", error)
  }
}

export const removeFromCart = async (props: { id: string }) => {
  try {
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "DELETE",
    }
    const URL = `${ETUBES_URL_PREFIX}/my_cart/` + props.id
    await fetch(URL, options)
  } catch (error) {
    console.log(error)
  }
}

export const fetchWishListItems = async (props: {
  userId: string
  setWishlistItems: Function
  setRequestStatus: Function
  triggerLoader?: boolean
}) => {
  const { userId, setWishlistItems, setRequestStatus, triggerLoader } = props
  try {
    setRequestStatus({ loading: !!triggerLoader, success: false, error: false })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "GET",
    }

    fetch(`${ETUBES_URL_PREFIX}/my_wish_list/${userId}`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({ loading: false, success: true, error: false })
        if (response.status_code === 200) {
          setWishlistItems(response.result)
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({ loading: false, success: false, error: true })
    console.log("--- Error while fetching Wishlist items:", error)
  }
}

export const updateVideoData = (props: {
  is_update: boolean
  videoId: string
  setRequestStatus: Function
  keys: Record<string, unknown>
}) => {
  const { is_update, videoId, setRequestStatus, keys } = props
  try {
    setRequestStatus({ loading: true, success: false, error: false })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        is_update,
        ...keys,
      }),
    }
    fetch(`${ETUBES_URL_PREFIX}/videos/${videoId}`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({ loading: false, success: true, error: false })
        if (response.status_code === 200) {
          // setWishlistItems(response.result);
          console.log("--- Successfully updated the keys: ", { ...keys })
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({ loading: false, success: false, error: true })
    console.log("--- Error while updating video data:", error)
  }
}

export const fetchOneVideo = (props: {
  videoId: string
  setRequestStatus: Function
  setSingleVideo?: Function
}) => {
  const { setSingleVideo, setRequestStatus, videoId } = props
  try {
    setRequestStatus({ loading: true, success: false, error: false })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN && TOKEN !== "" ? TOKEN : STATIC_TOKEN,
        "Content-type": "application/json",
      },
      method: "GET",
    }
    fetch(`${ETUBES_URL_PREFIX}/videos/${videoId}`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({ loading: false, success: true, error: false })
        if (response.status_code === 200 && setSingleVideo) {
          setSingleVideo(response.result)
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({ loading: false, success: false, error: true })
    console.log("--- Error while fetching video data of:", videoId)
  }
}

export const fetchVideoAndSet = (props: {
  video_id: string
  setRequestStatus: Function
  setState: Function
}) => {
  const { setState, setRequestStatus, video_id } = props
  try {
    setRequestStatus({ loading: true, success: false, error: false })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN && TOKEN !== "" ? TOKEN : STATIC_TOKEN,
        "Content-type": "application/json",
      },
      method: "GET",
    }
    fetch(`${ETUBES_URL_PREFIX}/videos/${video_id}`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({ loading: false, success: true, error: false })
        if (response.status_code === 200 && setState) {
          setState(response.result)
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({ loading: false, success: false, error: true })
    console.log("--- Error while fetching video data of:", video_id)
  }
}

export const fetchSearchHistory = (props: {
  setRequestStatus: Function
  setSearchHistory: Function
}) => {
  const { setSearchHistory, setRequestStatus } = props
  try {
    setRequestStatus({
      loading: false,
      success: false,
      error: false,
      buttonLoading: true,
    })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "GET",
    }
    fetch(`${ETUBES_URL_PREFIX}/search_history_video/`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({
          loading: false,
          success: true,
          error: false,
          buttonLoading: false,
        })
        if (response.status_code === 200) {
          setSearchHistory(response.result)
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({
      loading: false,
      success: false,
      error: true,
      buttonLoading: false,
    })
    console.log("--- Error while fetching search History")
  }
}

export const fetchVideoUserProfile = (props: {
  setRequestStatus: Function
  setVideoProfile: Function
}) => {
  const { setVideoProfile, setRequestStatus } = props
  try {
    setRequestStatus({
      loading: false,
      success: false,
      error: false,
      buttonLoading: true,
    })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "GET",
    }
    fetch(`${ETUBES_URL_PREFIX}/search_history_video/`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({
          loading: false,
          success: true,
          error: false,
          buttonLoading: false,
        })
        if (response.status_code === 200) {
          setVideoProfile(response.result)
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({
      loading: false,
      success: false,
      error: true,
      buttonLoading: false,
    })
    console.log("--- Error while fetching Video User Profile")
  }
}
export const USERNAME_EXISTS_CHECK_STRING = "user_name already exist"
