// import "./../pages/login/style.css"
// import backgroundImage from "./../assets/signup-background.svg"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { ToastContainer, toast } from "react-toastify"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
// import eyeIcon from "./../assets/eye-icon.svg"
// import { useLoginStore } from "../store/login"
import { changePassword } from "../../utils/util"
import { EyeIcon } from "@heroicons/react/24/outline"
import { USER_ID_NAME } from "../../utils/variables"
// import ReactGA from "react-ga"

// ReactGA.pageview(window.location.pathname + window.location.search)

type FormData = {
  // username: string;
  password: string
  confirmPassword: string
  oldPassword?: string
}

interface ForgotPasswordProps {
  title: string
  subTitle: string
  mode: string
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const navigate = useNavigate()
  // const { _id, email } = useLoginStore()
  // const [username, setUsername] = useState(email || '');
  const [showPassword, setShowPassword] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [password, setPassword] = useState("")
  const [oldPassword, setOldPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [changeMode, setMode] = useState(props.mode === "change")
  // const { setRequestStatus } = useLoginStore()
  const formSchema = Yup.object().shape({
    // username: Yup.string().email().required('Please enter your Username'),
    password: Yup.string()
      .required("Password is mandatory")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: Yup.string()
      .required("Please re-enter your password")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  })

  const changeModeformSchema = Yup.object().shape({
    // username: Yup.string().email().required('Please enter your Username'),
    password: Yup.string()
      .required("New Password is mandatory")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    oldPassword: Yup.string()
      .required("Current Password is mandatory")
      .min(8, "Password must be atleast 8 char long"),
    confirmPassword: Yup.string()
      .required("Please re-enter your password")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  })

  const formOptions = {
    resolver: yupResolver(changeMode ? changeModeformSchema : formSchema),
  }
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormData>(formOptions)
  const onSubmit = handleSubmit((data) => {
    const password = data.password
    const _id: any = localStorage.getItem(USER_ID_NAME)
    setValue("password", data.password)
    setValue("confirmPassword", data.confirmPassword)
    setValue("oldPassword", data.oldPassword)
    changePassword(props.mode, password, oldPassword, _id)
  })

  const onClickValidationHandler = () => {
    const errorValues = Object.values(errors)
    errorValues.map(
      (error) => error?.message !== "" && toast(error.message || "")
    )
  }

  return (
    <div className="min-h-screen pt-12 text-white flex justify-center">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="fixed inset-0">
        <video
          className="w-full object-cover rounded"
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
          poster={"./Images/loginbg.mp4"}
          src={"./Images/loginbg.mp4"}
        />
      </div>
      <div className="max-w-screen-xl m-0 z-50 sm:m-10 rounded-3xl  shadow  flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-[48%] p-6 sm:p-12">
          <div>
            <img src="./Images/Logo/logo.png" className="w-60 mx-auto" />
          </div>
          <div className="mt-12 flex flex-col items-center">
            <h1 className="text-2xl xl:text-3xl font-extrabold">
              Forgot Password
            </h1>
            <div className="w-full flex-1 mt-24">
              <form onSubmit={onSubmit} className="login-form flex flex-col">
                {props.mode === "change" ? (
                  <div className="input-wrapper  mb-6">
                    <div className="relative">
                      <div className="absolute right-0 mt-[10px]">
                        {/* <img
                      role="button"
                      className="mr-6 float-right"
                      src={EyeIcon}
                      alt="eye icon"
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                    /> */}
                        <EyeIcon />
                      </div>
                      <input
                        value={oldPassword}
                        {...register("oldPassword", {
                          onChange: (e) => setOldPassword(e.target.value),
                        })}
                        type={showCurrentPassword ? "text" : "password"}
                        id="old_password"
                        className="w-full bg-black focus:bg-black  px-6 py-4 rounded-lg font-medium  border-none  placeholder-gray-500 text-sm focus:outline-none"
                        placeholder="Enter current password"
                      />
                    </div>
                  </div>
                ) : null}
                <div className="input-wrapper mb-6">
                  <div className="relative">
                    <div className="absolute right-0 mt-[10px]">
                      {/* <img
                    role="button"
                    className="mr-6 float-right"
                    src={eyeIcon}
                    alt="eye icon"
                    onClick={() => setShowPassword(!showPassword)}
                  /> */}
                      <EyeIcon />
                    </div>
                    <input
                      value={password}
                      {...register("password", {
                        onChange: (e) => setPassword(e.target.value),
                      })}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="w-full bg-black focus:bg-black  px-6 py-4 rounded-lg font-medium  border-none  placeholder-gray-500 text-sm focus:outline-none"
                      placeholder="Enter New  password"
                    />
                  </div>
                </div>
                <div className="input-wrapper  mb-6">
                  <div className="relative">
                    <div className="absolute right-0 mt-[10px]">
                      {/* <img
                    role="button"
                    className="mr-6 float-right"
                    src={eyeIcon}
                    alt="eye icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  /> */}
                      <EyeIcon />
                    </div>
                    <input
                      value={confirmPassword}
                      {...register("confirmPassword", {
                        onChange: (e) => setConfirmPassword(e.target.value),
                      })}
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirmPassword"
                      className="w-full bg-black focus:bg-black  px-6 py-4 rounded-lg font-medium  border-none  placeholder-gray-500 text-sm focus:outline-none"
                      placeholder="Confirm password"
                    />
                  </div>
                </div>
                <button
                  onClick={onClickValidationHandler}
                  className="px-6 py-3 bg-btn_bgcolor3 rounded-xl"
                >
                  Continue
                </button>
                {/* <ButtonWithLoader
              onClickHandler={onClickValidationHandler}
              text="continue"
            /> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
