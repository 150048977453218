// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.large_circle,
.circle {
  background: linear-gradient(180deg, #5e44a2 0%, #7040f2 100%);
  width: 130px;
  height: 130px;
  filter: blur(60px);
  border-radius: 50px;
  position: fixed;
  top: -80px;
  left: -100px;
  pointer-events: none;
  /* z-index: 999999999999 !important; */
  transition: transform 0.1s;
}

.circle {
}
`, "",{"version":3,"sources":["webpack://./src/components/Cursor.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;;EAEE,6DAA6D;EAC7D,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,UAAU;EACV,YAAY;EACZ,oBAAoB;EACpB,sCAAsC;EACtC,0BAA0B;AAC5B;;AAEA;AACA","sourcesContent":["/** @format */\n\n.large_circle,\n.circle {\n  background: linear-gradient(180deg, #5e44a2 0%, #7040f2 100%);\n  width: 130px;\n  height: 130px;\n  filter: blur(60px);\n  border-radius: 50px;\n  position: fixed;\n  top: -80px;\n  left: -100px;\n  pointer-events: none;\n  /* z-index: 999999999999 !important; */\n  transition: transform 0.1s;\n}\n\n.circle {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
