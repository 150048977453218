import {
  navLinkListType,
  socialMediaType,
  tDropdown,
  videoProps,
} from "../../utils/genericTypes";

import image_1 from "../../assets/GalleryThumbnails/1.png";
import image_2 from "../../assets/GalleryThumbnails/2.png";
import image_3 from "../../assets/GalleryThumbnails/3.png";
import image_4 from "../../assets/GalleryThumbnails/4.png";
import image_5 from "../../assets/GalleryThumbnails/5.png";
import image_6 from "../../assets/GalleryThumbnails/6.png";
import image_7 from "../../assets/GalleryThumbnails/7.png";
import image_8 from "../../assets/GalleryThumbnails/8.png";
import icon_videos from "./../../assets/nav-video.svg";
import {
  Bell,
  Briefcase,
  CreditCard,
  DollarSign,
  Heart,
  Package,
  Repeat,
  ShoppingCart,
  TrendingUp,
  Tv,
  User,
  Video,
  Youtube,
} from "react-feather";
import icon_my_cart from "./../../assets/nav-cart.svg";
import my_upload from "./../../assets/upload.png";
import icon_profile from "./../../assets/icon-profile.svg";

import PinterestLogo from "../../assets/Pinterest.svg";
import FacebookLogo from "../../assets/facebook.svg";
import InstagramLogo from "../../assets/Instagram.svg";
// import LinkedInLogo from "../../assets/LinkedIn.svg"
import TwitterLogo from "../../assets/Twitter.svg";
import YouTubeLogo from "../../assets/YouTube.svg";
import banner_one from "./../../assets/playlistImages/maldives_banner.svg";
import banner_two from "./../../assets/playlistImages/maldives_2_banner.svg";
import banner_three from "./../../assets/playlistImages/maldives_3_banner.svg";
import banner_4x from "./../../assets/playlistImages/maldives_4x.svg";
import authorImage from "./../../assets/GalleryThumbnails/author.png";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/solid";

export const authorData = {
  src: authorImage,
  name: "Anton lee",
};

export const videoList: videoProps[] = [
  {
    src: "https://s3-alpha-sig.figma.com/img/9af4/15e6/7946cde3d35db070fe4af1d7355abc7b?Expires=1670803200&Signature=MJfZWzVlsONxjhcRpu-tLrJjpzv3bBA--aorIObCVY0mxNoVWI01fXdXjv~9ibmRA3XSD~N4n9zo58fvGl5813HWQ-VNjb0n3GBBNqwi7mkICnZ3~nOvH8ehOZlXfSHEH77gwEblm7ZmQJqpCBR8q-nhVupA5hLMPYM1OzvaZiV61dXBlDdeN2mD14NIKTlH6VXF2FjGT0HnhS5ETzr~2qT0Rj5bHzlt-J2WlHHEckoRJiy-KWEflbE~iXhksdqErgZhVCz0JFqHx-nj8gPXrXxAqputyYmKQETGEYjVYKBW5zC5aXG-OOVIdbVrtutlIhOwSw1VIHu0gkVEhjzrxg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    id: "7946cde3d35db070fe4af1d7355abc7b",
    length: "1:22",
    count: "15.2K",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/7168/fe7f/c317036c17b36b5cdd6c76b620f40fa6?Expires=1670803200&Signature=FI-GmekWv5wzsFMeeIs2HAHRqpkbZ~8PmUvYBbjWNrpY2hgamcXA3hRyuhkdkFHWlBVgwW1FsUEfSeYXJ47vWeFYWQfbshmpQ8J7TQFXm3yulablyscSbZxGQ2x2GKN4pHAVjqQdc8aUt~~W3zlvSLThYROCHG5lqD72Yt4w0XpH~plH6KBRlbnepFGcy8KTAAX6Izz0mIr6VIbDBSeh~6iMQnVjm1djVaYKVJ2FLck166jaqnscAEEXNGz5EIo~AH9PP2ObZ02UXNfIqE~Hw7lsxwp2mNIuO7a0C-P82nb~r9sQldWDv~oDzEsiFnPgT-ibgLef~w~b-Nif5qj~8w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    id: "c317036c17b36b5cdd6c76b620f40fa6",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/635d/3541/e174575f02499c66161d998047b2c953?Expires=1670803200&Signature=aFi5qXs~yJPkca3MOjsVbqLFOmv4zI9dccYJ6xVyDUce2BHsgZBe4nItgGZh0QFlptqhdnO5C99~jTHNWMR9Ei-p3U-w3IJC7NQ-CITyYPUbc1YbF8i45sOH-qxG7OFcPq09j3ECAu5mv2kSalmbknVohmhCO~~qUbmGZnc2bW2RVFHbiU-CbEohr8zw~uFKsV8J6w8D~SXlTNHc6ARnM6gWOl967ZzzlfRNj-2ZGmGX9PPKTAB7aoioIzTYeHWYhLVpJTFGL-AdgRI~dk2n2eoxQMi78ffC8mDm1w-jbvEJSAfR3arcEivJbJIW1rBxyd8WM32AK-gkNkKwPLftVw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    id: "e174575f02499c66161d998047b2c953",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/a7fc/678c/ddd29b464733e5e2ba057369baa2be01?Expires=1670803200&Signature=XFVJD1VeAM25Orf0ZzRCM7GJnHHQyb-khANkR6qQfSzZ50Z4dkL0qyB6L6cEjv9NPEcJTLp9V0LmNMyDhN-Jpw4NcjBfVAp3PlIHFVM8rZL-x3-F9Xo97lZAR89RtB~KGcs38J86KLx23Vs71w2FFPihiX~jibOxORVjQMmPkLQjnYP1Mq8~UeqIhSRaSugp9QHXzIt-wF~WGic8wekQQcoDQV1KlsubXiwW12SBtA6SJ3znKnvBQr80X4rQ-7ruhXDHK83BgWEQVbi9xVrDXecoccTx6HCTpsz2uWKNEoCzX0KkpAg7Gj-P069~9NjFQMiPDUqNnhHA9jwWlFJHXg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    id: "ddd29b464733e5e2ba057369baa2be01",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/9e17/f48e/c6d272dc9a384152b0af486b60d0d939?Expires=1670803200&Signature=H8k-CPx~19bap9cZxNXfluU-EK4VHU2pNfxWzBNwt6NrkQOgmj9hVyIvz7MKswEfsVfzMvnscFJfmiBTePS--xWzte0B2JKprzhJU-sZ1zdYKjCNSU-tbRvgpKKcCRaAsCf08UwTBxLclsjQbGfdil-WYgKG22Zx1W5nk5Xy8B1btELX8bBdiIoqc1qYWkm8Ke6F3jWDreVyKY4UWWNVC5SsIfo2tdvsjoBHKyXhR62mfqIKWT98XJhqEuFZbKC0R0z8ieSDCo52zcuaucan-LrxH3wlpAfzFg9rktDaPEd2sL92rSNfIuntCyK~WOOf1UANyxtsxdTRQ~c9crUjlA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    id: "c6d272dc9a384152b0af486b60d0d939",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/0251/5655/2adb2f1f8e3e0e56342b253f84e6917d?Expires=1670803200&Signature=U~-JjGH-DUgagN4fmRKcbf~REAEpuYx3ubUVWu5rYiiyOQ~S97wIV5VUhPNG18R5M-PZ~qA30ZlsWUXCNUCP81UmBNwoULIA5b6MsYZ9vD8pJXzGRnVRKAewXz4LKxfAfeP9hLJ7hGTYUFAJiuBUUAH3m7YzzVrn6RhtcswRa3xt8ZTEi5Zm4MtzirkQo7OHDTgneXdToCnhDKBeYfaV4xxeagR0eCu92H-D3THa9NKZ603-ggAW6ZzCR55kEIJuJ29dPcbpEnq4qwdv15YST-g1eR9miQVVqf3mmA~Z-BttXd-PagAe4ovaWjLu640YJ8c3geLRv4J~Q1Nnik9yzQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    id: "2adb2f1f8e3e0e56342b253f84e6917d",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/c495/a808/e77da48ed223f00814b93090b1ba0f46?Expires=1670803200&Signature=VcZwUWXNau2HG~ld1QothpBRgefTt3XSTSs8ka9UklW25cMSFgfE1ncoA2B-2b9ZRP0SM1qnH-6WMLeCfA2Q78mA8ik-9a84cJnWcG-05NGfaEqgPTzJu0db3GRJrKRfXyFJK2DVcYzLCmZsQFFByZVtjjTlz-eqkBYz-5No9yz-9gKxjsdWgmxjZU1pRqc7PHJ~z-jS08LsbsyJpnbVxcLZqBx3WsaU8ZKcvpjyclm2zb~lJ-mJVw4yLb4CuaHE1LDbaB0iV3HgaqxxuhEnaNsOTI6rff0wjL2u8gRr6sthIpX-3fcZzLulLhTOusm1q9yNoXZ628qvXx861-aGww__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/5990/d31c/d3427d28f6a9d826020d53973d98b943?Expires=1670803200&Signature=bw-9sr2T2~t8g4GeQkgi9HQaDDDmp7iTSiUC9fXx6bUTp-SVWr-1yGWxHFL8KG8a7U4bmTmB1fCy5Ov4GPLZ3G02OE~J2kF7wYupsfACD4enzgqF~3NCN5A22Ie8EOkd6VpJ~OA5~FuAQbc-wfwkIl2dAz5unwukKSKSUYX1K7vkbTdiMaJPuIdFgXDrcEGmN69psI3nYPn~S3nulLIg7yTF6r6ukMRdsqsEA4OFoE67pnPXoKLIR5wVUUM24cJJ1PVR3T4Ik5vg2AWdCTxFO4gD847-YHfDBvxy7Q6O9PFzUOfj~CeJcG6qmWLOyV~w4n7YymYsluM098GfGp0Q8w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
  },
];

export const navLinkList: navLinkListType = [
  {
    name: "Wishlist",
    src: "",
    icon: Heart,
    type: "icon_component",
    to: "/wishlist",
    userState: "logged_in",
  },
  {
    name: "My Cart",
    src: icon_my_cart,
    type: "icon_component",
    icon: ShoppingCart,
    to: "/my-cart",
    userState: "logged_in",
  },
  {
    name: "Upload Video",
    src: "",
    type: "button",
    to: "/upload-video",
    userState: "logged_in",
  },
  {
    name: "Profile",
    src: icon_profile,
    type: "profile",
    to: "",
    userState: "logged_in",
  },
  // {
  //   name: "Notifications",
  //   src: "",
  //   icon: Bell,
  //   type: "icon",
  //   to: "/notifications",
  //   userState: "logged_in",
  // },
  {
    name: "featured",
    src: "",
    to: "/#featured",
    userState: "logged_out",
  },
  {
    name: "trending",
    src: "",
    to: "/#trending",
    userState: "logged_out",
  },
  {
    name: "about",
    src: "",
    to: "/about-us",
    userState: "logged_out",
  },
  {
    name: "Sell Video",
    src: "",
    type: "button",
    to: "/login",
    userState: "logged_out",
  },
];
export const navTicketList: navLinkListType = [
  {
    name: "My Post",
    src: "",
    // icon: my_upload,
    // type: "icon_component",
    to: "/live-stream-event/my-posted",
    userState: "logged_in",
  },
  {
    name: "Post Your Event",
    src: "",
    type: "button",
    to: "/ticket/booking/create-ticket",
    userState: "logged_in",
  },
  {
    name: "Profile",
    src: icon_profile,
    type: "profile",
    to: "",
    userState: "logged_in",
  },
  // {
  //   name: "Notifications",
  //   src: "",
  //   icon: Bell,
  //   type: "icon",
  //   to: "/notifications",
  //   userState: "logged_in",
  // },
  {
    name: "featured",
    src: "",
    to: "/#featured",
    userState: "logged_out",
  },
  {
    name: "trending",
    src: "",
    to: "/#trending",
    userState: "logged_out",
  },
  {
    name: "about",
    src: "",
    to: "/about-us",
    userState: "logged_out",
  },
  {
    name: "Sign Up",
    src: "",
    type: "button",
    to: "/signup",
    userState: "logged_out",
  },
  {
    name: "Login",
    src: "",
    type: "button",
    to: "/login",
    userState: "logged_out",
    buttonType: "secondary",
  },
];
export const navWantedList: navLinkListType = [
  {
    name: "My Post",
    src: "",
    // icon: my_upload,
    // type: "icon_component",
    to: "/wanted/my-post",
    userState: "logged_in",
  },
  {
    name: "My Applied",
    src: "",
    // icon: my_upload,
    // type: "icon_component",
    to: "/wanted/my-applied",
    userState: "logged_in",
  },
  {
    name: "Wanted Video",
    src: "",
    type: "button",
    to: "/wanted/video/create",
    userState: "logged_in",
  },
  {
    name: "Profile",
    src: icon_profile,
    type: "profile",
    to: "",
    userState: "logged_in",
  },
  // {
  //   name: "Notifications",
  //   src: "",
  //   icon: Bell,
  //   type: "icon",
  //   to: "/notifications",
  //   userState: "logged_in",
  // },
  {
    name: "featured",
    src: "",
    to: "/#featured",
    userState: "logged_out",
  },
  {
    name: "trending",
    src: "",
    to: "/#trending",
    userState: "logged_out",
  },
  {
    name: "about",
    src: "",
    to: "/about-us",
    userState: "logged_out",
  },
  {
    name: "Sign Up",
    src: "",
    type: "button",
    to: "/signup",
    userState: "logged_out",
  },
  {
    name: "Login",
    src: "",
    type: "button",
    to: "/login",
    userState: "logged_out",
    buttonType: "secondary",
  },
];

export const sideNavLinkList: navLinkListType = [
  {
    name: "Upload Video",
    key: "",
    src: "",
    type: "button",
    to: "/upload-video",
    userState: "logged_in",
  },
  {
    name: "Sign Up",
    src: "",
    type: "button",
    to: "/signup",
    userState: "logged_out",
  },
  {
    name: "Login",
    src: "",
    type: "button",
    to: "/login",
    userState: "logged_out",
    buttonType: "secondary",
  },
  {
    name: "My Profile",
    src: "",
    icon: User,
    to: "/profile",
    userState: "logged_in",
  },
  {
    name: "My Videos",
    src: "",
    icon: Video,
    to: "/profile?id=My Videos",
    userState: "logged_in",
  },
  {
    name: "My Wishlist",
    src: "",
    icon: Heart,
    to: "/wishlist",
    userState: "logged_in",
  },
  {
    name: "My Cart",
    src: "",
    icon: ShoppingCart,
    to: "/my-cart",
    userState: "logged_in",
  },
  {
    name: "My Orders",
    src: "",
    icon: Package,
    to: "/orders",
    userState: "logged_in",
  },
  {
    name: "Sponsorship",
    src: "",
    icon: CreditCard,
    to: "/sponsorship",
    userState: "logged_in",
  },
  {
    name: "My Wallet",
    src: "",
    icon: CreditCard,
    to: "/profile?id=My Wallet",
    userState: "logged_in",
  },
  {
    name: "features",
    src: "",
    to: "/",
    userState: "logged_out",
  },
  // {
  //   name: "resources",
  //   src: "",
  //   to: "/resources",
  //   userState: "logged_out",
  // },
  {
    name: "about",
    src: "",
    to: "/about-us",
    userState: "logged_out",
  },
  {
    name: "Video Streaming",
    src: "",
    icon: Tv,
    to: "/ticket/booking",
    userState: "",
  },
  {
    name: "Wanted Videos",
    src: "",
    icon: TrendingUp,
    to: "/wanted/video",
    userState: "",
  },
  {
    name: "We are Buying Video",
    src: "",
    icon: Video,
    to: "/contact-us",
    userState: "",
  },
  // {
  //   name: "Careers",
  //   src: "",
  //   icon: Briefcase,
  //   to: "/wanted/video",
  //   userState: "",
  // },
];

const share_text = encodeURIComponent(
  "Follow and checkout Zynoflix! The best video buying and selling platform!"
);
const hashtags = "";
const url = "http://13.234.75.148:7000";
const twitter_SHARE = `https://twitter.com/intent/tweet?url=${"http://13.234.75.148:7000"}&text=${share_text}`;
const linkedin =
  "https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png";
const pinterest = "http://pinterest.com/pin/create/button/?url=" + url;
export const socialMediaList: socialMediaType[] = [
  {
    img: YouTubeLogo,
    name: "Youtube",
    url: "https://youtube.com/@ZynoFlix",
  },
  {
    img: TwitterLogo,
    name: "Twitter",
    url: "https://twitter.com/zynoflix?t=MreA7FFUWiNJJ9Jof7b8MQ&s=09",
  },
  {
    img: linkedin,
    name: "LinkedIn",
    url: "https://www.linkedin.com/company/zynoflix/",
  },
  {
    img: FacebookLogo,
    name: "FaceBook",
    url: "https://www.facebook.com/zynoflix?mibextid=LQQJ4d",
  },
  {
    img: InstagramLogo,
    name: "Instagram",
    url: "https://instagram.com/zynoflix__?igshid=MzNlNGNkZWQ4Mg==",
  },
];

export const footerList: {
  title: string;
  services: { name: string; to: string };
}[] = [
  {
    title: "About Us",
    services: { name: "about_us", to: "/about-us" },
  },
  {
    title: "Terms & Conditions",
    services: { name: "terms_and_conditions", to: "terms-and-conditions" },
  },
  {
    title: "Privacy policy",
    services: { name: "privacy_policy", to: "privacypolicy" },
  },
  {
    title: "Refund Policy",
    services: { name: "refund_policy", to: "refundpolicy" },
  },
  {
    title: "Copyrights",
    services: { name: "copyrights", to: "copyrights" },
  },
  {
    title: "Contact Us",
    services: { name: "contact_us", to: "contact-us" },
  },
  {
    title: "Advertise with us",
    services: { name: "advertise_with_us", to: "upload-ad-video" },
  },
];

export const serviceList: {
  title: string;
  services: { name: string; to: string }[];
}[] = [
  {
    title: "Product",
    services: [
      { name: "ZynoFlix", to: "" },
      { name: "Videos", to: "videos" },
      { name: "Dashboard", to: "dashboard" },
    ],
  },
  {
    title: "Features",
    services: [
      { name: "Nature", to: "/videos?feature=nature" },
      { name: "City", to: "/videos?feature=city" },
      { name: "Wildlife", to: "/videos?feature=wildlife" },
      { name: "Cars", to: "/videos?feature=cars" },
      { name: "Lifestyle", to: "/videos?feature=lifestyle" },
      { name: "Ocean", to: "/videos?feature=ocean" },
    ],
  },
  {
    title: "Profile",
    services: [
      { name: "Account", to: "account" },
      { name: "Payments", to: "payments" },
      { name: "Subscription", to: "subscription" },
    ],
  },
  {
    title: "Resources",
    services: [
      { name: "My Cart", to: "cart" },
      { name: "Wishlist", to: "wishlist" },
      { name: "Purchases", to: "purchases" },
      { name: "Dashboard", to: "dashboard" },
      { name: "FAQ", to: "faq" },
    ],
  },
  {
    title: "Legal",
    services: [
      { name: "Terms & Conditions", to: "terms-and-conditions" },
      { name: "Privacy policy", to: "privacypolicy" },
      { name: "Refund Policy", to: "refundpolicy" },
      { name: "Copyrights", to: "copyrights" },
      { name: "Contact Us", to: "contact-us" },
    ],
  },
];

export const playList = [
  {
    style: {
      background: `linear-gradient(270deg, #131927 1.42%, rgba(19, 25, 39, 0) 52.94%), url(${banner_one}) no-repeat`,
    },
    style_sm: {
      background: `linear-gradient(360deg, #131927 1.42%, rgba(19, 25, 39, 0) 52.94%), url(${banner_4x}) no-repeat`,
    },
    key: "playlist-1",
  },
  {
    style: {
      background: `linear-gradient(270deg, #131927 1.42%, rgba(19, 25, 39, 0) 52.94%), url(${banner_two}) no-repeat`,
    },
    style_sm: {
      background: `linear-gradient(360deg, #131927 1.42%, rgba(19, 25, 39, 0) 52.94%), url(${banner_two}) no-repeat`,
    },
    key: "playlist-2",
  },
  {
    style: {
      background: `linear-gradient(270deg, #131927 1.42%, rgba(19, 25, 39, 0) 52.94%), url(${banner_three}) no-repeat`,
    },
    style_sm: {
      background: `linear-gradient(360deg, #131927 1.42%, rgba(19, 25, 39, 0) 52.94%), url(${banner_three}) no-repeat`,
    },
    key: "playlist-3",
  },
];

export const cartItemsList = [
  {
    src: "https://s3-alpha-sig.figma.com/img/9af4/15e6/7946cde3d35db070fe4af1d7355abc7b?Expires=1670803200&Signature=MJfZWzVlsONxjhcRpu-tLrJjpzv3bBA--aorIObCVY0mxNoVWI01fXdXjv~9ibmRA3XSD~N4n9zo58fvGl5813HWQ-VNjb0n3GBBNqwi7mkICnZ3~nOvH8ehOZlXfSHEH77gwEblm7ZmQJqpCBR8q-nhVupA5hLMPYM1OzvaZiV61dXBlDdeN2mD14NIKTlH6VXF2FjGT0HnhS5ETzr~2qT0Rj5bHzlt-J2WlHHEckoRJiy-KWEflbE~iXhksdqErgZhVCz0JFqHx-nj8gPXrXxAqputyYmKQETGEYjVYKBW5zC5aXG-OOVIdbVrtutlIhOwSw1VIHu0gkVEhjzrxg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "Beautiful City lights blue tint video drone shot (Full HD)",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "1",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/7168/fe7f/c317036c17b36b5cdd6c76b620f40fa6?Expires=1670803200&Signature=FI-GmekWv5wzsFMeeIs2HAHRqpkbZ~8PmUvYBbjWNrpY2hgamcXA3hRyuhkdkFHWlBVgwW1FsUEfSeYXJ47vWeFYWQfbshmpQ8J7TQFXm3yulablyscSbZxGQ2x2GKN4pHAVjqQdc8aUt~~W3zlvSLThYROCHG5lqD72Yt4w0XpH~plH6KBRlbnepFGcy8KTAAX6Izz0mIr6VIbDBSeh~6iMQnVjm1djVaYKVJ2FLck166jaqnscAEEXNGz5EIo~AH9PP2ObZ02UXNfIqE~Hw7lsxwp2mNIuO7a0C-P82nb~r9sQldWDv~oDzEsiFnPgT-ibgLef~w~b-Nif5qj~8w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "2",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/635d/3541/e174575f02499c66161d998047b2c953?Expires=1670803200&Signature=aFi5qXs~yJPkca3MOjsVbqLFOmv4zI9dccYJ6xVyDUce2BHsgZBe4nItgGZh0QFlptqhdnO5C99~jTHNWMR9Ei-p3U-w3IJC7NQ-CITyYPUbc1YbF8i45sOH-qxG7OFcPq09j3ECAu5mv2kSalmbknVohmhCO~~qUbmGZnc2bW2RVFHbiU-CbEohr8zw~uFKsV8J6w8D~SXlTNHc6ARnM6gWOl967ZzzlfRNj-2ZGmGX9PPKTAB7aoioIzTYeHWYhLVpJTFGL-AdgRI~dk2n2eoxQMi78ffC8mDm1w-jbvEJSAfR3arcEivJbJIW1rBxyd8WM32AK-gkNkKwPLftVw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "3",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/a7fc/678c/ddd29b464733e5e2ba057369baa2be01?Expires=1670803200&Signature=XFVJD1VeAM25Orf0ZzRCM7GJnHHQyb-khANkR6qQfSzZ50Z4dkL0qyB6L6cEjv9NPEcJTLp9V0LmNMyDhN-Jpw4NcjBfVAp3PlIHFVM8rZL-x3-F9Xo97lZAR89RtB~KGcs38J86KLx23Vs71w2FFPihiX~jibOxORVjQMmPkLQjnYP1Mq8~UeqIhSRaSugp9QHXzIt-wF~WGic8wekQQcoDQV1KlsubXiwW12SBtA6SJ3znKnvBQr80X4rQ-7ruhXDHK83BgWEQVbi9xVrDXecoccTx6HCTpsz2uWKNEoCzX0KkpAg7Gj-P069~9NjFQMiPDUqNnhHA9jwWlFJHXg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "4",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/9e17/f48e/c6d272dc9a384152b0af486b60d0d939?Expires=1670803200&Signature=H8k-CPx~19bap9cZxNXfluU-EK4VHU2pNfxWzBNwt6NrkQOgmj9hVyIvz7MKswEfsVfzMvnscFJfmiBTePS--xWzte0B2JKprzhJU-sZ1zdYKjCNSU-tbRvgpKKcCRaAsCf08UwTBxLclsjQbGfdil-WYgKG22Zx1W5nk5Xy8B1btELX8bBdiIoqc1qYWkm8Ke6F3jWDreVyKY4UWWNVC5SsIfo2tdvsjoBHKyXhR62mfqIKWT98XJhqEuFZbKC0R0z8ieSDCo52zcuaucan-LrxH3wlpAfzFg9rktDaPEd2sL92rSNfIuntCyK~WOOf1UANyxtsxdTRQ~c9crUjlA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "5",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/0251/5655/2adb2f1f8e3e0e56342b253f84e6917d?Expires=1670803200&Signature=U~-JjGH-DUgagN4fmRKcbf~REAEpuYx3ubUVWu5rYiiyOQ~S97wIV5VUhPNG18R5M-PZ~qA30ZlsWUXCNUCP81UmBNwoULIA5b6MsYZ9vD8pJXzGRnVRKAewXz4LKxfAfeP9hLJ7hGTYUFAJiuBUUAH3m7YzzVrn6RhtcswRa3xt8ZTEi5Zm4MtzirkQo7OHDTgneXdToCnhDKBeYfaV4xxeagR0eCu92H-D3THa9NKZ603-ggAW6ZzCR55kEIJuJ29dPcbpEnq4qwdv15YST-g1eR9miQVVqf3mmA~Z-BttXd-PagAe4ovaWjLu640YJ8c3geLRv4J~Q1Nnik9yzQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "6",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/c495/a808/e77da48ed223f00814b93090b1ba0f46?Expires=1670803200&Signature=VcZwUWXNau2HG~ld1QothpBRgefTt3XSTSs8ka9UklW25cMSFgfE1ncoA2B-2b9ZRP0SM1qnH-6WMLeCfA2Q78mA8ik-9a84cJnWcG-05NGfaEqgPTzJu0db3GRJrKRfXyFJK2DVcYzLCmZsQFFByZVtjjTlz-eqkBYz-5No9yz-9gKxjsdWgmxjZU1pRqc7PHJ~z-jS08LsbsyJpnbVxcLZqBx3WsaU8ZKcvpjyclm2zb~lJ-mJVw4yLb4CuaHE1LDbaB0iV3HgaqxxuhEnaNsOTI6rff0wjL2u8gRr6sthIpX-3fcZzLulLhTOusm1q9yNoXZ628qvXx861-aGww__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "7",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/5990/d31c/d3427d28f6a9d826020d53973d98b943?Expires=1670803200&Signature=bw-9sr2T2~t8g4GeQkgi9HQaDDDmp7iTSiUC9fXx6bUTp-SVWr-1yGWxHFL8KG8a7U4bmTmB1fCy5Ov4GPLZ3G02OE~J2kF7wYupsfACD4enzgqF~3NCN5A22Ie8EOkd6VpJ~OA5~FuAQbc-wfwkIl2dAz5unwukKSKSUYX1K7vkbTdiMaJPuIdFgXDrcEGmN69psI3nYPn~S3nulLIg7yTF6r6ukMRdsqsEA4OFoE67pnPXoKLIR5wVUUM24cJJ1PVR3T4Ik5vg2AWdCTxFO4gD847-YHfDBvxy7Q6O9PFzUOfj~CeJcG6qmWLOyV~w4n7YymYsluM098GfGp0Q8w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "8",
  },
];

export const transactionsList = [
  {
    src: "https://s3-alpha-sig.figma.com/img/9af4/15e6/7946cde3d35db070fe4af1d7355abc7b?Expires=1670803200&Signature=MJfZWzVlsONxjhcRpu-tLrJjpzv3bBA--aorIObCVY0mxNoVWI01fXdXjv~9ibmRA3XSD~N4n9zo58fvGl5813HWQ-VNjb0n3GBBNqwi7mkICnZ3~nOvH8ehOZlXfSHEH77gwEblm7ZmQJqpCBR8q-nhVupA5hLMPYM1OzvaZiV61dXBlDdeN2mD14NIKTlH6VXF2FjGT0HnhS5ETzr~2qT0Rj5bHzlt-J2WlHHEckoRJiy-KWEflbE~iXhksdqErgZhVCz0JFqHx-nj8gPXrXxAqputyYmKQETGEYjVYKBW5zC5aXG-OOVIdbVrtutlIhOwSw1VIHu0gkVEhjzrxg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "Beautiful City lights blue tint video drone shot (Full HD)",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "1",
    operand: "-",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/7168/fe7f/c317036c17b36b5cdd6c76b620f40fa6?Expires=1670803200&Signature=FI-GmekWv5wzsFMeeIs2HAHRqpkbZ~8PmUvYBbjWNrpY2hgamcXA3hRyuhkdkFHWlBVgwW1FsUEfSeYXJ47vWeFYWQfbshmpQ8J7TQFXm3yulablyscSbZxGQ2x2GKN4pHAVjqQdc8aUt~~W3zlvSLThYROCHG5lqD72Yt4w0XpH~plH6KBRlbnepFGcy8KTAAX6Izz0mIr6VIbDBSeh~6iMQnVjm1djVaYKVJ2FLck166jaqnscAEEXNGz5EIo~AH9PP2ObZ02UXNfIqE~Hw7lsxwp2mNIuO7a0C-P82nb~r9sQldWDv~oDzEsiFnPgT-ibgLef~w~b-Nif5qj~8w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "2",
    operand: "+",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/635d/3541/e174575f02499c66161d998047b2c953?Expires=1670803200&Signature=aFi5qXs~yJPkca3MOjsVbqLFOmv4zI9dccYJ6xVyDUce2BHsgZBe4nItgGZh0QFlptqhdnO5C99~jTHNWMR9Ei-p3U-w3IJC7NQ-CITyYPUbc1YbF8i45sOH-qxG7OFcPq09j3ECAu5mv2kSalmbknVohmhCO~~qUbmGZnc2bW2RVFHbiU-CbEohr8zw~uFKsV8J6w8D~SXlTNHc6ARnM6gWOl967ZzzlfRNj-2ZGmGX9PPKTAB7aoioIzTYeHWYhLVpJTFGL-AdgRI~dk2n2eoxQMi78ffC8mDm1w-jbvEJSAfR3arcEivJbJIW1rBxyd8WM32AK-gkNkKwPLftVw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "3",
    operand: "-",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/a7fc/678c/ddd29b464733e5e2ba057369baa2be01?Expires=1670803200&Signature=XFVJD1VeAM25Orf0ZzRCM7GJnHHQyb-khANkR6qQfSzZ50Z4dkL0qyB6L6cEjv9NPEcJTLp9V0LmNMyDhN-Jpw4NcjBfVAp3PlIHFVM8rZL-x3-F9Xo97lZAR89RtB~KGcs38J86KLx23Vs71w2FFPihiX~jibOxORVjQMmPkLQjnYP1Mq8~UeqIhSRaSugp9QHXzIt-wF~WGic8wekQQcoDQV1KlsubXiwW12SBtA6SJ3znKnvBQr80X4rQ-7ruhXDHK83BgWEQVbi9xVrDXecoccTx6HCTpsz2uWKNEoCzX0KkpAg7Gj-P069~9NjFQMiPDUqNnhHA9jwWlFJHXg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "4",
    operand: "-",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/9e17/f48e/c6d272dc9a384152b0af486b60d0d939?Expires=1670803200&Signature=H8k-CPx~19bap9cZxNXfluU-EK4VHU2pNfxWzBNwt6NrkQOgmj9hVyIvz7MKswEfsVfzMvnscFJfmiBTePS--xWzte0B2JKprzhJU-sZ1zdYKjCNSU-tbRvgpKKcCRaAsCf08UwTBxLclsjQbGfdil-WYgKG22Zx1W5nk5Xy8B1btELX8bBdiIoqc1qYWkm8Ke6F3jWDreVyKY4UWWNVC5SsIfo2tdvsjoBHKyXhR62mfqIKWT98XJhqEuFZbKC0R0z8ieSDCo52zcuaucan-LrxH3wlpAfzFg9rktDaPEd2sL92rSNfIuntCyK~WOOf1UANyxtsxdTRQ~c9crUjlA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "5",
    operand: "-",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/0251/5655/2adb2f1f8e3e0e56342b253f84e6917d?Expires=1670803200&Signature=U~-JjGH-DUgagN4fmRKcbf~REAEpuYx3ubUVWu5rYiiyOQ~S97wIV5VUhPNG18R5M-PZ~qA30ZlsWUXCNUCP81UmBNwoULIA5b6MsYZ9vD8pJXzGRnVRKAewXz4LKxfAfeP9hLJ7hGTYUFAJiuBUUAH3m7YzzVrn6RhtcswRa3xt8ZTEi5Zm4MtzirkQo7OHDTgneXdToCnhDKBeYfaV4xxeagR0eCu92H-D3THa9NKZ603-ggAW6ZzCR55kEIJuJ29dPcbpEnq4qwdv15YST-g1eR9miQVVqf3mmA~Z-BttXd-PagAe4ovaWjLu640YJ8c3geLRv4J~Q1Nnik9yzQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "6",
    operand: "+",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/c495/a808/e77da48ed223f00814b93090b1ba0f46?Expires=1670803200&Signature=VcZwUWXNau2HG~ld1QothpBRgefTt3XSTSs8ka9UklW25cMSFgfE1ncoA2B-2b9ZRP0SM1qnH-6WMLeCfA2Q78mA8ik-9a84cJnWcG-05NGfaEqgPTzJu0db3GRJrKRfXyFJK2DVcYzLCmZsQFFByZVtjjTlz-eqkBYz-5No9yz-9gKxjsdWgmxjZU1pRqc7PHJ~z-jS08LsbsyJpnbVxcLZqBx3WsaU8ZKcvpjyclm2zb~lJ-mJVw4yLb4CuaHE1LDbaB0iV3HgaqxxuhEnaNsOTI6rff0wjL2u8gRr6sthIpX-3fcZzLulLhTOusm1q9yNoXZ628qvXx861-aGww__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "7",
    operand: "+",
  },
  {
    src: "https://s3-alpha-sig.figma.com/img/5990/d31c/d3427d28f6a9d826020d53973d98b943?Expires=1670803200&Signature=bw-9sr2T2~t8g4GeQkgi9HQaDDDmp7iTSiUC9fXx6bUTp-SVWr-1yGWxHFL8KG8a7U4bmTmB1fCy5Ov4GPLZ3G02OE~J2kF7wYupsfACD4enzgqF~3NCN5A22Ie8EOkd6VpJ~OA5~FuAQbc-wfwkIl2dAz5unwukKSKSUYX1K7vkbTdiMaJPuIdFgXDrcEGmN69psI3nYPn~S3nulLIg7yTF6r6ukMRdsqsEA4OFoE67pnPXoKLIR5wVUUM24cJJ1PVR3T4Ik5vg2AWdCTxFO4gD847-YHfDBvxy7Q6O9PFzUOfj~CeJcG6qmWLOyV~w4n7YymYsluM098GfGp0Q8w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "YouTube",
    length: "1:22",
    count: "15.2K",
    price: "24.99",
    id: "8",
    operand: "+",
  },
];

export const dropdownList = [
  { title: "Date", href: "" },
  { title: "Length", href: "" },
  { title: "Views", href: "" },
];

type categoryData = {
  name: string;
  img: string;
};

export const categoryData: categoryData[] = [
  {
    img: "https://s3-alpha-sig.figma.com/img/c85e/1bee/a1a34210cd51e855a481c1e09dc80e23?Expires=1670803200&Signature=Bq60ZAUAiVR~RWeQGIbJuiSECsydu5tKQi-4jlbS3evscnysQQUlLtCOYCvNYUOS-ysxXazysthgo55Xfz1wQ6u5uKWfWgo6uLYlhd6pASZjPVNMnx0p4iQyXUJVa2c1iXqE2AFK11UlmuSYR8thJuLNx6B3SQHvgY3MSnNp9fMwyU7LTFBQY-DZyedrU3YQQQ3eQc~iaO4Oc4crFXBipgs~J6Q4U1MAkhKPbRcPzoi1btPcHXlnrnITJyu8EA15XTJ-OLwlkUXkPTuPc0EorM0m5-iV28X4AiODI8yNa8cUiM5fZF3oMv1bus2AnV0fQYnuhwhhkwa150hIkeTFmA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    name: "Nature",
  },
  {
    img: "https://s3-alpha-sig.figma.com/img/e474/ccec/0426ddf1e1e6c1af14b3f994fd52bfb2?Expires=1670803200&Signature=eBzsQqUOmvS0HL0RoP5MC6y2o9R6W0hFg1OZlV2Lb8h1LN1-WZECzZjv2k023lMrLwRKwcfrmHYRv~Mb9ApggytQGRc8dfOCqelioYcHnR~355N1dfT-Nt0W3Xga5LTBNtbM1xFtxv5okTzyyAROM~qycj1joalxVWKaklohSbzKbNTSVLPL8ZWxBjonKA0MBLg6PK1dH2cqXYJfyUg0B-~reZBmhTWhEujdDDWbKNTMz0yQSTG20SvF2P7hR5gRMow8aOylogIJovhAG~xNcTs0r8Zj17iQvFyNeJdhzsWnHdoa6cQwl8a29XApnlS3EIH2PYt2hiA1arPOZw3nDA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    name: "Abstract",
  },
  {
    img: "https://s3-alpha-sig.figma.com/img/2d33/2e00/c4be2f43b49dc923853b8df77865bd31?Expires=1670803200&Signature=ChofzSrQuYlnBG6JUGUStLgIY~9FhakZg7pyNYm3dOFUXNAY9kSdW69o1AG5e54muuPhlQG5tRm0X5rR~surfDLZABIjIHLOZLfn5efMBZJR0dMT37l6wAy3QcvXoPO4Ho2qVamdq-wqXmHvDRHzR7tk8RYmp7g3gi5bSA-wP6gIbGJ6GNnxC16o4nfY7hjgrw8jYBZQn-jZ-sQlChjlPyP9j4gIvjM3EiJ0Vz8BGMsh7okJgZVneAaVn3lf8DC-PVj8TYaW7lJ~L82iu9wSYBD-EbPeYcwl1zE3NqvFfJ0MBizxG2wJmRDgHVaZgPqUByFdwkiQDEF-0sR4268PRA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    name: "People",
  },
  {
    img: "https://s3-alpha-sig.figma.com/img/e192/8c48/96e16fa0300743c694d9eed346fec398?Expires=1670803200&Signature=ekdoCo542rzx8LOwAhjyxxIOvv0BME5JZB17aeTe5lVIH1N8ZD4M9TjoKjpzy~egkHXPu7EDAzXX6SFPEPvQItC2CtsCLaNVOyVjxyCYq82Q5uYDfC4Z~zuduFBNNFjeufBg6nYrqYX8KZ7u0bN2VGtCXAFCKu8AwDKEVnWF7SHuPvCPIifHCUqMgRHSbgE6EDp4YyktP0QHIMIvyZz4g7~FCO8wjvDmPv8eRlNZggB03nAaIFCOaphDcDuA-3VPsAWFs3TKvkSUCJ7C4PeAE62-57SFU1UWoTkkAeILrvgUkrLJRDfMahnRQTZGIskZru3zLM5mbVofoiZULQNnBA__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    name: "Vintage",
  },
  {
    img: "https://s3-alpha-sig.figma.com/img/80f7/247f/13265851aa8517b38def485970420e94?Expires=1670803200&Signature=CFFn9IfAOOOLx0rbXC36qsRMoOCEogKMGn9~oi8gW40JnDdSoNHglocwtBXCX8dIXiagX5IZq4vkWoxBOAuWdLErBandIEiw-TLVzm5rEg6zLIsanveo7uvutglmbQU0K-Mb7GQYAQomOUNRueGhmySD40kS0E8FCri8zcshkkdBhUgjl2I3~oScu1bNfjkvteCO8oQXY5zZd-fd3sFNjKc2zDlfHN2P3gZ9OvA1WgtWmliiXjOJfwR~rd9MW~j4jPHd4~oVJ~wBx0IxGna4QMXexQrwiayU3Gy8ancK3ZeEMzx6i~YxdDZ6tS1rbIpuwMZN8cQd3pe9f~vFQBQGHg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    name: "Retro",
  },
  {
    img: "https://s3-alpha-sig.figma.com/img/6605/e75b/a033a406fb906e9442b30322f1853ee3?Expires=1670803200&Signature=WTLSmwelcnXuPJMv1-DGWKjM7xk5GCVwvUDZNsFlQuxwvx9urhwyIM-yZ2cpmChYS-dTVkbsy-lc7F~wfWKRfllKoAoL1tuWgu7G5Dj7SM~dRvHASqHCoVRF02RtTSJIpV0uLzG1LVDj3mxAfZgQz-cbokmImvWC2hnc6TvasqqhrbXxFhOp0bEO0BYyhS90eyLULYO~P6xEelOCkCBZ6MJ2a1klmuKwRF1chtOEjlIB81Gbtdf2~A95D7LGTaryyicx6VB3AwKJN8qsZbf6lfboSJi8HUqwKpoDfqJcACEygS1HDC0fkin-lOzKSSvLdL3hFS4cNJZ6Jv1RUtietg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    name: "Wildlife",
  },
  {
    img: "https://s3-alpha-sig.figma.com/img/7ef4/132a/c64b6f736962f9a22014eadfb11b3abf?Expires=1670803200&Signature=MbnPdopnAh0~c3ISfHEUvZb-QNxuGG8aRXIQbmbUsPXSIXo9MylQBBZSrhKN4gGaiPpdJafbDBN3P6Z-EhDxBEYTTF-FOpe1h~nRBYLtF5PpwPqdvopotMZWy3NcHmLmwvCoTe9qG09B11rLln49SvIIOQCDbFzWjIm4gtceF7UzkMC11PNUTNAYdutDBIBu0CNwDipd5HubaQLXmFc-8fYGF0RloT47RNZuEGRlrhVnTJj6FQ0nIKiy0Le9rJ64d8YiYFfeEpnykRWKhWhBABxqB39PJD1GmuNFbY0X27o9UfVyr-4OzgkG-g3h3omSILbBgQms1Cr-PRYHjJk~gQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    name: "Cars",
  },
  {
    img: "https://s3-alpha-sig.figma.com/img/fd63/d6fb/3a67b458c06b065638fb5617b256f168?Expires=1670803200&Signature=ZP2pLGEc1e3-R3obTvbw7tmBtPtmNd-MNAmvLJrVVACTv6XujCbEI1lJqi~gyA9r6aGB9ByeQK~GLsND60~fcmPkjpZNm3mAT6blWLXsAOQ~yrFPjbVfwVXal5wnP8Kvro9ClEWCL5JwahC9e0pUHAtDncGJNmGkW0Xjfy4QKMh16hJS-2sEB872BIPsaS~ZQ4Hn93jhVfDh6KBMmVoZS9bbogkcS5gYMsiu8ILPTx7gkPmygcdZhUTWvsChhxb71pINVtlR-4pjHv51ZIqtIZgAG92a1EaX4cAs-EjZD0vzdPuC~BSbr9CQj8ol6BYY4bLGORMszQVKrh7BOGngsw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    name: "Ocean",
  },
  {
    img: "https://s3-alpha-sig.figma.com/img/1a31/f5f7/074d9e600bab014a8cec2434f847d16f?Expires=1670803200&Signature=U7vjoaFBBkB6toTDEq1meWQSfQlizw-EKNkV~luYpd2hYH53qjWlLFfSTP-sKf4dPtqSlTaEkISCxCeApuaySyHxveRRgE00VpZQttiBRBqrLiDq3SF11v-tiJcosRs1yqa-9qT5zD6teVmPpvGrKE42t5mSPpYZivJ-nUva7cJRHnUkCycHGFPavX8nmWXBAB4LvuU555160xrQ~r6VA3TEIEVdwMHH3~J4c-vuZun6v9X5dWiOxuBZdMUMaeU90JdAMywFyToxT8kKHG-YfR1Z~1fnURJBOmCpXVNDyYPCKfi4EEOHjDazBqxH4E27zibUzhJ~q5zBS99FiAK7fw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    name: "City",
    // img: 'https://via.placeholder.com/50'
  },
];

export interface tFieldGroupList {
  username: string;
  "last name": string;
  "first name": string;
  email: string;
  country: tDropdown[];
  "contact details": string;
  date_of_birth: string;
  password: string;
  gender: tDropdown[];
}

export const fieldGroupList: tFieldGroupList = {
  username: "Krishna_prasath",
  "last name": "Krishna",
  "first name": "Krishna",
  email: "krishna@gmail.com",
  country: [
    { href: "", title: "United States" },
    { href: "", title: "India" },
    { href: "", title: "Sri Lanka" },
  ],
  "contact details": "+91-800-900-9080",
  date_of_birth: "10-12-2001",
  password: "********",
  gender: [
    { href: "", title: "Male" },
    { href: "", title: "Female" },
    { href: "", title: "others" },
  ],
};
