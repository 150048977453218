import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import TicketHeader from "./TicketHeader";
import WantedHeader from "./WantedHeader";

const Header = () => {
  const location = useLocation();
  const ticketPaths = [
    "/ticket/booking",
    "/ticket/booking/preview",
    "/ticket/booking/create-ticket",
    "/ticket/booking/payment",
    "/live-stream-event/my-posted",
    "/live-streaming",
  ];
  const WantedVideo = [
    "/wanted/video",
    "/wanted/video/create",
    "/wanted/my-post",
    "/wanted/video/apply",
    "/wanted/my-applied",
  ];
  const MainNav = [
    "/",
    "/founder ",
    "/video-summary",
    "/faq",
    "/wishlist",
    "/my-cart",
    "/orders",
    "/profile",
    "/notifications",
    "/upload-video",
    "/boost/post",
    "/login",
    "/signup",
    "/about-us",
    "/terms-and-conditions",
    "/privacypolicy",
    "/refundpolicy",
    "/copyrights",
    "/contact-us",
    "/change-password",
    "/instantcart-summary",
    "/my-cart/cart-summary",
    "/reset-password",
    "/search-results",
    "/location",
    "/founder",
    "/author-profile",
    "/transaction-success",
    "/forgot-password",
    "/sponsorship",
    "/sell/video",
    "/awards",
    "/boost/post/659d5550b5a7fb0bc26824e2",
  ];

  return (
    <div>
      {MainNav.includes(location.pathname) && <Navbar />}
      {WantedVideo.includes(location.pathname) && <WantedHeader />}
      {ticketPaths.includes(location.pathname) && <TicketHeader />}
    </div>
  );
};

export default Header;
