import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FetchPaymentStatus,
  fetchCategories,
  priceCheckForUploadingVideo,
  uploadVideo,
  viewProfile,
} from "../utils/util";
import * as Yup from "yup";
import { USER_ID_NAME } from "../utils/variables";
import { ToastContainer, toast } from "react-toastify";
import { Loader, Modal, Select, Slider } from "@mantine/core";
import { CategoryState } from "../utils/genericTypes";
import {
  tFormData,
  uploadFormSchema,
  uploadVideoSuccess,
} from "./SubmitVideo/components/uploadFormSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const TransactionSuccessData = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState<any>();
  const [progress, setProgress] = useState(0);
  // const [isPragressOpen, setIsPragressOpen] = useState(false)
  const PROFILE_DATA = {
    _id: profile?._id,
    first_name: profile?.first_name,
    last_name: profile?.last_name,
    user_name: profile?.user_name,
    email: profile?.email,
    contact_number: profile?.contact_number,
  };
  var formDataJSON: any = localStorage.getItem("formData");

  // Parse the JSON string to get the original object
  var formDataObject = JSON.parse(formDataJSON);

  // Create a new FormData object
  var formData = new FormData();
  const paymentId = localStorage.getItem("payment_id");
  function dataURItoBlob(dataURI: any) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI?.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI?.split(",")[1]);
    else byteString = unescape(dataURI?.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI?.split(",")[0]?.split(":")[1]?.split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  const formSchema = Yup.object().shape({});

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const [paymentSuccess, setPaymentSuccess] = useState<any>();
  console.log(paymentSuccess, "paymentSuccess");
  console.log(
    paymentSuccess?.[0].product_name === "Upload Video",
    "paymentSuccess?.[0].product_name === "
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isPragressOpen, setIsPragressOpen] = useState(false);
  const [priceCheckData, setPriceCheckData] = useState<
    { code: string; amount: string } | undefined
  >();
  const [uploadFormData, setUploadFormData] = useState<FormData | undefined>();
  const closeModal = (props: { type?: string }) => {
    setModalIsOpen(false);
    if (props?.type === "continue") {
      uploadVideo({
        formData,
        _id: profile?._id,
        profile: PROFILE_DATA,
        navigate,
        toast,
        setProgress: setProgress,
      });
      // triggerUpload({
      //   formData: uploadFormData,
      //   _priceCheckData: priceCheckData,
      // })
    }
  };

  const [VideosDB, setVideosDB] = useState<any>();
  const [preview_VideoDB, setPreview_VideoDB] = useState<any>();

  const [VideoUploadSuccess, setVideoUploadSuccess] = useState<any>();
  const [Video, setVideo] = useState<any>();
  const [duration, setDuration] = useState<any>();

  useEffect(() => {
    const ID = localStorage.getItem(USER_ID_NAME);
    FetchPaymentStatus({
      setWantedVideoCout: setPaymentSuccess,
      static: true,
      strp_payment_id: paymentId,
    });
    if (ID && ID !== "") {
      viewProfile(ID, setProfile);
    }
  }, []);
  function convertHMS(props: { value: string }) {
    const { value } = props;
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = `${Math.floor(sec / 3600)}`; // get hours
    let minutes = `${Math.floor((sec - +hours * 3600) / 60)}`; // get minutes
    let seconds = `${sec - +hours * 3600 - +minutes * 60}`; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (+hours < 10) {
      hours = "0" + hours;
    }
    if (+minutes < 10) {
      minutes = "0" + minutes;
    }
    if (+seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  }

  const [customList, setCustomList] = useState<CategoryState[]>([]);
  const img: any = localStorage.getItem("img");
  const video: any = localStorage.getItem("video");
  const preview_video: any = localStorage.getItem("videos");
  const newVideo: any = JSON.parse(video);
  const minutes = Math.floor(duration / 60);
  const seconds = Math.round(duration % 60);

  // Format the result as "00.00"
  const formattedDuration = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const onSubmit = async (data: any) => {
    try {
      if (formDataObject?.duration === formattedDuration) {
        const formData = new FormData();
        formData.append("duration", formDataObject?.duration);
        formData.append("cost", formDataObject?.cost);
        formData.append("created_by_id", formDataObject?.created_by_id);
        formData.append("created_by_name", formDataObject?.created_by_name);
        formData.append("description", formDataObject?.description);
        formData.append("title", formDataObject?.title);
        formData.append("category", formDataObject?.category);

        const thunImg = dataURItoBlob(img);

        formData.append("thumbnail", thunImg);
        formData.append("videos", data?.videoFile[0] as File);
        formData.set("preview_video", data?.preview_video?.[0] as File);

        if (localStorage.getItem("Success") === "Upload Video") {
          uploadVideo({
            formData,
            _id: profile?._id,
            profile: PROFILE_DATA,
            navigate,
            toast,
            setProgress: setProgress,
            setVideoUploadSuccess: setVideoUploadSuccess,
          });
          setIsPragressOpen(true);
        } else {
          toast("Upload Video Again");
        }
      } else {
        toast(
          "Please be advised that video uploads will only be accepted if they are accompanied by proof of completed payment"
        );
      }
    } catch (error) {
      console.log(" --- Error while price checking: ", error);
    }
  };
  React.useEffect(() => {
    fetchCategories({
      setCategories: setCustomList,
      static: true,
    });
  }, []);

  const getAudioVideoDuration = async (file: File) => {
    return new Promise((resolve, reject) => {
      const media = document.createElement("video");
      media.onloadedmetadata = () => {
        resolve(media.duration);
      };
      media.src = URL.createObjectURL(file);
    });
  };

  const onClickValidationHandler = () => {
    const errorValues = Object.values(errors);
    errorValues.map((error: any) => toast(error.message));
  };

  return (
    <div className="flex pt-24 items-center text-white justify-center ">
      <Modal
        closeOnClickOutside={false}
        size={"lg"}
        centered
        opened={progress === 100 ? false : isPragressOpen}
        onClose={() => setIsPragressOpen(false)}
        title="Authentication"
      >
        <div className="py-6">
          <h3>Upload percentage</h3>
          <Slider
            value={progress}
            color="blue"
            marks={[
              { value: 0, label: "0%" },
              { value: 50, label: "50%" },
              { value: 100, label: "100%" },
            ]}
          />
        </div>
      </Modal>

      <div>
        <h4 className="py-2 text-3xl font-bold">
          Upload Preview Video and Original Video
        </h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-background_item mt-8 py-6 px-8 rounded-2xl">
            {uploadVideoSuccess.map((value: any, index: any) => {
              return (
                <InputFieldWrapper title={value.label} des={value.des}>
                  {value.tag === "thumbnailFile" ||
                  value.tag === "preview_video" ||
                  value.tag === "videoFile" ? (
                    <>
                      <input
                        type={value.type}
                        inputMode={value.inputMode}
                        className="h-14 w-full"
                        accept={value.accept}
                        {...register(value.tag as keyof tFormData, {
                          required: `Please enter the ${value.name} `,
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (!event.target.files) return;
                            const file = event.target.files[0];

                            const fileSizeInBytes = file.size;
                            const fileSizeInMB =
                              fileSizeInBytes / (1024 * 1024); // Convert to MB
                            if (fileSizeInMB > value.fileLimit) {
                              toast.error(value.fileerror);
                              return;
                            }

                            if (
                              value.tag === "videoFile" ||
                              value.tag === "preview_video"
                            ) {
                              new Promise(async (resolve, reject) => {
                                let reader = new FileReader();
                                reader.onload = function () {
                                  if (!reader.result) return;
                                  let aud = new Audio(reader.result as string);
                                  aud.onloadedmetadata = function () {
                                    resolve(
                                      convertHMS({ value: `${aud.duration}` })
                                    );
                                  };
                                };
                                const durationVideo: any =
                                  getAudioVideoDuration(file);
                                durationVideo
                                  .then(function (value: any) {
                                    setDuration(value);
                                  })
                                  .catch(function (error: any) {
                                    console.error("Error:", error);
                                  });

                                reader.readAsDataURL(file);
                              }).then((d) => {
                                // setSelectedFile(file)

                                // setDuration(d)
                                return true;
                              });
                            } else if (value.name === "thumbnailFile") {
                              // setSelectedFile(event.target.files[0])
                            }
                            return false;
                          },
                        })}
                        placeholder={value.placeholder}
                      />
                    </>
                  ) : value.type === "select" ? (
                    <Select
                      onChange={(selectedValues) => {
                        register(value.tag as keyof tFormData, {
                          required: "Please select at least one value",
                          value: selectedValues, // You can include the selected values in the validation object
                        });
                      }}
                      searchable
                      type="fieldGroup"
                      // {...register(value.name as keyof tFormData, {
                      //   required: `Please select a ${value.name}`,
                      // })}
                      className="FormSubmittedText-submit-multi FormSubmittedText-submit "
                      placeholder="Pick value"
                      data={customList?.map((value) => value.name)}
                      clearable
                    />
                  ) : (
                    <div className="">
                      <textarea
                        // name={value.tag}
                        placeholder={value.placeholder}
                        rows={value.tag === "description" ? 12 : 3}
                        className="px-6 border-none outline-none text-lg font-semibold py-6 rounded-2xl w-full bg-background_body"
                        // type="text"
                        {...register(value.tag as keyof tFormData, {
                          required: `Please enter the ${value.name} `,
                        })}
                      />
                    </div>
                  )}

                  <p className="text-red-500 text-sm lg:text-sm font-medium">
                    {/* {errors?.[value.tag]?.message} */}
                  </p>
                </InputFieldWrapper>
              );
            })}
          </div>
          <div className="flex items-center justify-end">
            <button
              onClick={onClickValidationHandler}
              className="default-button-color my-8 ml-auto px-6 py-3 rounded-xl text-base font-semibold"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <>
        {VideoUploadSuccess?.status_code === 200 ? (
          <Modal
            closeOnClickOutside={false}
            size={"lg"}
            centered
            opened={VideoUploadSuccess?.status_code === 200}
            onClose={() => navigate("/")}
            title="Authentication"
          >
            <>
              {/* Main modal */}
              <div
                id="successModal"
                className="black overflow-y-auto flex items-center justify-center overflow-x-hidden justify-center items-center w-full h-full"
              >
                <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                  {/* Modal content */}
                  <div className="relative p-4 text-center rounded-lg shadow  sm:p-5">
                    <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-green-500 dark:text-green-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="sr-only">Success</span>
                    </div>
                    <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                      Payment Successfully completed
                    </p>
                    <p className="text-sm font-medium py-6 text-gray-300">
                      The payment for your purchase has been successfully
                      processed. You may now download your selected video
                      automatically. If you encounter any issues, please feel
                      free to download it from the "My Purchases" section.
                    </p>
                    <Link to="/profile?id=My Videos">
                      <button
                        data-modal-toggle="successModal"
                        type="button"
                        className="py-2 px-3 bg-main text-sm font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900"
                      >
                        Continue
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </>{" "}
          </Modal>
        ) : (
          <div className="">
            {/* <Loader className="" /> */}
            {/* <img className="w-3/5" src="/Images/uploadLoading.gif" alt="" /> */}
          </div>
        )}
      </>
    </div>
  );
};

function DownloadVideo() {
  const navigation = useNavigate();
  const [paymentSuccess, setPaymentSuccess] = useState<any>();
  console.log(paymentSuccess);

  const paymentId = localStorage.getItem("payment_id");
  useEffect(() => {
    FetchPaymentStatus({
      setWantedVideoCout: setPaymentSuccess,
      static: true,
      strp_payment_id: paymentId,
    });

    if (paymentSuccess) {
      const videoUrl = paymentSuccess?.[0]?.video_url;
      const downloadFileName = `${paymentSuccess?.[0]?.video_title}.mp4`; // Specify the desired file name for download

      // Create an anchor element to trigger the download
      const downloadLink: any = document.createElement("a");
      downloadLink.href = videoUrl;
      downloadLink.download = downloadFileName;
      downloadLink.download = "/copyright.pdf";
      downloadLink.click();
    }
    setTimeout(() => {
      localStorage.removeItem("payment_id");
    }, 4000);
  }, [paymentSuccess?.[0]?.video_url]);

  return (
    <div className="">
      <div className="flex h-screen items-center justify-center w-full mt-auto mb-auto">
        {" "}
        <div
          id="successModal"
          className="black overflow-y-auto flex items-center justify-center overflow-x-hidden w-full h-full"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            {/* Modal content */}
            <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              <button
                onClick={() => navigation("/")}
                type="button"
                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="successModal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Success</span>
              </div>
              <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                Payment Successfully completed
              </p>
              <p className="text-sm font-medium py-6 text-gray-300">
                The payment for your purchase has been successfully processed.
                You may now download your selected video automatically. If you
                encounter any issues, please feel free to download it from the
                "My Purchases" section.
              </p>
              <Link to="/profile?id=My Purchase">
                <button
                  data-modal-toggle="successModal"
                  type="button"
                  className="py-2 px-3 bg-main text-sm font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900"
                >
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const TransactionSuccess = () => {
  const successType = localStorage.getItem("Success");
  const paymentId = localStorage.getItem("payment_id");

  const navigation = useNavigate();
  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {paymentId ? (
        <>
          {successType === "Upload Video" ? (
            <TransactionSuccessData />
          ) : successType === "Month Membership Wanted" ? (
            <PlanFunction />
          ) : successType === "Event Ticket Buy" ? (
            <TicketBuy />
          ) : successType === "Month Membership Event" ? (
            <TicketPlan />
          ) : (
            <DownloadVideo />
          )}
        </>
      ) : (
        <>
          {toast("Create Payment Transaction")}
          {navigation("/")}
        </>
      )}
      {/* <TransactionSuccessData /> */}
    </div>
  );
};

export default TransactionSuccess;

function PlanFunction() {
  const navigation = useNavigate();

  return (
    <div className="">
      <div className="flex h-screen items-center justify-center w-full mt-auto mb-auto">
        {" "}
        <div
          id="successModal"
          className="black overflow-y-auto flex items-center justify-center overflow-x-hidden w-full h-full"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            {/* Modal content */}
            <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              <button
                onClick={() => navigation("/")}
                type="button"
                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="successModal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Success</span>
              </div>
              <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                Payment Successfully completed
              </p>
              <p className="text-sm font-medium py-6 text-gray-300">
                Congratulations! Your payment for the 1-month subscription fees
                has been successfully processed. Now you can access our job
                board and start earning money. Thank you for choosing our
                services.
              </p>
              <Link to="/wanted/my-post">
                <button
                  data-modal-toggle="successModal"
                  type="button"
                  className="py-2 px-3 bg-main text-sm font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900"
                >
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TicketBuy() {
  const navigation = useNavigate();

  return (
    <div className="">
      <div className="flex h-screen items-center justify-center w-full mt-auto mb-auto">
        {" "}
        <div
          id="successModal"
          className="black overflow-y-auto flex items-center justify-center overflow-x-hidden w-full h-full"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            {/* Modal content */}
            <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              <button
                onClick={() => navigation("/")}
                type="button"
                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="successModal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Success</span>
              </div>
              <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                Payment Successfully completed
              </p>
              <p className="text-sm font-medium py-6 text-gray-300">
                Your event ticket has been successfully booked. Prior to the
                event, you will receive the event link via email and SMS from
                ZynoFlix, approximately 15 minutes before it begins. We
                encourage you to watch the event live and share your feedback.
                Thank you for choosing ZynoFlix and enjoy the show!
              </p>
              <Link to="/profile?id=My Purchase">
                <button
                  data-modal-toggle="successModal"
                  type="button"
                  className="py-2 px-3 bg-main text-sm font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900"
                >
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TicketPlan() {
  const navigation = useNavigate();

  return (
    <div className="">
      <div className="flex h-screen items-center justify-center w-full mt-auto mb-auto">
        {" "}
        <div
          id="successModal"
          className="black overflow-y-auto flex items-center justify-center overflow-x-hidden w-full h-full"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            {/* Modal content */}
            <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              <button
                onClick={() => navigation("/")}
                type="button"
                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="successModal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-green-500 dark:text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Success</span>
              </div>
              <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                Payment Successfully completed
              </p>
              <p className="text-sm font-medium py-6 text-gray-300">
                By subscribing to our streaming event post, you have made a
                payment for a 1-month subscription. This allows you to make up
                to 10 streaming posts within the month. With this service, you
                can promote your events globally, sell tickets, generate
                revenue, and stream your videos through the ZynoFlix
                application.
              </p>
              <Link to="/wanted/my-post">
                <button
                  data-modal-toggle="successModal"
                  type="button"
                  className="py-2 px-3 bg-main text-sm font-medium text-center text-white rounded-lg bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-900"
                >
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
interface InputFieldWrapperProps {
  children: React.ReactNode;
  title: string;
  des: string;
}

function InputFieldWrapper({ children, title, des }: InputFieldWrapperProps) {
  return (
    <div className="flex lg:flex-row flex-col pb-9 gap-3 lg:gap-9 items-start lg:items-center">
      <div className="w-full lg:w-1/3">
        <h2 className="text-lg font-semibold">{title}</h2>
        <p className="text-sm font-normal text-gray-500">{des}</p>
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
}
