import React from "react"
import { Oval } from "react-loader-spinner"

interface ButtonWithLoaderProps {
  onClickHandler: React.MouseEventHandler<HTMLButtonElement>
  text: string
  isNotSubmit?: boolean
  customClassName?: string
  clearPreDefinedStyles?: boolean
  disabled?: boolean
}

const buttonStyle = {
  background: "linear-gradient(271deg, #1976D2 0%, #B561F7 87.81%)",
  marginTop: "1rem md:2rem",
}

const OvalLoader = () => {
  return (
    <Oval
      height={20}
      width={20}
      color="white"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#4fa94d"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  )
}

const ButtonWithLoader: React.FC<ButtonWithLoaderProps> = (props) => {
  const {
    onClickHandler,
    text,
    customClassName,
    clearPreDefinedStyles,
    disabled,
    isNotSubmit,
  } = props

  const disableButton = disabled
  return (
    <>
      <button
        type={isNotSubmit ? "button" : "submit"}
        style={clearPreDefinedStyles ? {} : buttonStyle}
        className={
          clearPreDefinedStyles
            ? customClassName
            : `actionBtn px-6 py-3 bg-btn_bgcolor3 rounded-xl flex justidy-ceter gap-4 flex items-center justify-center ${customClassName} ${
                disableButton && "opacity-50 cursor-not-allowed"
              } h-[65px]`
        }
        onClick={onClickHandler}
        disabled={disableButton}
      >
        <span>{text}</span>
      </button>
    </>
  )
}

export default ButtonWithLoader
