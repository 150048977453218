import React, { useEffect, useState } from "react"
import { TicketBookingcard } from "./TicketBooking"
import {
  RemoveEventPost,
  myPostLiveStreamingEvent,
  viewProfile,
} from "../utils/util"
import { USER_ID_NAME } from "../utils/variables"
import { Link } from "react-router-dom"
import {
  PlayCircleIcon,
  TicketIcon,
  TrashIcon,
} from "@heroicons/react/24/outline"

const MyPostedEvent = () => {
  const [myPostedEvent, setMyPostedEvent] = useState<any>()
  const [profile, setProfile] = useState<any>()
  const ID = localStorage.getItem(USER_ID_NAME)

  useEffect(() => {
    if (ID && ID !== "") {
      viewProfile(ID, setProfile)
    }

    myPostLiveStreamingEvent({ user_id: ID, setMyPostedEvent })
  }, [])

  return (
    <div className="pt-28 text-white pb-12 px-24">
      <div className="">
        <h3 className="pb-8 text-2xl font-bold">My Posted</h3>
      </div>

      {myPostedEvent?.map((value: any, index: any) => (
        <TicketBookingcardPost
          setMyPostedEvent={setMyPostedEvent}
          key={index}
          value={value}
        />
      )).length > 0 ? (
        // Data is available, render the mapped components
        myPostedEvent?.map((value: any, index: any) => (
          <TicketBookingcardPost
            setMyPostedEvent={setMyPostedEvent}
            key={index}
            value={value}
          />
        ))
      ) : (
        // No data, render loading message
        <div
          className={`h-[400px] col-span-4 flex flex-col border shadow-sm rounded-xl hover:border-blue-500 bg-gray-800 border-gray-700 shadow-slate-700/[.7]`}
        >
          <div className="flex flex-auto w-full flex-col justify-center items-center p-4 md:p-5">
            <svg
              className="max-w-[5rem]"
              viewBox="0 0 375 428"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M254.509 253.872L226.509 226.872"
                className=" stroke-white"
                stroke="currentColor"
                strokeWidth={7}
                strokeLinecap="round"
              />
              <path
                d="M237.219 54.3721C254.387 76.4666 264.609 104.226 264.609 134.372C264.609 206.445 206.182 264.872 134.109 264.872C62.0355 264.872 3.60864 206.445 3.60864 134.372C3.60864 62.2989 62.0355 3.87207 134.109 3.87207C160.463 3.87207 184.993 11.6844 205.509 25.1196"
                className=" stroke-white"
                stroke="currentColor"
                strokeWidth={7}
                strokeLinecap="round"
              />
              <rect
                x="270.524"
                y="221.872"
                width="137.404"
                height="73.2425"
                rx="36.6212"
                transform="rotate(40.8596 270.524 221.872)"
                className=" fill-white"
                fill="currentColor"
              />
              <ellipse
                cx="133.109"
                cy="404.372"
                rx="121.5"
                ry="23.5"
                className=" fill-white"
                fill="currentColor"
              />
              <path
                d="M111.608 188.872C120.959 177.043 141.18 171.616 156.608 188.872"
                className=" stroke-white"
                stroke="currentColor"
                strokeWidth={7}
                strokeLinecap="round"
              />
              <ellipse
                cx="96.6084"
                cy="116.872"
                rx={9}
                ry={12}
                className=" fill-white"
                fill="currentColor"
              />
              <ellipse
                cx="172.608"
                cy="117.872"
                rx={9}
                ry={12}
                className=" fill-white"
                fill="currentColor"
              />
              <path
                d="M194.339 147.588C189.547 148.866 189.114 142.999 189.728 138.038C189.918 136.501 191.738 135.958 192.749 137.131C196.12 141.047 199.165 146.301 194.339 147.588Z"
                className=" fill-white"
                fill="currentColor"
              />
            </svg>
            <p className="mt-5 text-sm text-gray-500">No data to show</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default MyPostedEvent

function TicketBookingcardPost({ value, setMyPostedEvent }: any) {
  const dateString = value.streaming_date
  const ID = localStorage.getItem(USER_ID_NAME)
  const dateObject = new Date(dateString)
  const DeletePost = (id: any) => {
    RemoveEventPost({ videoId: id })
    myPostLiveStreamingEvent({ user_id: ID, setMyPostedEvent })
  }
  const formattedDateStr = `${dateObject.getFullYear()}-${(
    dateObject.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${dateObject.getDate().toString().padStart(2, "0")} `
  const TimeString = value.streaming_time
  const TimeObject = new Date(TimeString)
  const formattedTimeStr = `${TimeObject.getHours()
    .toString()
    .padStart(2, "0")}:${TimeObject.getMinutes()
    .toString()
    .padStart(2, "0")}:${TimeObject.getSeconds().toString().padStart(2, "0")}`

  return (
    <div className="flex flex-col relative gap-6 bg-background_item rounded-xl mt-4 py-4 px-4 lg:gap-0 lg:flex-row pb-6">
      <button
        onClick={() => DeletePost(value._id)}
        className="absolute z-50 top-4 right-6"
      >
        <TrashIcon className="w-6 h-6" />
      </button>
      <div className="w-full lg:w-[35%]">
        <Link to={`/ticket/booking/preview?id=${value._id}`}>
          <img
            className="rounded-xl w-full h-[380px] object-cover object-center "
            src={value?.poster_image}
            alt=""
          />
        </Link>
      </div>
      <div className="w-full lg:w-3/5 pl-0 lg:pl-9">
        <div className="space-y-3">
          <Link to={`/ticket/booking/preview?id=${value._id}`}>
            <h2 className="text-2xl font-semibold ">{value?.heading}</h2>
          </Link>
          <h3 className="flex items-center gap-2">
            Total Time Duration :
            <p className="text-[#31d7a9] text-sm flex items-center font-medium">
              {value?.duration_time}
            </p>
          </h3>
          <div className="flex items-center gap-2 text-sm lg:text-base font-medium">
            <h3 className="px-4 py-2 w-min rounded-xl bg-background_item flex items-center">
              {value?.category}
            </h3>
            <h3 className="px-4 py-2 w-min rounded-xl bg-background_item flex items-center">
              {value?.language}
            </h3>
          </div>
          <h2 className="text-base font-medium">
            <span className="font-bold"> Release Date :</span>{" "}
            {formattedDateStr}
          </h2>
          <h2 className="text-base font-medium">
            <span className="font-bold"> Release Time :</span>{" "}
            {formattedTimeStr}
          </h2>
          <h1 className="capitalize">total Booking : {value?.ticket_cost}</h1>
          <p className="text-gray-400 line-clamp-3">{value?.description}</p>
        </div>
        <div className="w-full my-4 border-t border-gray-500 flex items-center justify-between border-b">
          <div className="flex gap-6 items-center py-4">
            <Link to={`/ticket/booking/preview?id=${value._id}`}>
              <button className="flex gap-2 default-button-color text-xs font-bold px-4 rounded-xl  py-2 lg:text-base items-center">
                {/* <div className="bg-background_item  text-xs lg:text-base w-10 h-10 rounded-full flex items-center justify-center">
                  <TicketIcon className="w-6 h-6" />
                </div> */}
                Boost Post
              </button>
            </Link>
          </div>
          <div className="">
            <Link
              to={`/ticket/booking/preview?id=${value._id}`}
              className="flex gap-2 text-xs lg:text-base items-center"
            >
              <div className="bg-background_item text-xs lg:text-base w-10 h-10 rounded-full flex items-center justify-center">
                <PlayCircleIcon className="w-6 h-6" />
              </div>
              Watch trailer
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
