import React, { useContext, useEffect, useState } from "react"
import "./Styles.css"
import { Link, useLocation } from "react-router-dom"
import Profile from "../../components/profile/Profile"
// import Logo from "../../components/logo/Logo"
import { IconComponentProps } from "../../utils/genericTypes"
import { AlertCircle, Icon, Bell } from "react-feather"
import { sideNavLinkList } from "../../store/mockStore"

import { fetchWishListItems } from "../../utils/userController"
import { TOKEN_NAME, USER_ID_NAME } from "../../utils/variables"
import { MyContext } from "../MyProvider/MyProvider"
import { fetchToken, viewProfile } from "../../utils/util"

interface SideNavProps {
  setShowSideNav: Function
  showSideNav: boolean
  backDropref: any
  activeTab: string
  setActiveTab: Function
  sideNavCloseButtonRef: React.RefObject<HTMLImageElement>
}

interface CustomLinkComponentProps {
  to: string
  name: string
  children: React.ReactNode
  onClickHanlder: Function
  classNameList: string
  setActiveTab: Function
  setShowSideNav: Function
}

const sideNavItemClickHandler = (props: {
  name: string
  setShowSideNav: Function
  setActiveTab: Function
}) => {
  props.setShowSideNav(false)
  props.setActiveTab(props.name)
}

const CustomLinkComponent: React.FC<CustomLinkComponentProps> = ({
  to,
  name,
  classNameList,
  onClickHanlder,
  setShowSideNav,
  setActiveTab,
  children,
}) => {
  return (
    <li
      className={`capitalize w-full ${classNameList}`}
      onClick={() => onClickHanlder({ name, setShowSideNav, setActiveTab })}
    >
      <Link to={to} className={`nav-links text-sm`}>
        {children}
      </Link>
    </li>
  )
}

const PageTag = (props: {
  name: string
  src: string
  count: number
  userState: string
  validSession: boolean
  activeTab: string
  buttonType?: string
  type?: string
  iconComponent?: React.FC<IconComponentProps>
  icon: Icon
  user_name: string
  avatar_url: string
}) => {
  let element: JSX.Element = <div></div>

  const isActive = props.name.toLowerCase() === props.activeTab.toLowerCase()
  const tabName = (
    <span className={`${isActive && "text-purple-500"}  tracking-normal`}>
      {props.name}
    </span>
  )
  const { user_name, avatar_url } = props

  if (props?.type === "button") {
    element = (
      <button
        className={`action-btn text-base font-medium border rounded-xl duration-300  action-btn-secondary${
          props.buttonType === "secondary" ? "-border" : ""
        } w-full h-11 flex items-center justify-center `}
      >
        {props.name}
      </button>
    )
  } else if (props?.type === "profile") {
    element = <Profile name={user_name} src={avatar_url} size={12} />
  } else if (props?.type === "icon") {
    element = (
      <div
        className={`flex gap-4 items-center justify-center relative ${props.name.toLowerCase()}-icon`}
      >
        {props.icon && (
          <props.icon
            strokeWidth={`${isActive ? "2" : "2"}`}
            color={`${isActive ? "purple" : "white"}`}
          />
        )}
        <span
          className={`purple-notification-badge ${!props.count && "hidden"}`}
        ></span>
      </div>
    )
  } else if (props?.type === "icon_component") {
    element = (
      <div
        className={`flex gap-2 items-center justify-center relative ${props.name.toLowerCase()}-icon`}
      >
        {props.icon && (
          <props.icon
            strokeWidth={`${isActive ? "3" : "2"}`}
            color={`${isActive ? "purple" : "white"}`}
            size={20}
          />
        )}
        {tabName}
        {props.count > 0 && (
          <span className={`purple-notification-badge`}></span>
        )}
      </div>
    )
  } else {
    element = (
      <div className="flex h-12 gap-2 items-center">
        <props.icon size={16} color={`${isActive ? "purple" : "white"}`} />
        {tabName}
      </div>
    )
  }

  return element
}

const SideNav: React.FC<SideNavProps> = (props) => {
  const [notifications, setNotifications] = useState([])
  const location = useLocation()
  const sideNavref = React.useRef<HTMLDivElement>(null)
  const [requestStatus, setRequestStatus] = useState()
  const [profile, setProfile] = useState<any>()
  // const { validSession } = useContext(MyContext)
  const TOKEN = fetchToken(TOKEN_NAME)
  const validSession: any = TOKEN
  const [wishlistItems, setWishlistItems] = useState()
  const { setShowSideNav, sideNavCloseButtonRef } = props
  useEffect(() => {
    if (validSession) {
      const ID = localStorage.getItem(USER_ID_NAME)
      if (ID && ID !== "") {
        viewProfile(ID, setProfile)
      }
    }
  }, [])
  //   const { user_name, avatar_url } = profile
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sideNavref?.current &&
        !sideNavref.current.contains(event.target) &&
        sideNavCloseButtonRef.current &&
        !sideNavCloseButtonRef.current.contains(event.target)
      ) {
        setShowSideNav(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [sideNavref])

  useEffect(() => {
    if (validSession) {
      const ID = localStorage.getItem(USER_ID_NAME) || ""
      fetchWishListItems({
        userId: ID,
        setWishlistItems,
        setRequestStatus,
        triggerLoader: false,
      })
    }
  }, [location])

  return (
    <>
      <nav
        className="fixed z-[99] duration-300 text-white left-0 bg-background_body top-0 bottom-0 h-screen w-3/4 sm:w-3/6 side-nav no-scrollbar flex flex-col p-4 lg:hidden"
        ref={sideNavref}
      >
        <div className="flex justify-between gap-4  no-scrollbar items-center py-6 border-bottom-grey">
          {/* <Logo /> */}
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5">
              <img
                className="pr-2 w-40 md:w-56"
                src="/Images/Logo/logo.png"
                alt=""
              />
            </Link>
          </div>
          {validSession && (
            <div
              className="flex justify-between items-center gap-2"
              onClick={() => {
                setShowSideNav(false)
              }}
            >
              <Profile
                name={profile?.user_name}
                src={profile?.avatar_url}
                size={12}
              />
              <div
                className={`hidden gap-4 items-center justify-center relative notifications-icon`}
              >
                <Bell />
                <span
                  className={`purple-notification-badge ${false && "hidden"}`}
                ></span>
              </div>
            </div>
          )}
        </div>
        <ul
          className="flex justify-start no-scrollbar items-start grow gap-4 sidenav flex-col py-4 overflow-scroll"
          data-valid-session={validSession}
        >
          {sideNavLinkList
            .filter(
              (item) =>
                (validSession && item.userState === "logged_in") ||
                (!validSession && item.userState === "logged_out") ||
                item.userState === ""
            )
            .map((link) => (
              <CustomLinkComponent
                to={link?.to}
                name={link.name}
                classNameList={`${link?.type}-type`}
                onClickHanlder={sideNavItemClickHandler}
                setShowSideNav={setShowSideNav}
                setActiveTab={props.setActiveTab}
                data-user-state={link.userState}
              >
                <PageTag
                  activeTab={props.activeTab}
                  name={link.name}
                  src={link.src}
                  type={link?.type}
                  buttonType={link?.buttonType}
                  count={notifications.length}
                  userState={link.userState}
                  validSession={validSession}
                  icon={link?.icon || AlertCircle}
                  user_name={profile?.user_name}
                  avatar_url={profile?.avatar_url}
                />
              </CustomLinkComponent>
            ))}
        </ul>
        <div className="p-4 h-12"></div>
      </nav>
    </>
  )
}

export default SideNav
