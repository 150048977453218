// import { fetchToken } from "../util"
// import { tVideoState, tWishlistState } from "../genericTypes"
// // import { ETUBES_URL_PREFIX, TOKEN_NAME, USER_ID_NAME } from '../variables';

// export const removeFromWishList = async (props: {
//   collectionId: string
//   setRequestStatus: Function
// }) => {
//   const { collectionId, setRequestStatus } = props
//   try {
//     setRequestStatus({
//       loading: false,
//       success: false,
//       error: false,
//       buttonLoading: true,
//     })
//     const TOKEN = fetchToken(TOKEN_NAME)
//     const options = {
//       headers: {
//         Authorization: TOKEN,
//         "Content-type": "application/json",
//       },
//       method: "DELETE",
//     }

//     fetch(`${ETUBES_URL_PREFIX}/my_wish_list/${collectionId}`, options)
//       .then((res) => res.json())
//       .then((response) => {
//         setRequestStatus({
//           loading: false,
//           success: true,
//           error: false,
//           buttonLoading: false,
//         })
//         if (response.status_code === 200) {
//           console.log("Removed from wishlist >>", response.message)
//         }
//       })
//       .catch((err) => console.log(err))
//   } catch (error) {
//     setRequestStatus({
//       loading: false,
//       success: false,
//       error: true,
//       buttonLoading: false,
//     })
//     console.log("--- Error while deleting Wishlist items:", error)
//   }
// }

// export const createWishlistOperation = (props: {
//   videoId: string
//   operationType: string
//   setRequestStatus: Function
// }) => {
//   const LOGGED_USER_ID = localStorage.getItem(USER_ID_NAME) || ""
//   const TOKEN = fetchToken(TOKEN_NAME)
//   const options = {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: TOKEN,
//     },
//   }

//   const { videoId, setRequestStatus, operationType } = props

//   try {
//     if (operationType === "add") {
//       fetch(`${ETUBES_URL_PREFIX}/videos/${videoId}`, options)
//         .then((response) => response.json())
//         .then((data) => {
//           if (data.status_code === 200) {
//             const {
//               _id: video_id,
//               cost,
//               title,
//               description,
//               url,
//               created_by_name,
//             } = data.result[0]

//             const wishlistedVideo: tWishlistState = {
//               video_id, // the id should be of the mongo collection and not the actual video TODO: BACKEND ask backend to fix this
//               video_cost: cost,
//               video_title: title,
//               video_description: description,
//               video_url: url,
//               created_by_id: LOGGED_USER_ID, // it should be the current logged in user
//               created_by_name,
//             }

//             addToWishlist({ video: wishlistedVideo, setRequestStatus })
//           } else {
//             console.log("Error whille creating wishlist >>", data.status)
//           }
//         })
//         .catch((err) => console.log("Error whille creating wishlist >>", err))
//     } else {
//       const options = {
//         headers: {
//           Authorization: TOKEN,
//           "Content-type": "application/json",
//         },
//         method: "GET",
//       }
//       fetch(`${ETUBES_URL_PREFIX}/my_wish_list/${LOGGED_USER_ID}`, options)
//         .then((response) => response.json())
//         .then((data) => {
//           if (data.status_code === 200) {
//             const obj = data.result.find((obj: tVideoState) =>
//               Object.values(obj).includes(videoId)
//             )
//             removeFromWishList({ collectionId: obj._id, setRequestStatus }) // the id should be of the mongo collection and not the actual video TODO: BACKEND ask backend to fix this
//           } else {
//             console.log("Error whille creating wishlist >>", data.status)
//           }
//         })
//         .catch((err) => console.log("Error whille creating wishlist >>", err))
//     }
//   } catch (error) {
//     console.log("Error whille creating wishlist >>", error)
//   }
// }

// export const addToWishlist = async (props: {
//   video: tWishlistState
//   setRequestStatus: Function
// }) => {
//   const { video, setRequestStatus } = props
//   try {
//     setRequestStatus({
//       loading: false,
//       success: false,
//       error: false,
//       buttonLoading: true,
//     })
//     const TOKEN = fetchToken(TOKEN_NAME)
//     const options = {
//       headers: {
//         Authorization: TOKEN,
//         "Content-type": "application/json",
//       },
//       method: "POST",
//       body: JSON.stringify(video),
//     }
//     fetch(`${ETUBES_URL_PREFIX}/my_wish_list`, options)
//       .then((res) => res.json())
//       .then((response) => {
//         setRequestStatus({
//           loading: false,
//           success: true,
//           error: false,
//           buttonLoading: true,
//         })
//         if (response.status_code === 200) {
//           console.log(response.message)
//         }
//       })
//       .catch((err) => console.log(err))
//   } catch (error) {
//     setRequestStatus({
//       loading: false,
//       success: false,
//       error: true,
//       buttonLoading: true,
//     })
//     console.log("--- Error while Add Wishlist items:", error)
//   }
// }

import { fetchToken } from "../util"
import { tVideoState, tWishlistState } from "../genericTypes"
import { ETUBES_URL_PREFIX, TOKEN_NAME, USER_ID_NAME } from "../variables"
import { fetchWishListItems } from "../userController"

export const removeFromWishList = async (props: {
  collectionId: string
  setRequestStatus: Function
}) => {
  const { collectionId, setRequestStatus } = props
  try {
    setRequestStatus({
      loading: false,
      success: false,
      error: false,
      buttonLoading: true,
    })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "DELETE",
    }

    fetch(`${ETUBES_URL_PREFIX}/my_wish_list/${collectionId}`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({
          loading: false,
          success: true,
          error: false,
          buttonLoading: false,
        })
        if (response.status_code === 200) {
          console.log("Removed from wishlist >>", response.message)
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({
      loading: false,
      success: false,
      error: true,
      buttonLoading: false,
    })
    console.log("--- Error while deleting Wishlist items:", error)
  }
}

export const createWishlistOperation = (props: {
  videoId: string
  operationType?: string
  setRequestStatus: Function
  setWishlistItems: Function
}) => {
  const LOGGED_USER_ID = localStorage.getItem(USER_ID_NAME) || ""
  const TOKEN = fetchToken(TOKEN_NAME)
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: TOKEN,
    },
  }

  const { videoId, setRequestStatus, operationType, setWishlistItems } = props

  try {
    if (operationType === "add") {
      fetch(`${ETUBES_URL_PREFIX}/videos/${videoId}`, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.status_code === 200) {
            const {
              _id: video_id,
              cost,
              title,
              description,
              url,
              created_by_name,
            } = data.result[0]

            const wishlistedVideo: tWishlistState = {
              video_id, // the id should be of the mongo collection and not the actual video TODO: BACKEND ask backend to fix this
              video_cost: cost,
              video_title: title,
              video_description: description,
              video_url: url,
              created_by_id: LOGGED_USER_ID, // it should be the current logged in user
              created_by_name,
            }
            // setWishlistItems((prev: tVideoState[]) => [...prev, data.result[0]])
            addToWishlist({
              video: wishlistedVideo,
              setRequestStatus,
              setWishlistItems,
            })
            // const ID = localStorage.getItem(USER_ID_NAME) || ""
            // fetchWishListItems({
            //   userId: ID,
            //   setWishlistItems,
            //   setRequestStatus,
            // })
          } else {
            console.log("Error whille creating wishlist >>", data.status)
          }
          const ID = localStorage.getItem(USER_ID_NAME) || ""
          fetchWishListItems({
            userId: ID,
            setWishlistItems,
            setRequestStatus,
          })
        })
        .catch((err) => console.log("Error whille creating wishlist >>", err))
    } else {
      const options = {
        headers: {
          Authorization: TOKEN,
          "Content-type": "application/json",
        },
        method: "GET",
      }
      fetch(`${ETUBES_URL_PREFIX}/my_wish_list/${LOGGED_USER_ID}`, options)
        .then((response) => response.json())
        .then((data) => {
          if (data.status_code === 200) {
            const obj = data.result.find((obj: tVideoState) =>
              Object.values(obj).includes(videoId)
            )
            removeFromWishList({ collectionId: obj._id, setRequestStatus }) // the id should be of the mongo collection and not the actual video TODO: BACKEND ask backend to fix this
            // setWishlistItems((prev: tVideoState[]) => prev.filter((item) => item.video_id !== videoId));
          } else {
            console.log("Error whille creating wishlist >>", data.status)
          }
          const ID = localStorage.getItem(USER_ID_NAME) || ""
          fetchWishListItems({
            userId: ID,
            setWishlistItems,
            setRequestStatus,
          })
        })
        .catch((err) => console.log("Error whille creating wishlist >>", err))
    }
  } catch (error) {
    console.log("Error whille creating wishlist >>", error)
  }
  const ID = localStorage.getItem(USER_ID_NAME) || ""
  fetchWishListItems({
    userId: ID,
    setWishlistItems,
    setRequestStatus,
  })
}

export const addToWishlist = async (props: {
  video: tWishlistState
  setRequestStatus: Function
  setWishlistItems: Function
}) => {
  const { video, setRequestStatus, setWishlistItems } = props
  try {
    setRequestStatus({
      loading: false,
      success: false,
      error: false,
      buttonLoading: true,
    })
    const TOKEN = fetchToken(TOKEN_NAME)
    const options = {
      headers: {
        Authorization: TOKEN,
        "Content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(video),
    }
    fetch(`${ETUBES_URL_PREFIX}/my_wish_list`, options)
      .then((res) => res.json())
      .then((response) => {
        setRequestStatus({
          loading: false,
          success: true,
          error: false,
          buttonLoading: true,
        })
        if (response.status_code === 200) {
          console.log(response.message)
          setWishlistItems(response)
        }
      })
      .catch((err) => console.log(err))
  } catch (error) {
    setRequestStatus({
      loading: false,
      success: false,
      error: true,
      buttonLoading: true,
    })
    console.log("--- Error while Add Wishlist items:", error)
  }
}

export const USERNAME_EXISTS_CHECK_STRING = "user_name already exist"
